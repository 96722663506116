import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-scroll';

import { Colors, Easings, TextSize } from '@topia.com/ui-kit';

import { GuideSection } from '../types';
import { useMetrics } from '../../../analytics';

interface Props {
  sections: GuideSection[];
}

/**
 * Sidebar navigation
 */
export const SideNav = ({ sections }: Props) => {
  const { events } = useMetrics();

  return (
    <OuterWrapper>
      <Wrapper>
        <Container data-testid="guide-sidenav-container">
          {sections
            .filter(s => s.label)
            .map(({ id, label }) => (
              <SideNavItem
                data-testid="guide-sidenav-item"
                key={id}
                to={id}
                onSetActive={to => events.Guide.SectionView(to)}>
                {label}
              </SideNavItem>
            ))}
        </Container>
      </Wrapper>
    </OuterWrapper>
  );
};

const OuterWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  max-width: 1032px;
  margin: 0 auto;
`;

const Wrapper = styled.div`
  position: absolute;
  width: 240px;
  height: 100%;
  right: 0;
`;

const Container = styled.div`
  position: sticky;
  top: 50vh;
  margin-top: calc(100% + 24px);
  transform: translateY(-50%);
  z-index: 1;
`;

const SideNavItem = styled(Link).attrs({
  activeClass: 'active',
  offset: -64,
  spy: true,
  hashSpy: true,
  smooth: 'easeOutQuint',
})`
  ${TextSize.Medium};
  display: block;
  padding: 12px 0;

  color: ${Colors.NavyBlue()};
  opacity: 0.4;
  transition: opacity 560ms ${Easings.ExpoOut};

  &.active,
  &:hover {
    opacity: 1;
    text-decoration: none;
  }
`;
