import pMemoize from 'promise-memoize';
import React, { useContext } from 'react';

import api from '../../shared/api';
import { loadNeighborhoodsMetadata } from '../neighborhoods/api';
import { EmployeeResponse, Move, ProfileImage, Session } from './types';
import { getNeighborhoods } from '../neighborhoods-text/api';
import config from '../../config';

const fetchNeighborhoodDataPromise = async (cityId?: number) => {
  // Ugly code to work around bad data with neighborhoods, i.e. when both endpoints return 404.
  const neighborhoods = cityId
    ? await loadNeighborhoodsMetadata(cityId).catch(console.error)
    : undefined;

  const textNeighborhoods = neighborhoods
    ? undefined
    : (await getNeighborhoods()
        .then(response => response.length > 0)
        .catch(console.error)) || undefined;

  return { neighborhoods, textNeighborhoods };
};

/**
 * Load session data
 */
export const loadSession = pMemoize(
  async () => {
    const employeeJSON = await api.get('v1/employee').json<EmployeeResponse>();
    const neighborhoodsPromise = fetchNeighborhoodDataPromise(employeeJSON.destinationCityId);
    const profileImage = config.fusionEnv ? await getProfileImage() : undefined;
    const { neighborhoods, textNeighborhoods } = await neighborhoodsPromise;

    const session: Session = {
      textNeighborhoods,
      cityId: employeeJSON.destinationCityId,
      neighborhoods: neighborhoods || undefined,
      group: employeeJSON.group,
      cityName: employeeJSON.movingToCity,
      employee: {
        firstName: employeeJSON.firstName,
        lastName: employeeJSON.lastName,
        id: employeeJSON.userId,
        isDemo: employeeJSON.isDemo === true,
        currency: employeeJSON.userCurrency,
        profileImage:
          profileImage && profileImage.image
            ? `data:image/jpeg;base64,${profileImage.image}`
            : undefined,
      },
      activeMoveId: (employeeJSON.move || {}).id,
      funcConfigs: employeeJSON.funcConfigs,
    };

    return session;
  },
  { maxAge: 600 * 100 },
);

/**
 * Session context
 */
export const SessionContext = React.createContext<Session | undefined>(undefined);

/**
 * Export guaranteed session as hook
 */
export const useSession = () => {
  const sessionContext = useContext(SessionContext);
  if (!sessionContext) {
    console.error('useSession cannot be used outside <SessionProvider>!');
    throw new Error('Missing session metadata');
  }

  return sessionContext;
};

/**
 * Get list of employee moves.
 */
export const getMoves = ({ signal }: AbortController) =>
  api.get('v1/moves', { signal }).json<Move[]>();

/**
 * Update active move.
 */
export const updateActiveMove = (params: { id: number }, { signal }: AbortController) =>
  api.post(`v1/session/moves/${params.id}/activate`, { signal });

/**
 * Get profile image
 */
export const getProfileImage = pMemoize(async () => {
  return api.get('v1/accounts/profiles/images').json<ProfileImage>();
});
