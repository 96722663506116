import React, { useRef } from 'react';
import { createGlobalStyle } from 'styled-components';
import ReactMapboxGl from 'react-mapbox-gl';
// tslint:disable-next-line
import mapboxGlCSS from '!raw-loader!mapbox-gl/dist/mapbox-gl.css';

import { MediaQuery, TextSize, useMedia } from '@topia.com/ui-kit';

import config from '../../../../config';
import { convertToLatLngBounds, padBBox } from '../../utils/map';
import { ZoomControl } from './ZoomControl';
import { NAV_HEIGHT } from '../../../application/components/Nav/Header';
import { useMetrics } from '../../../../analytics';

const Map = ReactMapboxGl({
  injectCSS: false,
  minZoom: 7,
  accessToken: config.mapboxKey,
  attributionControl: false,
  dragRotate: false,
});

/**
 * Mapbox css styles
 */
export const MapboxGlCSS = createGlobalStyle`
  ${mapboxGlCSS};

  .mapboxgl-map {
    font: inherit;
    ${TextSize.Default};
    width: 100%;
    height: 100%;
  }
`;

interface Props {
  bbox: GeoJSON.BBox;
}

/**
 * react-mapbox-gl with defaults
 */
export const GLMap: React.FunctionComponent<Props> = ({ bbox, children }) => {
  const map = useRef<mapboxgl.Map | undefined>(undefined);
  const { events } = useMetrics();
  const fitBounds = convertToLatLngBounds(bbox);
  const isMobile = useMedia('mobile');
  const maxBounds = convertToLatLngBounds(padBBox(bbox, isMobile ? 1.6 : 0.8));
  const padding = isMobile ? 32 : { top: NAV_HEIGHT, right: 0, bottom: 0, left: 384 };

  return (
    <Map
      onStyleLoad={mapInstance => (map.current = mapInstance)}
      style="mapbox://styles/topia-mobility/ckzy9p868001f15nt3hef4cx9"
      fitBounds={fitBounds}
      fitBoundsOptions={{ padding, duration: 0 }}
      onZoomEnd={events.NeighborhoodsPlanner.ZoomChange}
      onDragStart={events.NeighborhoodsPlanner.MapDrag}
      maxBounds={maxBounds}>
      <MediaQuery.Default>
        <ZoomControl
          onClickZoomIn={() => {
            events.NeighborhoodsPlanner.ZoomControlClick('in');
            if (map.current) map.current.zoomIn();
          }}
          onClickZoomOut={() => {
            events.NeighborhoodsPlanner.ZoomControlClick('out');
            if (map.current) map.current.zoomOut();
          }}
        />
      </MediaQuery.Default>
      <>{children}</>
    </Map>
  );
};
