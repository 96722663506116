import React from 'react';
import styled, { css } from 'styled-components';
import { Popup as MapboxGLPopup } from 'react-mapbox-gl';
import { Props as PopupProps } from 'react-mapbox-gl/lib/popup';
import { FontWeight, Colors } from '@topia.com/ui-kit';
import { BORDER_RADIUS, DEFAULT_BOX_SHADOW } from '@topia.com/ui-kit/variables';

const popupStyle = css`
  pointer-events: none;

  b {
    font-weight: ${FontWeight.SemiBold};
  }

  .mapboxgl-popup-tip {
    border-width: 8px;
  }

  .mapboxgl-popup-content {
    pointer-events: none;
    color: ${Colors.DarkGray()};
    border-radius: ${BORDER_RADIUS};
    padding: 6px 8px;
    box-shadow: ${DEFAULT_BOX_SHADOW};
  }
`;

const secondaryStyle = css`
  &.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
    border-top-color: ${Colors.NavyBlue()};
  }

  &.mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
    border-bottom-color: ${Colors.NavyBlue()};
  }

  &.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
    border-right-color: ${Colors.NavyBlue()};
  }

  &.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
    border-left-color: ${Colors.NavyBlue()};
  }

  .mapboxgl-popup-content {
    color: ${Colors.White()};
    background-color: ${Colors.NavyBlue()};
  }
`;

interface Props {
  type?: 'primary' | 'secondary';
}

/**
 * Styled map popup
 */
export const Popup: React.FunctionComponent<Props & PopupProps> = React.memo(styled(MapboxGLPopup)`
  ${popupStyle};

  ${({ type = 'primary' }: Props) => type === 'secondary' && secondaryStyle};
`);
