import React from 'react';
import config from "../config";

/**
 * Help text used in error messages
 */
export const EmailSupportText = () => (
  <>
    If this problem persists, please contact&nbsp;
    <a href={`mailto:${config.supportEmail}`}>{config.supportEmail}</a>.
  </>
);
