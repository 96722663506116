import React from 'react';
import pMemoize from 'promise-memoize';
import styled from 'styled-components';
import { Spinner, media, ErrorOverlay } from '@topia.com/ui-kit';
import { useAsync } from '@topia.com/ui-kit/hooks';

import { PlannerMode, PlannerFeatureCollection, Office } from '../types';
import { getRentCells, getScoreData, getCommuteCells } from '../api';
import { generateScoreProperties, ScoringSettings } from '../utils/score';

// Memoize API calls forever
const getRent = pMemoize(getRentCells);
const getCommute = pMemoize(getCommuteCells, { resolve: 'json' });
const getScore = pMemoize(getScoreData, { resolve: 'json' });

interface GeoJSONParams {
  mode: PlannerMode;
  cityId: number;
  office: Office | undefined;
  scoringSettings: ScoringSettings;
}

/**
 * Fetch data for corresponding mode
 */
async function getGeoJSON(params: GeoJSONParams) {
  const { mode, office, cityId } = params;
  switch (mode) {
    case PlannerMode.Score:
      if (!office) return;
      const data = await getScore(cityId, office.latLng);
      return generateScoreProperties(data, params.scoringSettings, office.latLng);
    case PlannerMode.Commute:
      if (!office) return;
      return getCommute(cityId, office.latLng);
    case PlannerMode.Rent:
      return getRent(cityId);
  }
}

interface Props extends GeoJSONParams {
  children: (geoJSON?: PlannerFeatureCollection) => React.ReactNode;
}

/**
 * Load data for given mode and office
 */
export const GeoJSONProvider = ({ children, ...params }: Props) => {
  const geoJSON = useAsync(getGeoJSON, { params });

  return (
    <>
      {geoJSON.isLoading && <Loader overlayOpacity={0} label="Loading data" />}
      {geoJSON.error && (
        <ErrorOverlay error={geoJSON.error} buttonText="Reload" onClose={geoJSON.reload}>
          Failed to load data.
        </ErrorOverlay>
      )}
      {children(geoJSON.data)}
    </>
  );
};

const Loader = styled(Spinner)`
  ${media.mobile`
    height: 50%;
  `}
`;
