import React from 'react';
const SvgFileUploadIcon = React.forwardRef(
  (
    { color = '#003049', ...props }: React.SVGProps<SVGSVGElement>,
    ref: React.Ref<SVGSVGElement>,
  ) => (
    <svg height={120} viewBox="0 0 120 120" width={120} ref={ref} color={color} {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M72 21l.081.003.068.008a1.083 1.083 0 01.222.06l.052.023a1.018 1.018 0 01.172.102l.034.028-.008-.008.004.004.015.012.013.01.054.05 16 16a1.006 1.006 0 01.147.187l.021.037c.011.02.022.04.031.06l.023.053a.988.988 0 01.067.284l.004.055V98a1 1 0 01-1 1H32a1 1 0 01-1-1V22a1 1 0 011-1zm-1 2H33v74h54V39H72a1 1 0 01-1-1zM40 83.5h28a1 1 0 01.117 1.993L68 85.5H40a1 1 0 01-.117-1.993L40 83.5h28zm36-10a1 1 0 010 2H40a1 1 0 010-2zm-8-10a1 1 0 01.117 1.993L68 65.5H40a1 1 0 01-.117-1.993L40 63.5zm8-10a1 1 0 01.117 1.993L76 55.5H40a1 1 0 01-.117-1.993L40 53.5zm-3-29.085V37h12.585z"
          fill="currentColor"
          fillRule="nonzero"
        />
        <circle cx={32} cy={28} fill="#bcdcf5" r={16} />
        <path
          d="M33 27h9v2h-9v9h-2v-9h-9v-2h9v-9h2z"
          fill="currentColor"
          fillRule="nonzero"
          opacity={0.9}
        />
      </g>
    </svg>
  ),
);
export default SvgFileUploadIcon;
