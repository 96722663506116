import React, { useContext, useState } from 'react';

import { AlertItem, AlertStatus } from './types';

interface AlertContextValue {
  alerts: AlertItem[];
  addAlert: (item: AlertItem) => void;
  dismissAlert: (key: string) => void;
}

const AlertContext = React.createContext<AlertContextValue | undefined>(undefined);

/**
 * Context helper
 */
export const useAlertContext = () => {
  const alertContext = useContext(AlertContext);
  if (!alertContext) {
    console.error('AlertRenderer cannot be used outside <AlertProvider>!');
    throw new Error('Missing AlertProvider');
  }
  return alertContext;
};

/**
 * Provider to wrap around
 */
export const AlertProvider: React.FC = ({ children }) => {
  const [alerts, setAlerts] = useState<AlertItem[]>([]);

  const dismissAlert = (id: string) => setAlerts(old => old.filter(o => o.id !== id));

  const addAlert = (item: AlertItem) => {
    setAlerts(oldList => {
      const newList = oldList.filter(o => o.id !== item.id);
      return [item, ...newList];
    });
  };

  return (
    <AlertContext.Provider value={{ alerts, addAlert, dismissAlert }}>
      {children}
    </AlertContext.Provider>
  );
};

/**
 * Trigger alert
 */
export const useAlert = () => {
  const { addAlert, dismissAlert } = useAlertContext();

  return (message: string, options: Partial<Omit<AlertItem, 'message'>> = {}) => {
    const id = Math.random()
      .toString(36)
      .substr(2, 5);

    addAlert({
      id,
      message,
      duration: options.button ? undefined : 3000,
      channel: 'default',
      status: AlertStatus.Success,
      ...options,
    });

    return () => dismissAlert(id);
  };
};
