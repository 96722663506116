import React, { useState } from 'react';
import { Grid, Colors, FontWeight, TextSize } from '@topia.com/ui-kit';
import styled from 'styled-components';
import { VictoryChart, VictoryBar, VictoryTooltip, VictoryAxis } from 'victory';
import { scaleLinear } from 'd3-scale';

import { WeatherWidgetData } from '../types';
import { Label } from './WidgetComponents';

const formatMonth = new Intl.DateTimeFormat(undefined, { month: 'long' }).format;
const MONTHS = Array.from({ length: 12 }, (_v, i) =>
  formatMonth(new Date(Date.UTC(2000, i, 1, 0, 0, 0))),
);

const Tooltip = (
  <VictoryTooltip
    cornerRadius={4}
    style={{
      fontFamily: 'Inter',
      fontWeight: 600,
      padding: 6,
      fontSize: 12,
      fill: Colors.White(),
    }}
    flyoutStyle={{
      strokeWidth: 0,
      fill: Colors.NavyBlue(),
    }}
  />
);

/**
 * Weather widget
 */
export const WeatherWidget = (weather: WeatherWidgetData) => {
  const [degreeType, setDegreeType] = useState<'celsius' | 'fahrenheit'>('celsius');
  const degreeLabel = `°${degreeType === 'fahrenheit' ? 'F' : 'C'}`;

  const temperatures = weather.temperature_avg_high_monthly[degreeType];
  const tempSeries = temperatures.map((temperature, idx) => ({
    temperature,
    label: `${MONTHS[idx]}: ${temperature} ${degreeLabel}`,
    month: idx,
  }));

  const colorScale = scaleLinear<string>()
    .domain([Math.min(...temperatures), Math.max(...temperatures)])
    .range(['#bcdcf5', '#fdd246', '#f38b3a']);

  return (
    <>
      <Row>
        <Grid.Item size={4} mobileSize={12}>
          <Label>Climate type</Label>
          <ClimateTypeLabel>{weather.climate_type}</ClimateTypeLabel>
        </Grid.Item>
      </Row>
      <Row>
        <Grid.Item size={4} mobileSize={12}>
          <Label>{weather.custom_metric.label}</Label>
          <SunHoursWrapper>
            <svg height="120" width="120" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M60 97a37 37 0 1 1 0-74 37 37 0 0 1 0 74zm0-2a35 35 0 1 0 0-70 35 35 0 0 0 0 70zM59 6a1 1 0 0 1 2 0v12a1 1 0 0 1-2 0zM6 61a1 1 0 0 1 0-2h12a1 1 0 0 1 0 2zm96 0a1 1 0 1 1 0-2h12a1 1 0 1 1 0 2zm-43 41a1 1 0 1 1 2 0v12a1 1 0 1 1-2 0zm27.1-89.3a1 1 0 0 1 1.8 1l-6 10.4a1 1 0 1 1-1.8-1zM12.7 34a1 1 0 0 1 1-1.8l10.4 6a1 1 0 1 1-1 1.8zm83.2 48a1 1 0 1 1 1-1.8l10.4 6a1 1 0 0 1-1 1.8zM38 95.9a1 1 0 1 1 1.8 1l-6 10.4a1 1 0 0 1-1.8-1zM106.3 32a1 1 0 1 1 1 1.8l-10.4 6a1 1 0 1 1-1-1.8zM32 13.7a1 1 0 0 1 1.8-1l6 10.4a1 1 0 1 1-1.8 1zm48 83.2a1 1 0 1 1 1.8-1l6 10.4a1 1 0 1 1-1.8 1zM23.1 80a1 1 0 1 1 1 1.8l-10.4 6a1 1 0 0 1-1-1.8z"
                fillRule="evenodd"
                fill={Colors.YellowSoda()}
              />
            </svg>
            <SunHours>{weather.custom_metric.value}</SunHours>
          </SunHoursWrapper>
        </Grid.Item>
        <Grid.Item size={4} mobileSize={12}>
          <Label>
            <span>Average daily high temperature</span>
            <DegreeToggle
              onClick={() => setDegreeType(degreeType === 'fahrenheit' ? 'celsius' : 'fahrenheit')}>
              {degreeLabel} ▾
            </DegreeToggle>
          </Label>
          <VictoryChart
            width={320}
            height={144}
            style={{ parent: { maxHeight: 144 } }}
            padding={{ top: 16, bottom: -10, left: 8, right: 34 }}>
            <VictoryBar
              padding={{ right: 10 }}
              data={tempSeries}
              barRatio={0.5}
              y="temperature"
              x="month"
              labelComponent={Tooltip}
              style={{
                data: {
                  fill: (({ datum }: any) => colorScale(datum.temperature)) as any,
                },
              }}
            />
            <VictoryAxis
              style={{
                axis: { strokeWidth: 0 },
                tickLabels: {
                  fontFamily: 'Inter',
                  fontSize: 10,
                  fill: Colors.DarkGray(0.32),
                  textAnchor: 'middle',
                },
              }}
              tickCount={5}
              offsetX={20}
              dependentAxis
              orientation="right"
            />
          </VictoryChart>
        </Grid.Item>
      </Row>
    </>
  );
};

const Row = styled(Grid)`
  margin-bottom: 48px;
`;

const ClimateTypeLabel = styled.div`
  ${TextSize.Medium};
  font-weight: ${FontWeight.SemiBold};
  color: ${Colors.SkyBlue()};
`;

const SunHoursWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  padding-top: 32px;
`;

const SunHours = styled.div`
  ${TextSize.ExtraLarge};
  font-weight: ${FontWeight.SemiBold};
  color: ${Colors.YellowSoda()};
  margin-left: 24px;
`;

const DegreeToggle = styled.span`
  cursor: pointer;
`;
