import React from 'react';
const SvgFileIcon = React.forwardRef(
  (
    { color = '#003049', ...props }: React.SVGProps<SVGSVGElement>,
    ref: React.Ref<SVGSVGElement>,
  ) => (
    <svg width={24} height={24} viewBox="0 0 24 24" ref={ref} color={color} {...props}>
      <path
        d="M18 11h-6V5H6v14h12v-8zm-.828-2L14 5.828V9h3.172zM4 3h10l6 6v12H4V3z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </svg>
  ),
);
export default SvgFileIcon;
