import React from 'react';
import { Colors, FontWeight, TextSize } from '@topia.com/ui-kit';
import styled from 'styled-components';

interface Props {
  max: number;
  mean: number;
  min: number;
  formatCurrency: (value: number) => string;
}

/**
 * Housing cost graph
 */
export const HousingCostGraph = ({ max, mean, min, formatCurrency }: Props) => (
  <Container>
    <Bar />
    <Mean left={((mean - min) / (max - min)) * 100} />
    <AxisLabel>{formatCurrency(min)}</AxisLabel>
    <AxisLabel>{formatCurrency(max)}</AxisLabel>
  </Container>
);

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
`;

const Mean = styled.div`
  position: absolute;
  top: -16px;
  left: ${(props: { left: number }) => props.left}%;
  width: 2px;
  height: 32px;

  background-color: ${Colors.DarkGray()};
`;

const AxisLabel = styled.div`
  margin-top: 32px;

  ${TextSize.Small};
  font-weight: ${FontWeight.SemiBold};
  text-transform: uppercase;
`;

const Bar = styled.div`
  position: absolute;
  width: 100%;
  height: 16px;
  margin-bottom: 8px;
  background-image: linear-gradient(
    to right,
    ${Colors.LightBlue()},
    ${Colors.Blonde()} 50%,
    ${Colors.SeaShell()}
  );
`;
