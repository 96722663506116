import React from 'react';
import styled from 'styled-components';

import { ASSETS_URL_ROOT } from '@topia.com/ui-kit/variables';
import { Link } from '@topia.com/context-path';

import { BurgerMenu } from './BurgerMenu';
import config from '../../../../../config';
import { CreateMenu } from '../CreateMenu';
import { UserMenu } from '../UserMenu';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

/**
 * MobileNavBar
 */
export const MobileNavBar = ({ open, setOpen }: Props) => (
  <Wrapper>
    <LeftActions>
      <BurgerMenu isOpen={open} onClick={() => setOpen(!open)} />
    </LeftActions>
    <Logo>
      <Link to="/" onClick={() => setOpen(false)}>
        <img alt="Topia" src={`${ASSETS_URL_ROOT}/logo-small.svg`} />
      </Link>
    </Logo>
    <RightActions>
      {!open && (
        <>
          {config.fusionEnv && <CreateMenu />}
          <StyledUserMenu />
        </>
      )}
    </RightActions>
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  width: 100%;
`;

const LeftActions = styled.div`
  display: flex;
  flex: 1;
`;

const Logo = styled.div`
  display: flex;
  flex: 2;
  justify-content: center;
`;

const RightActions = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

const StyledUserMenu = styled(UserMenu)`
  margin-left: 8px;
`;
