import React from 'react';
import { VictoryChart, VictoryLine, VictoryAxis } from 'victory';
import { Colors } from '@topia.com/ui-kit';

import { UAPopulationEstimate } from '../../types';
import { growthLineData } from '../../utils/widget';

interface Props {
  current: string;
  firstComparison: string;
  secondComparison: string;
  populationEstimates: UAPopulationEstimate[];
}

const tickLabels = {
  fontSize: 11,
  fontFamily: 'Inter',
  fill: Colors.DarkGray(0.32),
  textAnchor: 'start' as 'start',
};

const lineProps = (stroke: string) => ({
  interpolation: 'natural' as 'natural',
  y: 'count',
  x: 'year',
  style: {
    data: { stroke, strokeWidth: 4, strokeLineCap: 'round' },
  },
});

/**
 * Population chart in people widget
 */
export const PopulationChart = ({
  current,
  firstComparison,
  secondComparison,
  populationEstimates,
}: Props) => {
  const currentLine = growthLineData(current, populationEstimates);
  const firstLine = growthLineData(firstComparison, populationEstimates);
  const secondLine = growthLineData(secondComparison, populationEstimates);

  return (
    <VictoryChart
      padding={{ top: 10, left: 0, right: 40, bottom: 24 }}
      domainPadding={{ y: 10 }}
      height={160}
      animate={{ duration: 240, easing: 'expOut' }}>
      {currentLine && <VictoryLine {...lineProps(Colors.SkyBlue())} data={currentLine} />}
      {firstLine && <VictoryLine {...lineProps(Colors.YellowSoda())} data={firstLine} />}
      {secondLine && <VictoryLine {...lineProps(Colors.Green())} data={secondLine} />}
      <VictoryAxis
        offsetX={40}
        style={{
          tickLabels,
          axis: { strokeWidth: 0 },
          grid: { strokeWidth: 1, stroke: Colors.DarkGray(0.08) },
        }}
        dependentAxis
        fixLabelOverlap
        tickFormat={(t, _i, ticks) => {
          const { 0: first, [ticks.length - 1]: last } = ticks;
          const delta = Math.abs(last - first);
          const fullMillions = delta >= 1000000;
          return fullMillions ? `${Math.round(t / 1000000)}M` : `${Math.round(t / 100000) / 10}M`;
        }}
        orientation="right"
      />
      <VictoryAxis
        style={{
          tickLabels,
          axis: { stroke: Colors.DarkGray(0.08) },
        }}
        crossAxis
      />
    </VictoryChart>
  );
};
