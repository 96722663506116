import debounce from 'lodash/debounce';

import { PlannerSettings, LatLngLiteral, PlannerMode } from './modules/neighborhoods/types';
import { useFlags } from './features';

const PREFIX = 'EE';

interface WindowWithAnalytics extends Window {
  analytics?: SegmentAnalytics.AnalyticsJS;
}

const Window = window as WindowWithAnalytics;

enum UserActivityEvents {
  Active = 'UserActivity_Active',
  Idle = 'UserActivity_Idle',
  UpdateReload = 'UserActivity_Update_Reload',
}

enum NeighborhoodsPlannerEvents {
  SettingsUpdate = 'NeighborhoodsPlanner_Settings_Update',
  PinSet = 'NeighborhoodsPlanner_Pin_Set',
  ZoomChange = 'NeighborhoodsPlanner_Zoom_Change',
  FeatureHover = 'NeighborhoodsPlanner_Feature_Hover',
  MapDrag = 'NeighborhoodsPlanner_Map_Drag',
  ZoomControlClick = 'NeighborhoodsPlanner_Zoom_Control_Click',
}

enum GuideEvents {
  SectionView = 'Guide_Section_View',
  SectionLinkClick = 'Guide_SectionLink_Click',
  SearchResult = 'Guide_Search_Result',
}

enum DashboardEvents {
  ChecklistDetailsOpen = 'Dashboard_Checklist_Open',
  ChecklistToggle = 'Dashboard_Checklist_Toggle',
  GuideLinkClick = 'Dashboard_Guide_Link_Click',
}

enum InboxEvents {
  InboxItemClick = 'Inbox_Item_Click',
  InboxItemSubmit = 'Inbox_Item_Submit',
  InboxDocumentUpload = 'Inbox_Document_Upload',
}

/**
 * Hook for sending metrics
 */
export const useMetrics = () => {
  const flags = useFlags();

  const event = (title: string, ...properties: object[]) =>
    flags.metrics && Window.analytics
      ? Window.analytics.track(`${PREFIX} ${title}`, properties)
      : console.info(title, ...properties);
  const identify = (id: string, traits: any) =>
    flags.metrics && Window.analytics
      ? Window.analytics.identify(id, traits)
      : console.info(`Identify user %s`, id, traits);
  const pageView = (path: string, ...properties: object[]) => {
    // Normalise trailing slashes in the path to avoid getting duplicates
    const normalisedPath = path.replace(/\/*$/, '');

    if (flags.metrics && Window.analytics) {
      Window.analytics.page(normalisedPath, properties);
    } else {
      console.info(`Page view: ${normalisedPath}`, ...properties);
    }
  };

  return {
    identify,
    pageView,
    events: {
      UserActivity: {
        Active() {
          event(UserActivityEvents.Active);
        },
        Idle() {
          event(UserActivityEvents.Idle);
        },
        UpdateReload() {
          event(UserActivityEvents.UpdateReload);
        },
      },
      NeighborhoodsPlanner: {
        SettingsUpdate: debounce((changed: string, settings: PlannerSettings) => {
          event(NeighborhoodsPlannerEvents.SettingsUpdate, { changed, ...settings });
        }, 100),
        PinSet(latLng: LatLngLiteral) {
          event(NeighborhoodsPlannerEvents.PinSet, { latLng });
        },
        ZoomChange: debounce(() => {
          event(NeighborhoodsPlannerEvents.ZoomChange);
        }, 500),
        ZoomControlClick(direction: 'in' | 'out') {
          event(NeighborhoodsPlannerEvents.ZoomControlClick, { direction });
        },
        FeatureHover: debounce((mode: PlannerMode) => {
          event(NeighborhoodsPlannerEvents.FeatureHover, { mode });
        }, 500),
        MapDrag: debounce(() => {
          event(NeighborhoodsPlannerEvents.MapDrag);
        }, 500),
      },
      Guide: {
        SectionView: debounce((section: string) => {
          event(GuideEvents.SectionView, { section });
        }, 1000),
        SectionLinkClick(section: string) {
          event(GuideEvents.SectionLinkClick, { section });
        },
        SearchResult(label: string) {
          event(GuideEvents.SearchResult, { label });
        },
      },
      Dashboard: {
        ChecklistDetailsOpen(title: string) {
          event(DashboardEvents.ChecklistDetailsOpen, { title });
        },
        CheckListToggle: debounce((done: boolean) => {
          event(DashboardEvents.ChecklistToggle, { done });
        }, 500),
        GuideLinkClick(url: string) {
          event(DashboardEvents.GuideLinkClick, { url });
        },
      },
      Inbox: {
        InboxItemClick(url: string) {
          event(InboxEvents.InboxItemClick, { url });
        },
        InboxItemSubmit() {
          event(InboxEvents.InboxItemSubmit);
        },
        InboxDocumentUpload() {
          event(InboxEvents.InboxDocumentUpload);
        },
      },
    },
  };
};
