import React from 'react';
import styled from 'styled-components';
import { MediaQuery } from '@topia.com/ui-kit';

import { ModeSwitcher } from './ModeSwitcher';
import { AddressSuggest, AddressSuggestProps } from '../AddressSuggest';
import Settings, { SettingsProps } from './Settings';

interface Props {
  settingsProps: SettingsProps;
  suggestProps: AddressSuggestProps;
}

/**
 * Default map overlay
 */
export const DefaultOverlay: React.FunctionComponent<Props> = ({
  settingsProps,
  suggestProps,
  children,
}) => (
  <MediaQuery.Default>
    <ModeSwitcher />

    <LeftPane>
      <AddressSuggest {...suggestProps} />
      <Settings {...settingsProps}>{children}</Settings>
    </LeftPane>
  </MediaQuery.Default>
);

/**
 * Neighborhoods overlay container
 */
export const LeftPane = styled.div`
  position: absolute;
  top: 0;
  left: 32px;
  max-height: calc(100% - 32px);
  display: flex;
  flex-direction: column;
  z-index: 100;
`;
