import { Link } from '@reach/router';
import React from 'react';
import styled from 'styled-components';

import {
  Avatar,
  Colors,
  Easings,
  Menu,
  MenuLink,
  MenuList,
  UnstyledMenuButton,
} from '@topia.com/ui-kit';
import DropdownIcon from '@topia.com/ui-kit/icons/small/DropdownIcon';
import { useContextPathSupport } from '@topia.com/context-path';

import { useSession } from '../../api';
import config from '../../../../config';

/**
 * User action menu - logout, settings etc
 */
export const UserMenu = ({ className }: { className?: string }) => {
  const { withContextPath } = useContextPathSupport();
  const {
    employee: { firstName, lastName, profileImage },
  } = useSession();

  return (
    <Menu>
      <AvatarButton className={className} data-testid="header-avatar">
        <Avatar photo={profileImage} name={`${firstName} ${lastName}`} />
        <DropdownOverlay aria-hidden />
      </AvatarButton>
      <StyledMenuList data-testid="user-menu" align="right">
        <UpArrow />
        {config.fusionEnv && (
          <MenuLink data-testid="account-link" as={Link} to={withContextPath('/account')}>
            Account
          </MenuLink>
        )}
        {config.fusionEnv && (
          <MenuLink data-testid="policy-link" as={Link} to={withContextPath('/policy')}>
            Policy Information
          </MenuLink>
        )}
        {config.fusionEnv && (
          <MenuLink data-testid="support-link" as={Link} to={withContextPath('/support')}>
            Support
          </MenuLink>
        )}
        <MenuLink data-testid="logout-link" href={withContextPath('/auth/logout')}>
          Sign Out
        </MenuLink>
      </StyledMenuList>
    </Menu>
  );
};

const DropdownOverlay = styled(DropdownIcon).attrs({ color: 'white' })`
  position: absolute;
  top: 0;
  left: 0;
  width: 32px;
  height: 32px;
  padding: 8px;
  border-radius: 50%;
  opacity: 0;
  background-color: ${Colors.DarkGray(0.4)};
  transition: opacity 240ms ${Easings.ExpoOut};
`;

const AvatarButton = styled(UnstyledMenuButton)`
  appearance: none;
  border: none;
  background: none;
  padding: 0;
  position: relative;
  cursor: pointer;

  &:hover ${DropdownOverlay} {
    opacity: 1;
  }
`;

const UpArrow = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;

  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid ${Colors.White()};
`;

const StyledMenuList = styled(MenuList)`
  &[data-reach-menu-list] {
    margin-top: 16px;
    margin-right: -8px;
  }
`;
