import React from 'react';
import styled, { css } from 'styled-components';

import { GuideArticle } from '../types';
import { Colors, FontWeight, Grid, media, TextSize } from '@topia.com/ui-kit';

interface Props {
  article: GuideArticle;
  noTitle: boolean;
}

/**
 * Article in guide section
 */
export const Article = ({ article, noTitle }: Props) => (
  <Grid>
    <Grid.Item offset={1} size={6} mobileOffset={0} mobileSize={12}>
      {!noTitle && <ArticleTitle id={article.id}>{article.label}</ArticleTitle>}
      <ArticleBody dangerouslySetInnerHTML={{ __html: article.content }} />
    </Grid.Item>
  </Grid>
);

/**
 * Article title
 */
const articleTitle = css`
  ${TextSize.Large};

  font-weight: ${FontWeight.Bold};
  margin-top: 64px;
  margin-bottom: 16px;
  color: ${Colors.NavyBlue()};

  ${media.mobile`
    margin-top: 48px;
  `};

  & + .h2title {
    margin-top: 0;
  }
`;

/**
 * Article subtitle
 */
const articleSubtitle = css`
  ${TextSize.Medium};
  margin-top: 48px;
  margin-bottom: 16px;
  text-transform: capitalize;
  color: ${Colors.NavyBlue()};
`;

/**
 * Any list in article
 */
const bulletList = css`
  margin: 8px 0;
  padding-left: 24px;
  list-style: none;
  transform: translateY(-1px);

  ${media.mobile`
    padding-left: 16px;
  `};

  li::before {
    display: inline-block;
    width: 16px;
    margin-left: -16px;
    content: '•';
    color: ${Colors.DarkGray(0.16)};
  }

  li {
    padding: 4px 0;

    > br + br:last-child {
      display: none;
    }
  }
`;

/**
 * Link in article
 */
const articleLink = css`
  font-weight: ${FontWeight.SemiBold};
`;

/**
 * Did you know sections
 */
const didYouKnow = css`
  ${TextSize.Medium};
  margin-top: 80px;
  margin-left: -17%;
  color: ${Colors.NavyBlue()};

  b {
    display: block;
    margin-bottom: 16px;
  }

  ${media.mobile`
    margin: 0 -24px;
    margin-top: 56px;
    padding: 24px;
    background-color: ${Colors.DarkGray(0.04)};
  `};
`;

const ArticleTitle = styled.div`
  ${articleTitle};
`;

const ArticleBody = styled.div`
  max-width: 640px;

  .title {
    ${articleTitle};
  }

  .h2title {
    ${articleSubtitle};
  }

  .didyouknow {
    ${didYouKnow};
  }

  a {
    ${articleLink};
  }

  ul {
    ${bulletList};
  }
`;
