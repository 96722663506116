import React from 'react';
const SvgEditIcon = React.forwardRef(
  (
    { color = '#003049', ...props }: React.SVGProps<SVGSVGElement>,
    ref: React.Ref<SVGSVGElement>,
  ) => (
    <svg height={24} viewBox="0 0 24 24" width={24} ref={ref} color={color} {...props}>
      <path
        d="M8.672 20.278H4.429v-4.242l13.02-13.021 4.243 4.242zm10.192-13.02L17.45 5.842 6.429 16.864v1.414h1.414z"
        fill="currentColor"
      />
    </svg>
  ),
);
export default SvgEditIcon;
