import React, { useRef, useImperativeHandle } from 'react';
import styled, { css } from 'styled-components';

import { Input as InputEl } from './Input';
import { Colors } from '../Colors';
import CloseIcon from '../icons/small/CloseIcon';
import SearchIcon from '../icons/SearchIcon';
import { BORDER_RADIUS } from '../variables';

type Props = Pick<
  React.InputHTMLAttributes<HTMLInputElement>,
  'className' | 'value' | 'onChange' | 'onBlur' | 'placeholder' | 'disabled'
>;

/**
 * Search input - used for all kinds of autocompletes
 */
export const SearchInput = React.forwardRef<HTMLInputElement | null, Props>(
  ({ className, disabled, ...props }, ref) => {
    const inputRef = useRef<HTMLInputElement>(null);
    useImperativeHandle(ref, () => inputRef.current);

    const clear = () => {
      if (!inputRef.current) return;
      const descriptor = Object.getOwnPropertyDescriptor(HTMLInputElement.prototype, 'value');
      if (descriptor && descriptor.set) descriptor.set.call(inputRef.current, '');
      inputRef.current.dispatchEvent(new Event('change', { bubbles: true }));
      inputRef.current.blur();
    };

    return (
      <Wrapper className={className}>
        <SearchIconWrapper disabled={disabled} />
        <Input type="text" ref={inputRef} disabled={disabled} {...props} />
        {props.value && <CloseButton disabled={disabled} onClick={() => !disabled && clear()} />}
      </Wrapper>
    );
  },
);

const Wrapper = styled.div`
  display: inline-block;
  position: relative;
`;

const SearchIconWrapper = styled(SearchIcon)<{ disabled?: boolean }>`
  cursor: text;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 4px;
  padding: 4px;
  width: 32px;
  height: 100%;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.4;
    `}
`;

const Input = styled(InputEl)`
  padding-left: 40px;
  padding-right: 32px;
  width: 100%;
`;

const CloseButton = styled(CloseIcon)<{ disabled?: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  width: 32px;
  height: 100%;
  border-radius: ${BORDER_RADIUS};
  cursor: pointer;

  &:hover {
    color: ${Colors.NavyBlue(0.64)};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.4;
      cursor: not-allowed;

      &:hover {
        color: initial;
      }
    `}
`;
