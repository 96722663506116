import { Link } from '@reach/router';
import React from 'react';
import styled from 'styled-components';

import {
  Colors,
  Easings,
  media,
  Menu,
  MenuLink,
  MenuList,
  UnstyledMenuButton,
} from '@topia.com/ui-kit';
import PlusIcon from '@topia.com/ui-kit/icons/PlusIcon';
import { useContextPathSupport } from '@topia.com/context-path';

import { useFlags } from '../../../../features';

/**
 * CreateMenu
 */
export const CreateMenu = () => {
  const { withContextPath } = useContextPathSupport();
  const flags = useFlags();

  return (
    <Menu>
      <PlusButton data-testid="plus-button">
        <StyledPlusIcon />
      </PlusButton>
      <StyledMenuList data-testid="create-menu" align="right">
        <UpArrow />
        {flags.uploadExpenses && (
          <MenuLink data-testid="add-expense-link" as={Link} to={withContextPath('/expenses/add')}>
            Upload Expense
          </MenuLink>
        )}
        <MenuLink data-testid="add-file-link" as={Link} to={withContextPath('/files/add')}>
          Upload File
        </MenuLink>
      </StyledMenuList>
    </Menu>
  );
};

const UpArrow = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;

  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid ${Colors.White()};
`;

const StyledMenuList = styled(MenuList)`
  &[data-reach-menu-list] {
    margin-top: 16px;
    margin-right: -8px;
  }
`;

const PlusButton = styled(UnstyledMenuButton)`
  appearance: none;
  border: none;
  background: none;
  padding: 4px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 240ms ${Easings.ExpoOut};

  &:hover {
    background-color: ${Colors.Selection()};
  }
`;

const StyledPlusIcon = styled(PlusIcon)`
  color: ${Colors.SecondaryGray()};

  ${media.mobile`
    color: ${Colors.NavyBlue()};
  `}
`;
