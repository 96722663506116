import React, { Fragment } from 'react';
import styled from 'styled-components';

import { TextSize } from '../TextSize';
import { Colors } from '../Colors';
import { FontWeight } from '../FontWeight';
import { Easings } from '../Easings';

/**
 * Breadcrumbs component
 */
export const BreadCrumbs: React.FC = ({ children }) => {
  return (
    <Wrapper>
      {React.Children.toArray(children)
        .filter(child => child)
        .map((link, idx, all) =>
          idx === all.length - 1 ? link : <Fragment key={idx}>{link}&nbsp;&nbsp;→&nbsp;&nbsp;</Fragment>,
        )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  ${TextSize.Large};
  font-weight: ${FontWeight.Bold};

  &,
  a {
    color: ${Colors.DarkGray(0.24)};
    transition: color 240ms ${Easings.ExpoOut};
  }

  a:hover {
    text-decoration: none;
    color: ${Colors.NavyBlue()};
  }
`;
