/**
 * Available column types
 */
export enum FilterColumnType {
  Boolean = 'boolean',
  String = 'string',
  Number = 'number',
  Date = 'date',
  Array = 'array',
}

/**
 * Definition of filterable column
 */
export interface FilterColumnDefinition {
  /**
   * Label for given filterable column
   */
  label: string;
  /**
   * Field name for the filterable column
   */
  field: string;
  /**
   * Applied filter tag color
   */
  color?: string;
  /**
   * Data type of the filter - drives editor
   */
  type: FilterColumnType;
  /**
   * Allows user to pick a value from dropdown
   */
  predefinedValues?: ({ label: string; value: string } | string | number | DatePeriod)[];
  /**
   * List of operators enabled for particular
   */
  enabledOperatorMappings?: OperatorTypeMapping;
  /**
   * Disables filter field
   */
  disabled?: boolean;
}

/**
 * List of available operators. Note: Operators which work with dropdown are suffixed as "Relative"
 */
export enum Operator {
  /* General */
  IsEmpty = 'is_empty',
  IsNotEmpty = 'is_not_empty',
  Equals = 'equals',
  NotEquals = 'not_equals',

  /* String specific */
  Contains = 'contains',
  NotContains = 'not_contains',

  /* Number specific */
  LesserThan = 'lesser_than',
  LesserThanOrEquals = 'lesser_than_or_equals',
  GreaterThan = 'greater_than',
  GreaterThanOrEquals = 'greater_than_or_equals',
  IsBetween = 'is_between',
  IsNotBetween = 'is_not_between',

  /* Date specific */
  IsIn = 'is_in',
  IsInRange = 'is_in_range',
  IsInYear = 'is_in_year',
  IsInPast = 'is_in_past',
  IsInNext = 'is_in_next',
  IsBefore = 'is_before',
  IsOnOrAfter = 'is_on_or_after',
}

/**
 * Whether an operator is absolute or relative or both
 */
export enum OperatorType {
  None = 'none', // Used in case of booleans
  Absolute = 'absolute',
  Relative = 'relative',
  Range = 'Range',
}

/**
 * Available relative date periods
 */
export enum DatePeriod {
  ThisWeek = 'This week',
  ThisMonth = 'This month',
  ThisQuarter = 'This quarter',
  ThisYear = 'This year',
}

/**
 * Mapping Operator with Operator Types
 */
export type OperatorTypeMapping = Map<Operator, OperatorType[]>;

/**
 * Dictionary of filtered values
 */
export interface FilterValue {
  field: string;
  operator: Operator;
  selectedOperatorType?: OperatorType;
  value: string | number | boolean | DatePeriod;
  rangeValues?: string[];
}

/**
 * Saved filter
 */
export interface SavedFilter {
  id: string;
  name: string;
  values: FilterValue[];
}

/**
 * Configuration for hooking up saved filters
 */
export interface SavedFiltersDefinition {
  /**
   * Active saved filter
   */
  current: string | undefined;
  /**
   * List of available saved filters
   */
  list: SavedFilter[];
  onSelect: (value: string) => void;
  onCreate: (value: Omit<SavedFilter, 'id'>) => string;
  onUpdate: (value: SavedFilter) => void;
  onDelete: (value: SavedFilter) => void;
}

/**
 * Configuration for hooking up filters
 */
export interface FilterDefinition {
  /**
   * Columns to be allowed filtered by
   */
  columns: FilterColumnDefinition[];
  /**
   * Currently applied filter values
   */
  values: FilterValue[];
  /**
   * Event handler if filters change
   */
  onChange: (values: FilterValue[]) => void;

  /**
   * List of saved filters with handling
   */
  savedFilters?: SavedFiltersDefinition;
}
