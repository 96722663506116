import React from 'react';
import { RouteComponentProps } from '@reach/router';
import PageTitle from '@topia.com/ui-kit/PageTitle';
import { Spinner, ErrorOverlay, ErrorBoundary } from '@topia.com/ui-kit';
import { useAsync } from '@topia.com/ui-kit/hooks';

import { useSession } from '../application/api';

import { loadGuide } from './api';
import { Guide } from './components/Guide';
import { EmailSupportText } from '../../shared/EmailSupportText';

const GuideView = (_props: RouteComponentProps) => {
  const { cityId } = useSession();
  if (typeof cityId !== 'number' && typeof cityId !== 'string') throw new Error('Guide view is disabled.');

  const guide = useAsync(loadGuide);

  return (
    <ErrorBoundary>
      <PageTitle>Guide</PageTitle>
      {guide.isLoading && <Spinner label="Loading guide" />}
      {guide.error && (
        <ErrorOverlay
          error={guide.error}
          buttonText="Reload"
          onClose={guide.reload}
          title="Failed to load guide.">
          <EmailSupportText />
        </ErrorOverlay>
      )}
      {guide.data && <Guide {...guide.data} />}
    </ErrorBoundary>
  );
};

export default GuideView;
