import React from 'react';
import styled, { css } from 'styled-components';
import {
  Tabs as ReachTabs,
  TabList as ReachTabList,
  Tab as ReachTab,
  TabPanels as ReachTabPanels,
  TabPanel as ReachTabPanel,
  TabsProps as ReachTabsProps,
  TabProps as ReachTabProps,
  TabPanelProps as ReachTabPanelProps,
  TabContainerProps as ReachTabContainerProps,
} from '@reach/tabs';

import { Colors } from '../Colors';
import { TextSize } from '../TextSize';
import { FontWeight } from '../FontWeight';
import { Easings } from '../Easings';

/**
 * Container for TabList and TabPanels
 */
export const Tabs = ReachTabs as React.FC<ReachTabsProps<any>>;

interface TabProps extends ReachTabProps {
  as?: keyof JSX.IntrinsicElements;
}

/**
 * The tab of the respective TabPanel
 */
export const Tab = React.forwardRef<HTMLElement, TabProps>((props, ref) => (
  <TabRootWrapper>
    <TabRoot {...props} ref={ref}>
      {props.children}
    </TabRoot>
  </TabRootWrapper>
));

const TabRootWrapper = styled.div`
  padding-right: 40px;
`;

const TabRoot = styled(ReachTab)`
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  ${TextSize.Default};
  font-weight: ${FontWeight.SemiBold};
  color: ${Colors.DarkGray(0.48)};

  &:hover {
    color: ${Colors.NavyBlue()};
  }

  &[data-selected] {
    color: ${Colors.NavyBlue()};
  }
`;

interface TabListProps extends ReachTabContainerProps {
  secondary?: boolean;
  as?: keyof JSX.IntrinsicElements;
}

/**
 * Container for Tab's
 */
export const TabList = React.forwardRef<HTMLElement, TabListProps>((props, ref) => (
  <TabListRoot {...props} ref={ref}>
    {props.children}
  </TabListRoot>
));

const TabListRoot = styled(ReachTabList)`
  ${(props: TabListProps) =>
    props.secondary
      ? css`
          background-color: ${Colors.White()};
          display: flex;
          border-bottom: 2px solid ${Colors.Separator()};

          ${TabRoot} {
            border-bottom: 2px solid transparent;
            margin-bottom: -2px;
            padding: 16px 0;
            transition: border-bottom-color 560ms ${Easings.ExpoOut};

            &[data-selected] {
              border-bottom-color: ${Colors.NavyBlue()};
            }
          }
        `
      : css`
          background-color: ${Colors.LightGray(0.2)};
          display: flex;
          align-items: center;
          border-radius: 20px;
          padding: 0 8px;
          height: 32px;

          ${TabRootWrapper} {
            padding: 0;
          }

          ${TabRoot} {
            padding: 0 16px;
          }
        `};
`;

interface TabPanelProps extends ReachTabPanelProps {
  as?: keyof JSX.IntrinsicElements;
}

/**
 * The panel that is shown when the respective Tab is active
 */
export const TabPanel = React.forwardRef<HTMLElement, TabPanelProps>((props, ref) => (
  <TabPanelRoot {...props} ref={ref}>
    {props.children}
  </TabPanelRoot>
));

const TabPanelRoot = styled(ReachTabPanel)`
  &:focus {
    outline: none;
  }
`;

/**
 * Container for TabPanel's
 */
export const TabPanels = ReachTabPanels;
