import { useLayoutEffect } from 'react';

/**
 * Sets application to full width
 */
export function useFullAppWidth() {
  useTemporaryAppCssClass('full-width');
}

/**
 * Hides the footer
 */
export function useWithoutFooter() {
  useTemporaryAppCssClass('no-footer');
}

/**
 * Hook for adding and removing css class to #app temporarily
 */
function useTemporaryAppCssClass(cssClass: string) {
  useLayoutEffect(() => {
    const app = document.getElementById('app');

    if (app) app.classList.add(cssClass);
    return () => {
      if (app) app.classList.remove(cssClass);
    };
  }, []);
}
