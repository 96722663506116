import { LinkGetProps, LinkProps } from '@reach/router';
import React from 'react';
import styled, { css } from 'styled-components';

import { Colors } from '@topia.com/ui-kit';
import { Link, useContextPathSupport } from '@topia.com/context-path';

/**
 * Expose active className on partial matches
 */
const PartiallyActiveLink: React.FunctionComponent<LinkProps<unknown>> = props => {
  const { withContextPath } = useContextPathSupport();

  return (
    <Link
      getProps={({ isCurrent, isPartiallyCurrent, href }: LinkGetProps) => {
        const isActive = href === withContextPath('/') ? isCurrent : isPartiallyCurrent;
        return { className: isActive ? `${props.className} active` : props.className };
      }}
      to={props.to}>
      {props.children}
    </Link>
  );
};

/**
 * Main nav link styles
 */
export const linkStyle = css`
  display: inline-flex;
  align-items: center;
  padding: 0 16px;

  font-size: 20px;
  letter-spacing: -0.67px;
  line-height: 32px;
  color: ${Colors.DarkGray()};

  transition: opacity 160ms;

  &:hover {
    text-decoration: none;
  }
`;

/**
 * Internal links to be used in navigation
 */
export const NavLink = styled(PartiallyActiveLink)`
  ${linkStyle}
`;

/**
 * External links to be used in navigation
 */
export const ExternalNavLink = styled.a`
  ${linkStyle}
`;
