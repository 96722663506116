/**
 * Format numbers
 */
export const formatNumber = new Intl.NumberFormat(undefined, {
  maximumFractionDigits: 0,
}).format;

/**
 * Format percentages
 */
export const formatPercentage = new Intl.NumberFormat(undefined, {
  maximumFractionDigits: 0,
  style: 'percent',
}).format;

/**
 * Format US Dollars
 */
export const formatUSD = new Intl.NumberFormat('en', {
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
  style: 'currency',
  currency: 'USD',
}).format;

/**
 * Format positive ordinals
 */
export const ordinal = (n: number) =>
  n + (['st', 'nd', 'rd'][((((n + 90) % 100) - 10) % 10) - 1] || 'th');
