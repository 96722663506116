import React, { useState } from 'react';
import styled from 'styled-components';
import { TextSize } from '../../../TextSize';
import { Colors } from '../../../Colors';
import { FontWeight } from '../../../FontWeight';
import { Dropdown, SelectElement } from '../../Dropdown';
import { IconButton } from '../../IconButton';
import DeleteIcon from '../../../icons/DeleteIcon';
import ReloadIcon from '../../../icons/ReloadIcon';
import { Button } from '../../Button';
import { Checkbox } from '../../Checkbox';
import { Modal, showModal } from '../../Modal';
import { Input } from '../../Input';
import { SavedViewsDefinition, SavedView } from './types';
import { Tooltip } from '../../Tooltip';
import { FormField } from '../../FormField';

interface Props {
  isChanged: boolean;
  definition: SavedViewsDefinition;
  onClose: () => void;
}

/**
 * UI for saving filters
 */
export const SavedViewPane = ({ definition, isChanged, onClose }: Props) => {
  const done = async (andReload?: boolean) => {
    const promise = andReload ? definition.onReload() : Promise.resolve();
    if (onClose) onClose();
    return promise;
  };

  const views = definition.list || [];
  const view = definition.current || views[0];

  return (
    <Container>
      <Title>Saved Views</Title>
      {views.length === 0 ? (
        <CreateSavedView definition={definition} done={done} />
      ) : (
        <InnerSavedViewPane
          definition={definition}
          views={views}
          done={done}
          view={view}
          whichChanged={(isChanged && view.id) || ''}
        />
      )}
    </Container>
  );
};

interface InnerProps {
  whichChanged: string;
  definition: SavedViewsDefinition;
  views: SavedView[];
  view: SavedView;
  done: (andReload?: boolean) => Promise<any>;
}

const InnerSavedViewPane = ({ definition, whichChanged, done, views, view }: InnerProps) => {
  const [selected, setSelected] = useState<SavedView>(view);

  if ((!selected || !selected.id) && (views && views[0] && views[0].id)) setSelected(views[0]); // re-initialize

  const selecting = async () => {
    definition.onSelect(selected);
    return done();
  };

  const deleting = async () => {
    const yes = await showDeleteModal(selected.name);
    if (yes) return definition.onDelete(selected).then(_ => done(true));
  };

  const savingAs = async () => {
    const sv = await showModal<SaveAsResult>(closeModal => <SaveAs done={closeModal} />);
    if (!sv) return;
    const newView = { ...selected };
    newView.name = sv.name;
    newView.isDefault = sv.isDefault;
    newView.isReadOnly = false;
    const list = await definition.onCreate(newView).then(_ => definition.onReload());
    const reselect = list.find(v => v.name === newView.name);
    if (reselect) {
      setSelected(reselect);
      definition.onSelect(reselect);
    }
    return done();
  };

  return (
    <>
      <ListWrapper>
        <Dropdown
          options={views
            .map(sv => ({ value: sv.id, label: sv.name || '' }))
            .sort((a, b) => (a.label.toLowerCase() < b.label.toLowerCase() ? -1 : +1))}
          value={selected.id}
          onChange={event => {
            const newId = event.target.value;
            setSelected(views.find(v => v.id === newId)!);
          }}
        />
        {selected.id === whichChanged ? (
          <IconButton large onClick={_ => done(true)}>
            <Tooltip content="Revert to original Saved View">
              <ReloadIcon />
            </Tooltip>
          </IconButton>
        ) : (
          <DisableableIconButton large onClick={_ => deleting()} disabled={selected.isReadOnly}>
            <DeleteIcon />
          </DisableableIconButton>
        )}
      </ListWrapper>
      <SetDefaultWrapper disabled={selected.isReadOnly}>
        <Checkbox
          label="Set as preferred view"
          disabled={selected.isReadOnly}
          value={selected.isDefault || false}
          onChange={checked => {
            selected.isDefault = checked;
            setSelected({ ...selected });
          }}
        />
      </SetDefaultWrapper>
      <ButtonWrapper>
        {selected.id === whichChanged && (
          <Button secondary onClick={savingAs}>
            Save as…
          </Button>
        )}
        {selected.id === whichChanged && (
          <Button
            disabled={selected.isReadOnly}
            onClick={() => definition.onUpdate(selected).then(_ => done())}>
            Update
          </Button>
        )}
        {selected.id !== whichChanged && (
          <Button secondary onClick={selecting}>
            Apply
          </Button>
        )}
      </ButtonWrapper>
    </>
  );
};

const CreateSavedView = ({
  definition,
  done,
}: {
  definition: SavedViewsDefinition;
  done: (andReload?: boolean) => Promise<any>;
}) => {
  const [name, setName] = useState('');
  const [isDefault, setIsDefault] = useState(false);
  return (
    <>
      <TitleNote>
        A Saved View displays your chosen filters and columns, ordered and sorted as you need.
      </TitleNote>
      <Input value={name} onChange={e => setName(e.target.value)} placeholder="Name" />
      <SetDefaultWrapper>
        <Checkbox label="Set as preferred view" value={isDefault} onChange={setIsDefault} />
      </SetDefaultWrapper>
      <ButtonWrapper>
        <Button secondary onClick={() => done(false)}>
          Cancel
        </Button>
        <Button onClick={() => definition.onCreate({ name, isDefault }).then(_ => done(true))}>
          Save
        </Button>
      </ButtonWrapper>
    </>
  );
};

type SaveAsResult = Pick<SavedView, 'name' | 'isDefault'> | undefined;

const SaveAs = ({ done }: { done: (x?: SaveAsResult) => void }) => {
  const [name, setName] = useState('');
  const [isDefault, setIsDefault] = useState(false);
  return (
    <ModalAboveTippy>
      <Modal
        visible
        title="New Saved View"
        actions={
          <>
            <Button large secondary onClick={_ => done()}>
              Cancel
            </Button>
            <Button large onClick={_ => done({ name, isDefault })}>
              Save
            </Button>
          </>
        }>
        <FormMatting>
          <FormField label="Name">
            <Input value={name} onChange={event => setName(event.target.value)} />
          </FormField>
          <FormField label="">
            <Checkbox label="Set as preferred view" value={isDefault} onChange={setIsDefault} />
          </FormField>
        </FormMatting>
      </Modal>
    </ModalAboveTippy>
  );
};

const showDeleteModal = async (name: string) =>
  showModal<boolean>(closeModal => (
    <ModalAboveTippy>
      <Modal
        visible
        actions={
          <>
            <Button large secondary onClick={_ => closeModal(false)}>
              Not Now
            </Button>
            <Button large onClick={_ => closeModal(true)}>
              Delete
            </Button>
          </>
        }>
        <Text>{`Delete "${name}"?`}</Text>
      </Modal>
    </ModalAboveTippy>
  ));

const Container = styled.div`
  position: relative;
  padding: 24px;
  padding-bottom: 32px;
  width: 288px;

  ${SelectElement} {
    width: 100%;
  }
`;

const Title = styled.div`
  ${TextSize.SemiMedium};
  font-size: 20px;
  font-weight: ${FontWeight.Bold};
  color: ${Colors.NavyBlue()};
  margin-bottom: 8px;
`;

const TitleNote = styled.div`
  ${TextSize.Small};
  color: ${Colors.DarkGray(0.48)};
  margin-bottom: 16px;
`;

const Text = styled.p`
  ${TextSize.SemiMedium};
  font-weight: ${FontWeight.Bold};
  color: ${Colors.NavyBlue()};
`;

const ListWrapper = styled.div`
  display: flex;
  align-items: center;

  button {
    margin-left: 8px;
    flex-shrink: 0;
  }
  > *:first-child {
    width: 100%;
  }
`;

const DisableableIconButton = styled(IconButton)`
  :disabled {
    opacity: 0.2;
  }
`;

const SetDefaultWrapper = styled.div<{ disabled?: boolean }>`
  margin-top: 16px;
  opacity: ${p => (p.disabled ? 0.2 : 1.0)};
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin: 0 -4px;

  button {
    flex: 1;
    margin: 16px 4px 0 4px;
  }
`;

const ModalAboveTippy = styled.div`
  position: relative;
  z-index: 12001;
`;

const FormMatting = styled.div`
  margin-top: 24px;
  > * {
    margin-bottom: 16px;
  }
`;
