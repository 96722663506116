import { format as dateFnsFormat, parseISO } from 'date-fns';
import { orderBy } from 'lodash';
import React, { useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import {
  AlertStatus,
  Colors,
  FontWeight,
  media,
  Popover,
  TextSize,
  useAlert,
} from '@topia.com/ui-kit';
import { useAsync, useAsyncAction } from '@topia.com/ui-kit/hooks';
import CheckIcon from '@topia.com/ui-kit/icons/CheckIcon';
import ArrowIcon from '@topia.com/ui-kit/icons/small/ArrowIcon';
import { BORDER_RADIUS } from '@topia.com/ui-kit/variables';
import { useContextPathSupport } from '@topia.com/context-path';

import { getMoves, updateActiveMove, useSession } from '../../api';
import { Move } from '../../types';

const formatDate = (date: string) => dateFnsFormat(parseISO(date), 'dd MMM yy');

const formatTitle = ({ type, hostCity }: Move) => `${type} · ${hostCity}`;

const formatDuration = ({ startDate, endDate }: Move) =>
  `${formatDate(startDate)} — ${endDate ? formatDate(endDate) : '...'}`;

/**
 * Shows active move and provides ability to switch between those.
 */
export const MovePicker = () => {
  const moves = useAsync(getMoves);
  const { activeMoveId } = useSession();
  const [updateActiveMoveId] = useAsyncAction(updateActiveMove);
  const menuTippyRef = useRef<{ hide: () => void }>();
  const [open, setOpen] = useState(false);
  const showAlert = useAlert();
  const { withContextPath } = useContextPathSupport();

  if (moves.isLoading || moves.error || moves.data.length === 1) {
    return null;
  }

  const cancelPopover = () => {
    if (menuTippyRef.current) {
      menuTippyRef.current.hide();
    }
  };

  const onClick = (id: number) => {
    cancelPopover();
    updateActiveMoveId({ id })
      .then(() => (window.location.href = withContextPath('/')))
      .catch(err => {
        showAlert('Failed to update active move', { status: AlertStatus.Error });
        throw err;
      });
  };

  const activeMove = moves.data.find(({ id }) => id === activeMoveId)!;

  return (
    <Popover
      content={
        <Dropdown data-testid="move-dropdown">
          {orderBy(moves.data, ['startDate'], ['desc']).map(move => (
            <MoveRow
              key={move.id}
              move={move}
              selected={move.id === activeMove.id}
              onClick={onClick}
            />
          ))}
        </Dropdown>
      }
      placement="bottom-start"
      trigger="click"
      interactive
      noPadding
      onCreate={(tip: any) => (menuTippyRef.current = tip)}
      onShow={() => setOpen(true)}
      onHide={() => setOpen(false)}
      onHidden={cancelPopover}
      arrow={false}
      maxWidth="none">
      <ClickableMove data-testid="active-move" open={open}>
        <MoveDetails>
          <TitleRow data-testid="move-title">{formatTitle(activeMove)}</TitleRow>
          <DateRow data-testid="move-date">{formatDuration(activeMove)}</DateRow>
        </MoveDetails>
        <DropdownIconWrapper>
          <ArrowIcon />
        </DropdownIconWrapper>
      </ClickableMove>
    </Popover>
  );
};

interface MoveRowProps {
  selected: boolean;
  onClick: (id: number) => void;
  move: Move;
}

const MoveRow = ({ selected, onClick, move }: MoveRowProps) => (
  <MoveRowWrapper data-testid="move-row" onClick={() => onClick(move.id)}>
    <MoveDetails>
      <DropdownTitleRow data-testid="move-title">{formatTitle(move)}</DropdownTitleRow>
      <DropdownDateRow data-testid="move-date">{formatDuration(move)}</DropdownDateRow>
    </MoveDetails>
    <SelectedIconWrapper data-testid="selected">{selected && <SelectedIcon />}</SelectedIconWrapper>
  </MoveRowWrapper>
);

const TitleRow = styled.div`
  ${media.mobile`
    font-weight: ${FontWeight.SemiBold};
  `};
`;

const DropdownTitleRow = styled.div`
  font-weight: ${FontWeight.SemiBold};
`;

const DateRow = styled.div`
  color: ${Colors.DarkGray(0.48)};
`;

const DropdownDateRow = styled.div`
  color: ${Colors.SecondaryGray()};
`;

const Dropdown = styled.div`
  padding: 4px 0;
`;

const ClickableMove = styled.div<{ open: boolean }>`
  ${TextSize.Small};
  border-radius: ${BORDER_RADIUS};
  display: flex;
  font-weight: ${FontWeight.Regular};
  min-width: 250px;
  padding: 4px 8px 4px 12px;

  ${media.mobile`
    font-size: 14px;
    line-height: 18px;
    padding-bottom: 10px;
    padding-top: 10px;
  `};

  &:hover {
    background: ${Colors.DarkGray(0.04)};
    cursor: pointer;
  }

  ${({ open }) =>
    open &&
    css`
      background: ${Colors.DarkGray(0.04)};
    `}

  ${media.mobile`
      background: ${Colors.DarkGray(0.04)};
  `};
`;

const MoveDetails = styled.div`
  flex: 1;
  font-weight: ${FontWeight.Regular};
`;

const DropdownIconWrapper = styled.div`
  margin-bottom: auto;
  margin-left: 12px;
  margin-top: auto;
`;

const MoveRowWrapper = styled.div`
  cursor: pointer;
  display: flex;
  padding: 8px 16px;

  &:hover {
    background-color: ${Colors.DarkGray(0.04)};
  }
`;

const SelectedIconWrapper = styled.div`
  margin-left: 32px;
  width: 24px;
`;

const SelectedIcon = styled(CheckIcon)`
  margin-top: 50%;
`;
