import { Tradeoff, CommuteMode, RentBedroomSize } from '../types';

/**
 * Planner modes
 */
export const PlannerModeRoutes = [
  {
    label: 'Best Match',
    value: '/neighborhoods/score',
  },
  {
    label: 'Rent',
    value: '/neighborhoods/rent',
  },
  {
    label: 'Commute',
    value: '/neighborhoods/commute',
  },
];

/**
 * Labels shown in tradeoff dropdown
 */
export const TRADEOFF_LABELS = {
  [Tradeoff.Balanced]: 'Both',
  [Tradeoff.LowerRent]: 'Rent',
  [Tradeoff.FasterCommute]: 'Commute',
};

/**
 * Labels shown in commute mode dropdown
 */
export const COMMUTE_LABELS = {
  [CommuteMode.Driving]: 'Driving',
  [CommuteMode.Transit]: 'Transit',
  [CommuteMode.Bicycling]: 'Cycling',
  [CommuteMode.Walking]: 'Walking',
};

/**
 * Labels shown in bedrooms dropdown
 */
export const BEDROOM_LABELS = {
  [RentBedroomSize.Studio]: 'Studio',
  [RentBedroomSize.Beds1]: '1 bedroom',
  [RentBedroomSize.Beds2]: '2 bedroom',
  [RentBedroomSize.Beds3]: '3 bedroom',
};
