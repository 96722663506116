import React from 'react';
import styled from 'styled-components';
import { Colors, TextSize } from '@topia.com/ui-kit';
import { BORDER_RADIUS } from '@topia.com/ui-kit/variables';

interface Props {
  name: string;
  commute: string;
  rent: string;
  color: string;
  style?: React.CSSProperties;
}

/**
 * Neighborhoods list item
 */
export const NeighborhoodItem: React.FunctionComponent<Props> = React.memo(props => (
  <Container data-testid="neighborhood-item" style={props.style}>
    <Dot data-testid="dot" style={{ backgroundColor: props.color }} />
    <Name data-testid="item-name">{props.name}</Name>
    <Stats data-testid="item-commute-stats">{props.commute}</Stats>
    <Stats data-testid="item-rent-stats">{props.rent}</Stats>
  </Container>
));

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;

  ${TextSize.Small};
  border-radius: ${BORDER_RADIUS};

  &:hover {
    background-color: ${Colors.DarkGray(0.04)};
  }
`;

const Dot = styled.div`
  border-radius: 100%;
  width: 12px;
  height: 12px;
  flex-basis: 12px;
  margin-right: 8px;
`;

const Name = styled.div`
  flex: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const Stats = styled.div`
  margin-left: 16px;
  min-width: 50px;
  text-align: right;
`;
