import React from 'react';
const SvgDownloadIcon = React.forwardRef(
  (
    { color = '#003049', ...props }: React.SVGProps<SVGSVGElement>,
    ref: React.Ref<SVGSVGElement>,
  ) => (
    <svg height={24} viewBox="0 0 24 24" width={24} ref={ref} color={color} {...props}>
      <g fill="none" fillRule="evenodd" stroke="currentColor" strokeWidth={2}>
        <path d="M18 10l-6 6-6-6M12 14V4" strokeLinecap="square" />
        <path d="M22 20H2" />
      </g>
    </svg>
  ),
);
export default SvgDownloadIcon;
