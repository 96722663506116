import React from 'react';
import { Grid } from '@topia.com/ui-kit';

import { PeopleWidgetData } from '../../types';
import { Label, LargeFigure, Subtitle } from '../WidgetComponents';
import { Row } from '.';

/**
 * Languages section in people widget
 */
export const LanguagesSection = ({ english_skills, spoken_languages }: PeopleWidgetData) => (
  <>
    <Subtitle>Languages</Subtitle>
    <Row>
      {english_skills && (
        <Grid.Item size={4} mobileSize={6}>
          <Label>English proficiency score</Label>
          <LargeFigure>{english_skills.ua.value}</LargeFigure>
        </Grid.Item>
      )}
      {spoken_languages && (
        <Grid.Item size={4} mobileSize={6}>
          <Label>Common spoken languages</Label>
          <LargeFigure>{spoken_languages.join(', ')}</LargeFigure>
        </Grid.Item>
      )}
    </Row>
  </>
);
