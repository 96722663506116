import React from 'react';
import styled, { css } from 'styled-components';

import { Colors } from '../../Colors';
import { IconButton } from '../IconButton';
import FileIcon from '../../icons/FileIcon';
import DeleteIcon from '../../icons/DeleteIcon';
import DownloadIcon from '../../icons/DownloadIcon';
import { BORDER_RADIUS } from '../../variables';

/**
 * A file that has been uploaded, can optionally show preview
 */
export interface AttachedFile {
  name: string;
  extension: string;
  mime?: string;
  preview?: File | string;
}

interface Props extends AttachedFile {
  onRemove?: () => void;
  onRequestDownload?: () => void;
}

const getPreviewUrl = (file: File | string) =>
  typeof file === 'string' ? file : file.type.startsWith('image') && URL.createObjectURL(file);

/**
 * Preview of attached file
 */
export const AttachedFilePreview = ({
  name,
  extension,
  preview,
  onRemove,
  onRequestDownload,
}: Props) => {
  const previewUrl = preview && getPreviewUrl(preview);
  return (
    <LargeWrapper>
      <PreviewBox>{previewUrl ? <img alt={name} src={previewUrl} /> : <FileIcon />}</PreviewBox>
      <FileName onClick={onRequestDownload} downloadEnabled={!!onRequestDownload}>
        {`${name}${extension ? `.${extension}` : ''}`}
      </FileName>
      {onRequestDownload && (
        <IconButton type="button" large>
          <DownloadIcon onClick={onRequestDownload} />
        </IconButton>
      )}
      {onRemove && (
        <IconButton type="button" large>
          <DeleteIcon onClick={onRemove} />
        </IconButton>
      )}
    </LargeWrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  text-align: left;
  font-size: 14px;
  line-height: 24px;
`;

const FileName = styled.div`
  flex: 1;
  color: ${Colors.DarkGray(0.4)};

  ${(props: { downloadEnabled?: boolean }) =>
    props.downloadEnabled &&
    css`
      :hover {
        text-decoration: underline;
        cursor: pointer;
      }
    `};
`;

const LargeWrapper = styled(Wrapper)`
  margin-bottom: 8px;

  > svg {
    cursor: pointer;
  }

  ${FileName} {
    color: ${Colors.DarkGray()};
  }
`;

const PreviewBox = styled.div`
  border-radius: ${BORDER_RADIUS};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background-color: ${Colors.DarkGray(0.04)};
  margin-right: 16px;

  ${(props: { large?: boolean }) =>
    props.large &&
    css`
      width: 64px;
      height: 64px;
      margin-right: 24px;
    `};

  > img {
    max-width: 100%;
    max-height: 100%;
  }
`;
