import React from 'react';
import styled from 'styled-components';
import EditIcon from '@topia.com/ui-kit/icons/EditIcon';

import { useOfficeContext } from '../OfficeProvider';
import { Office } from '../../types';

interface Props {
  office: Office;
}

/**
 * Allows editing single office
 */
export const SingleOfficeEditor = ({ office }: Props) => {
  const { clearOffices, editableOffice } = useOfficeContext();
  if (!editableOffice) return <Label>{office.label}</Label>;

  return (
    <Wrapper onClick={clearOffices}>
      <Label>{office.label}</Label>
      <EditIcon />
    </Wrapper>
  );
};

const Label = styled.span`
  max-width: 180px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: flex-end;
  cursor: pointer;
  margin-right: -6px;

  svg {
    height: 16px;
    transform: translateY(-1px);
  }

  &:hover ${Label} {
    text-decoration: underline;
  }
`;
