import {
  Link as ReachLink,
  LinkProps,
  navigate as reachNavigate,
  NavigateOptions,
  Redirect as ReachRedirect,
  RedirectProps,
} from '@reach/router';
import React, { useContext } from 'react';

interface Props {
  contextPath: string;
  navigate: (to: string, options?: NavigateOptions<{}>) => Promise<void>;
  withContextPath: (to: string) => string;
}

const ContextPathContext = React.createContext<Props | null>(null);

/**
 * React context to propagate context-path throughout app.
 */
export const ContextPathProvider = ({
  children,
  contextPath,
}: {
  children: React.ReactNode | ((props: Props) => React.ReactNode);
  contextPath: string;
}) => {
  const withContextPath = (to: string) => (to[0] === '/' ? contextPath : '') + to;
  const navigate = (to: string, options?: NavigateOptions<{}>) =>
    reachNavigate(withContextPath(to), options);
  const value = { contextPath, navigate, withContextPath };

  return (
    <ContextPathContext.Provider value={value}>
      {typeof children === 'function' ? children(value) : children}
    </ContextPathContext.Provider>
  );
};

/**
 * Reach Link with context path.
 */
export const Link = React.forwardRef<HTMLAnchorElement, LinkProps<HTMLAnchorElement>>(
  ({ to, ...props }, ref) => {
    const { withContextPath } = useContextPathSupport();

    return <ReachLink {...props} ref={ref as React.RefObject<any>} to={withContextPath(to)} />;
  },
);

/**
 * Reach Redirect with context path.
 */
export const Redirect = ({ to, ...props }: RedirectProps<unknown>) => {
  const { withContextPath } = useContextPathSupport();

  return <ReachRedirect to={withContextPath(to)} {...props} />;
};

/**
 * Hook to expose context.
 */
export const useContextPathSupport = () => {
  const context = useContext(ContextPathContext);

  if (context === null) {
    throw new Error('Missing context, did you use <ContextPathProvider>?');
  }

  return context;
};
