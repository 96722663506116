import React, { forwardRef, InputHTMLAttributes, Ref, useState } from 'react';
import { endOfDay, format as dateFnsFormat, isValid, parse, parseISO } from 'date-fns';
import styled, { css } from 'styled-components';
import { DayPickerInputProps, DayPickerProps } from 'react-day-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';

import { Input } from '../Input';
import { Colors } from '../../Colors';
import { FontWeight } from '../../FontWeight';
import { BORDER_RADIUS, DEFAULT_BOX_SHADOW } from '../../variables';
import CloseIcon from '../../icons/small/CloseIcon';
import CalendarIcon from '../../icons/CalendarIcon';

import { bodyStyles, weekdayStyles, wrapperStyles } from './styles';
import { YearMonthSwitcher } from './YearMonthSwitcher';
import { NavBarElement } from './NavbarElement';

interface Props extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'value'> {
  onChange: (date?: string) => void;
  value?: string;
  className?: string;
  dayPickerProps?: DayPickerProps;
  dayPickerInputProps?: Omit<DayPickerInputProps, 'onChange' | 'value' | 'disabled' | 'format'>;
  testId?: string;
}

const defaultDateFormat = 'dd MMM yyyy';

const DayPickerInputWrapper = ({
  className = '',
  name,
  value,
  autoFocus,
  onChange,
  disabled,
  dayPickerProps,
  dayPickerInputProps,
  testId,
}: Props) => {
  const [switcherMonth, setSwitcherMonth] = useState(new Date());

  const placeholder =
    dayPickerInputProps && dayPickerInputProps.placeholder
      ? dayPickerInputProps.placeholder
      : dateFnsFormat(new Date(), defaultDateFormat);

  return (
    <DayPickerInput
      format={defaultDateFormat}
      placeholder={placeholder}
      formatDate={(date, format, locale: any) => dateFnsFormat(date, format, { locale })}
      parseDate={(dateStr, format, locale: any) => {
        const date = parse(dateStr, format, new Date(), { locale });
        return isValid(date) ? date : undefined;
      }}
      value={value ? parseISO(value) : undefined}
      onDayChange={(date, modifiers) => {
        if (!date || modifiers.disabled) {
          return;
        }
        onChange(endOfDay(date).toISOString());
      }}
      dayPickerProps={{
        showOutsideDays: true,
        firstDayOfWeek: 1,
        weekdaysShort: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
        month: switcherMonth,
        captionElement: ({ date, localeUtils }) => (
          <YearMonthSwitcher
            date={date}
            localeUtils={localeUtils}
            onChange={setSwitcherMonth}
            {...dayPickerProps}
          />
        ),
        navbarElement: NavBarElement,
        ...dayPickerProps,
      }}
      component={CustomInput}
      classNames={{
        container: className,
        overlay: 'DayPickerInput-Overlay',
        overlayWrapper: 'DayPickerInput-OverlayWrapper',
      }}
      inputProps={{
        autoFocus,
        name,
        disabled,
        onDayPickerInputChange: onChange,
        'data-testid': testId,
      }}
      {...dayPickerInputProps}
    />
  );
};

interface CustomInputProps extends InputHTMLAttributes<HTMLInputElement> {
  onDayPickerInputChange: (date?: string) => void;
}

const CustomInput = forwardRef(
  (
    { name, value, disabled, onDayPickerInputChange, ...props }: CustomInputProps,
    ref: Ref<HTMLInputElement>,
  ) => {
    return (
      <Wrapper disabled={disabled}>
        <InputElement {...props} ref={ref} name={name} value={value} disabled={disabled} />
        {value && !disabled && (
          <CloseButton onClick={() => onDayPickerInputChange(undefined)}>
            <CloseIcon />
          </CloseButton>
        )}
        <StyledCalendarIcon />
      </Wrapper>
    );
  },
);

/**
 * Topia style date picker with faux input
 */
export const DatePickerInput = styled(DayPickerInputWrapper)`
  ${wrapperStyles};
  ${weekdayStyles};
  ${bodyStyles};

  .DayPickerInput {
    display: inline-block;
  }

  .DayPickerInput-OverlayWrapper {
    position: relative;
  }

  .DayPickerInput-Overlay {
    position: absolute;
    top: 8px;
    left: 0;
    z-index: 1000;
    min-width: 244px;

    background-color: ${Colors.White()};
    border-radius: ${BORDER_RADIUS};
    box-shadow: ${DEFAULT_BOX_SHADOW};

    &::before {
      content: '';
      position: absolute;
      top: -7px;
      left: 16px;

      border-bottom: 8px solid ${Colors.White()};
      border-right: 8px solid transparent;
      border-left: 8px solid transparent;
    }
  }

  .DayPicker-Caption {
    display: table-caption;
    margin-bottom: 8px;
    padding: 0 8px;
    text-align: left;

    > div {
      font-weight: ${FontWeight.SemiBold};
      font-size: 20px;
      line-height: 24px;
    }
  }
`;

const Wrapper = styled.div<{ disabled?: boolean }>`
  display: inline-block;
  position: relative;

  ${({ disabled }) =>
    disabled &&
    css`
      ${StyledCalendarIcon} {
        opacity: 0.4;
      }

      ${InputElement} {
        cursor: not-allowed;
        color: ${Colors.DarkGray(0.4)};
      }
    `};
`;

const InputElement = styled(Input)`
  background: ${Colors.White()};
  color: ${Colors.DarkGray()};
  width: 100%;
  cursor: text;
  padding-right: 60px;
`;

const StyledCalendarIcon = styled(CalendarIcon)`
  position: absolute;
  right: 8px;
  top: 50%;
  width: 24px;
  height: 24px;
  margin: -12px 0 0 0;
  pointer-events: none;
`;

const CloseButton = styled.button.attrs({
  type: 'button',
})`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 40px;
  top: 50%;
  width: 20px;
  height: 20px;
  margin: -10px 0 0 0;
  padding: 0;
  background: ${Colors.DarkGray(0.04)};
  border: none;
  border-radius: 100%;
  box-shadow: none;
  appearance: none;
  cursor: pointer;

  &:hover {
    background-color: ${Colors.DarkGray(0.08)};
  }
`;
