import React from 'react';
import styled from 'styled-components';

import { Colors } from '../Colors';
import { FontWeight } from '../FontWeight';
import { TextSize } from '../TextSize';
import { Tooltip } from './Tooltip';
import { NumberFormatter } from './NumberFormatter';

interface StackedHorizontalBarChartProps {
  /**
   * Currency code row items
   */
  currencyCode: string;
  /**
   * Array of row items
   */
  rows: RowItemProps[];
}
interface RowItemProps {
  /**
   * Row label or title
   */
  label: string;
  /**
   * Array of bars/sections/segements items
   */
  bars: BarItemProps[];
}

interface BarItemProps {
  /**
   * Bar label
   */
  label: string;
  /**
   * Bar amount
   */
  amount: number;
  /**
   * Color for bar segement
   */
  color: string;
}
interface BarPopoverProps {
  color: string;
  bar: BarItemProps;
  currencyCode: string;
}

interface BarGroupProps {
  /**
   * Total for entire row i.e individual bar segements
   */
  rowTotal: number;
  /**
   * Bar items
   */
  bars: BarItemProps[];
  /**
   * Currency code
   */
  currencyCode: string;
}

const colors = [
  Colors.SkyBlue(),
  Colors.SeaShell(),
  Colors.NavyBlue(),
  Colors.Blonde(),
  Colors.YellowSoda(),
  Colors.LightBlue(),
];

/**
 *  Bar popover or tooltip
 */
const BarPopover = ({ bar, currencyCode, color }: BarPopoverProps) => {
  return (
    <>
      <BarPopoverHeader>
        <BarPopoverShape color={color} />
        <BarPopoverLabel>{bar.label}</BarPopoverLabel>
      </BarPopoverHeader>
      <BarPopoverAmount>
        {`${currencyCode} `}
        <NumberFormatter amount={bar.amount} />
      </BarPopoverAmount>
    </>
  );
};

const BarGroup = ({ rowTotal, bars, currencyCode }: BarGroupProps) => {
  return (
    <div>
      {bars.map((bar, index) => (
        <Tooltip
          key={bar.label}
          content={
            <BarPopover
              bar={bar}
              currencyCode={currencyCode}
              color={colors[index % colors.length]}
            />
          }>
          <Bar rowTotal={rowTotal} bar={bar} color={colors[index % colors.length]} />
        </Tooltip>
      ))}
    </div>
  );
};

/**
 * Stacked Horizontal Bar Chart
 */
export const StackedHorizontalBarChart = ({
  rows,
  currencyCode,
}: StackedHorizontalBarChartProps) => {
  // Sum up amounts reducer functoin
  const sumUpAmounts = (total: number, current: BarItemProps) => total + current.amount;

  // Sum up all rows total amount
  const totals = rows.map(row => row.bars.reduce(sumUpAmounts, 0));

  // Find the max row total
  const maxRowTotal = Math.max(...totals);

  return (
    <RowsList>
      {rows.map(row => (
        <Row key={row.label} rowWidth={row.bars.reduce(sumUpAmounts, 0) / maxRowTotal}>
          <RowHeader>
            <RowLabel>{row.label}</RowLabel>
            <RowAmount>
              <NumberFormatter amount={row.bars.reduce(sumUpAmounts, 0)} />
            </RowAmount>
          </RowHeader>
          <BarGroup
            rowTotal={row.bars.reduce(sumUpAmounts, 0)}
            bars={row.bars}
            currencyCode={currencyCode}
          />
        </Row>
      ))}
      <TotalAmountText>
        {`Total: ${currencyCode} `}
        <NumberFormatter amount={totals.reduce((total, current) => total + current, 0)} />
      </TotalAmountText>
    </RowsList>
  );
};

interface BarElementProps {
  rowTotal: number;
  color: string;
  bar: BarItemProps;
}

interface BarPopoverShapeProps {
  color: string;
}

const RowsList = styled.div``;

interface RowProps {
  rowWidth: number;
}

const Row = styled.div`
  width: ${({ rowWidth }: RowProps) => `${rowWidth * 100}%`};
  margin-bottom: 16px;
`;

const RowHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const RowLabel = styled.div`
  ${TextSize.Small};
  font-weight: ${FontWeight.SemiBold};
  color: ${Colors.DarkGray(0.48)};
`;

const RowAmount = styled.div`
  ${TextSize.Small};
  color: ${Colors.DarkGray()};
`;

const TotalAmountText = styled.div`
  ${TextSize.Small}
  color: ${Colors.DarkGray()};
  font-weight: ${FontWeight.SemiBold};
`;

const Bar = styled.div`
  display: inline-block;
  height: 8px;
  background-color: ${({ color }) => color};
  width: ${({ rowTotal, bar }: BarElementProps) => {
    return `${(bar.amount / rowTotal) * 100}%`;
  }};
  background-color: ${({ bar }: BarElementProps) => bar.color};
  &:not(:last-child) {
    border-right: 2px solid ${Colors.White()};
  }
  &:first-child {
    border-radius: 4px 0 0 4px;
  }
  &:last-child {
    border-radius: 0 4px 4px 0px;
  }
  &:only-child {
    border-radius: 4px;
  }
`;

const BarPopoverHeader = styled.div`
  display: flex;
  align-items: center;
`;

const BarPopoverShape = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: blue;
  margin-right: 8px;
  background-color: ${({ color }: BarPopoverShapeProps) => color};
`;

const BarPopoverLabel = styled.div`
  ${TextSize.Small};
  color: ${Colors.DarkGray(0.48)};
`;

const BarPopoverAmount = styled.div`
  color: ${Colors.DarkGray()};
  font-size: ${TextSize.Default};
  font-weight: ${FontWeight.Regular};
`;
