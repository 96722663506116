import { registerFlags } from '@topia.com/feature-flags';

import config from './config';

/**
 * Register feature flags
 */
export const { useFlags, useFlagContext, FlagManager, FlagProvider } = registerFlags({
  uiDebugGrid: {
    label: 'UI: Debug grid',
    isEnabled: false,
  },
  manageTasks: {
    label: 'Manage tasks integration',
    isEnabled: false,
  },
  uploadExpenses: {
    label: 'Expenses',
    isEnabled: false,
  },
  inboxV2: {
    label: 'New inbox-api',
    isEnabled: config.fusionEnv,
  },
  metrics: {
    label: 'Send metrics',
    isEnabled: true,
  },
});
