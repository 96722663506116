import React, { useState } from 'react';
import styled from 'styled-components';

import { Colors } from '../../Colors';
import MinusIcon from '../../icons/MinusIcon';
import PlusIcon from '../../icons/PlusIcon';

import { ColumnDefinition, SubcontentDefinition } from './types';

interface Props<D> {
  columns: ColumnDefinition<D>[];
  subcontent?: SubcontentDefinition<D, keyof D>;
}

/**
 * Table row subcontent helper hook
 */
export const useRowSubcontent = <D extends object>({
  columns,
  subcontent,
}: Props<D>): [ColumnDefinition<D>[], Set<unknown>] => {
  const [internalExpandedRows, setExpandedRows] = useState<Set<unknown>>(new Set());

  if (!subcontent) return [columns, internalExpandedRows];

  const expandedRows = subcontent.expandedRows || internalExpandedRows;

  const toggleExpansion = (key: D[keyof D], value: boolean) => {
    if (subcontent.onExpand && subcontent.onCollapse) {
      return value ? subcontent.onExpand(key) : subcontent.onCollapse(key);
    }
    setExpandedRows(value ? new Set([key]) : new Set());
  };

  return [
    [
      {
        label: '',
        cellStyle: {
          position: 'relative',
          padding: 0,
        },
        render(row: D) {
          const key = row[subcontent.expansionColumn];
          const isExpanded = expandedRows.has(key);

          return (
            <IconButton onClick={() => toggleExpansion(key, !isExpanded)}>
              {isExpanded ? <MinusIcon /> : <PlusIcon />}
            </IconButton>
          );
        },
      },
      ...columns,
    ],
    expandedRows,
  ];
};

const IconButton = styled.div`
  padding: 6px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  margin-top: -15px;
  left: -40px;

  svg {
    padding: 3px;
    border-radius: 100%;
    background-color: ${Colors.LightBlue()};
    width: 20px;
    height: 20px;
  }
`;
