import React from 'react';
import styled, { css } from 'styled-components';

import { FontWeight } from '../FontWeight';
import { Colors } from '../Colors';
import { TextSize } from '../TextSize';
import { media } from '../MediaQuery';
import { InlineFormField, Props as InlineFormFieldProps } from './InlineFormField';

interface Props {
  className?: string;
  /**
   * Label text/element
   */
  label: React.ReactNode;
  /**
   * Associate label with input
   */
  labelFor?: string;
  /**
   * Help text/element underneath label
   */
  help?: string | React.ReactNode;
  /**
   * Show optional field label
   */
  optional?: boolean;
  /**
   * Test attribute for test automation
   */
  testId?: string;
}

/**
 * Form input wrapper with label and other help texts
 */
export const FormField: React.FC<Props & Omit<InlineFormFieldProps, 'className'>> = ({
  label,
  labelFor,
  help,
  className,
  optional,
  testId,
  ...props
}) => {
  return (
    <Wrapper className={className} data-testid={testId}>
      <LabelContainer>
        <Label htmlFor={labelFor}>
          {label}
          {optional && <OptionalLabel>optional</OptionalLabel>}
        </Label>
        {help && <Help isText={typeof help === 'string'}>{help}</Help>}
      </LabelContainer>
      <StyledInlineFormField {...props} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 24px;
`;

const LabelContainer = styled.div`
  flex-shrink: 0;
  flex-basis: 152px;
  margin-right: 24px;
  margin-bottom: 8px;

  ${media.mobile`
    flex-basis: 100%;
  `};
`;

const Label = styled.label`
  display: block;
  margin-top: 8px;

  ${TextSize.Default};
  font-weight: ${FontWeight.SemiBold};
`;

const OptionalLabel = styled.sup`
  font-style: italic;
  color: ${Colors.DarkGray(0.4)};
  margin-left: 4px;
  font-size: 10px;
  line-height: 1;
`;

const Help = styled.div<{ isText: boolean }>`
  margin-top: 8px;

  ${TextSize.Small};
  ${({ isText }) =>
    isText &&
    css`
      color: ${Colors.DarkGray(0.56)};
    `}
`;

const StyledInlineFormField = styled(InlineFormField)`
  flex: 1;
`;
