import React from 'react';

import {
  PlannerMode,
  PlannerSettings,
  LatLngLiteral,
  CityMetadata,
  Office,
  ScoreFeatureCollection,
} from '../types';
import Map from './Map';
import { GeoJSONProvider } from './GeoJSONProvider';
import { MobileOverlay } from './Overlay/Mobile';
import { DefaultOverlay } from './Overlay/Default';
import { NeighborhoodList } from './Overlay/NeighborhoodList';

interface Props {
  mode: PlannerMode;
  meta: CityMetadata;
  initialSettings: PlannerSettings;
  offices: Office[];
  onPinSet: (latLng: LatLngLiteral) => void;
  onSettingsUpdated: (changed: string, settings: PlannerSettings) => void;
  onFeatureHover: (mode: PlannerMode) => void;
}

interface State {
  pinLocation: LatLngLiteral | null;
  settings: PlannerSettings;
}

/**
 * Planner - contains neighborhood planner functionality
 */
export class Planner extends React.Component<Props, State> {
  /**
   * Pick first office if user hasn't selected any
   */
  static getDerivedStateFromProps({ offices }: Props, state: State) {
    if (offices.length === 0 || (state.settings.activeOffice && offices.length > 1)) return null;
    return { ...state, settings: { ...state.settings, activeOffice: offices[0] } };
  }

  constructor(props: Props) {
    super(props);
    this.state = { pinLocation: null, settings: props.initialSettings };
  }

  updateSettings = (settings: PlannerSettings) => {
    this.setState({ settings });
  };

  setPinLocation = (pinLocation: LatLngLiteral | null) => {
    if (pinLocation) this.props.onPinSet(pinLocation);
    this.setState({ pinLocation });
  };

  render() {
    const { mode, meta, offices } = this.props;
    const { settings, pinLocation } = this.state;
    const { activeOffice, maxCommute, maxRent, ...scoringSettings } = settings;
    const overlayProps = {
      settingsProps: {
        mode,
        meta,
        offices,
        settings,
        onChange: this.updateSettings,
        onSettingsUpdated: this.props.onSettingsUpdated,
      },
      suggestProps: { bbox: meta.bbox, value: pinLocation, onChange: this.setPinLocation },
    };
    const mapProps = {
      mode,
      meta,
      settings,
      pinLocation,
      onFeatureHover: this.props.onFeatureHover,
    };

    return (
      <GeoJSONProvider
        cityId={meta.id}
        mode={mode}
        office={activeOffice}
        scoringSettings={scoringSettings}>
        {geoJSON => (
          <>
            <Map {...mapProps} geoJSON={geoJSON} />
            {offices.length > 0 && <MobileOverlay {...overlayProps} />}
            {offices.length > 0 && (
              <DefaultOverlay {...overlayProps}>
                {geoJSON && mode === PlannerMode.Score && (
                  <NeighborhoodList
                    data={geoJSON as ScoreFeatureCollection}
                    settings={settings}
                    currencyCode={meta.currency}
                  />
                )}
              </DefaultOverlay>
            )}
          </>
        )}
      </GeoJSONProvider>
    );
  }
}
