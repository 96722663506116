import flatten from 'lodash/flatten';

import { SectionType, GuidePage } from '../types';

const cleanLabel = (label: string) =>
  label
    .replace(/<\S+?>/g, '')
    .replace(/&amp;/g, '&')
    .replace(/&nbsp;/g, ' ');

/**
 * Pull out searchable items from guide response
 */
export const extractSearchableItems = (pages: GuidePage[]) =>
  flatten(
    flatten(
      pages.map(page =>
        page.sections.map(section => {
          if (section.type === SectionType.Widget) {
            return [
              {
                id: section.id.toString(),
                label: section.label || '',
                page: page.id,
                content: '',
                humanLabel: section.label || '',
              },
            ];
          }
          const hasSingleArticle = section.articles.length === 1;

          return section.articles.map(a => ({
            id: a.id,
            label: a.label,
            page: page.id,
            content: a.content,
            humanLabel: cleanLabel(
              (hasSingleArticle ? [page.label, a.label] : [section.label, a.label]).join(' - '),
            ),
          }));
        }),
      ),
    ),
  ).filter(r => r.id && r.humanLabel);
