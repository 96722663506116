import React, { forwardRef, InputHTMLAttributes, Ref } from 'react';
import styled, { css } from 'styled-components';

import { Colors } from '../Colors';
import { BORDER_RADIUS } from '../variables';

const InputField = forwardRef(
  (props: InputHTMLAttributes<HTMLInputElement>, ref: Ref<HTMLInputElement>) => (
    <input ref={ref} {...props} />
  ),
);

/**
 * Shared common inputs style
 */
export const inputStyle = css`
  font-size: 14px;
  line-height: 24px;
  padding: 4px 12px;

  appearance: none;
  color: ${Colors.DarkGray()};
  border-radius: ${BORDER_RADIUS};
  border: none;
  background-color: ${Colors.White()};
  box-shadow: 0 1px 4px 0 ${Colors.NavyBlue(0.16)};

  &::placeholder {
    color: ${Colors.DarkGray(0.4)};
  }

  &::-ms-clear {
    display: none;
  }

  &[readonly] {
    cursor: default;
    color: ${Colors.DarkGray(0.56)};
  }

  &[disabled] {
    cursor: not-allowed;
    color: ${Colors.DarkGray(0.56)};
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px ${Colors.LightBlue(0.4)}, 0 1px 4px 0 ${Colors.DarkGray(0.08)};
  }

  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }
`;

/**
 * Input element - basis for more complex fields
 */
export const Input = styled(InputField)`
  ${inputStyle}
`;
