import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Match } from '@reach/router';
import { FontWeight, Colors, MediaQuery, Easings, Dropdown, TextSize } from '@topia.com/ui-kit';
import CloseIcon from '@topia.com/ui-kit/icons/small/CloseIcon';
import ArrowIcon from '@topia.com/ui-kit/icons/small/ArrowIcon';
import { BORDER_RADIUS } from '@topia.com/ui-kit/variables';

import Settings, { SettingsProps } from './Settings';
import { AddressSuggest, AddressSuggestProps } from '../AddressSuggest';
import { PlannerModeRoutes } from '../../data/labels';

interface Props {
  settingsProps: SettingsProps;
  suggestProps: AddressSuggestProps;
}

/**
 * Mobile overlay with settings collapse toggle
 */
export const MobileOverlay = ({ settingsProps, suggestProps }: Props) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <MediaQuery.Mobile>
      <ModeDropdown />
      <MobileAddressSuggest collapseToIcon {...suggestProps} />
      <SettingsToggle onClick={() => setOpen(!isOpen)}>
        FILTERS
        {isOpen ? (
          <CloseIcon color={Colors.DarkGray()} />
        ) : (
          <FlippedArrowIcon color={Colors.DarkGray()} />
        )}
      </SettingsToggle>
      <MobileSettings open={isOpen} {...settingsProps} />
    </MediaQuery.Mobile>
  );
};

const FlippedArrowIcon = styled(ArrowIcon)`
  transform: scaleY(-1);
`;

const MobileAddressSuggest = styled(AddressSuggest)`
  position: absolute;
  top: 16px;
  left: 16px;
  z-index: 10;
`;

const MobileSettings = styled(Settings)`
  position: absolute;
  margin-top: 0;
  z-index: 10;
  top: 100%;
  width: 100%;
  border-radius: 0;
  transition: transform 240ms ${Easings.ExpoOut};

  ${(props: { open: boolean }) =>
    props.open &&
    css`
      transform: translateY(-100%);
    `}
`;

const SettingsToggle = styled.button`
  display: flex;
  align-items: center;
  position: absolute;
  right: 16px;
  top: 16px;
  padding: 6px 6px 6px 16px;

  ${TextSize.Small};
  line-height: 24px;
  font-weight: ${FontWeight.SemiBold};

  appearance: none;
  border: none;
  border-radius: ${BORDER_RADIUS};
  box-shadow: 0 1px 4px 0 ${Colors.DarkGray(0.16)};
  background-color: ${Colors.White()};

  svg {
    margin-left: 8px;
  }
`;

const MobileDropdown = styled(Dropdown)`
  position: absolute;
  top: 16px;
  left: 72px;

  font-weight: ${FontWeight.SemiBold};
  text-transform: uppercase;
  font-size: 11px;
`;

/**
 * Dropdown UI for switching modes in planner
 */

export const ModeDropdown = () => (
  <Match path="/neighborhoods/:mode">
    {({ match, navigate }) =>
      match && (
        <MobileDropdown
          options={PlannerModeRoutes}
          value={match.uri}
          onChange={event => navigate(event.target.value)}
        />
      )
    }
  </Match>
);
