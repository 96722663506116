import { useState, useEffect, useMemo, useRef, useDebugValue } from 'react';
import isEqual from 'react-fast-compare';

interface Options<T, P> {
  initialValue?: T;
  params?: P;
  defer?: boolean;
  watch?: any[];
}

interface InternalState<T> {
  oldData?: T;
  data?: T;
  error?: Error;
  startedAt?: Date;
  finishedAt?: Date;
}

interface BaseState<T> {
  data?: T;
  error?: Error;
  /**
   * Cancel current request
   */
  cancel: () => void;
  /**
   * Is current promise loading
   */
  isLoading: boolean;
  /**
   * Rerun current promise
   */
  reload: (params?: any) => Promise<T>;
  setData: (data: T | ((oldData: T) => T)) => void;
  revertData: () => void;
  setError: (error?: Error) => void;
}

/**
 * Loaded state of useAsync hook
 */
export interface LoadedState<T> extends BaseState<T> {
  data: T;
  error: undefined;
  isLoading: false;
}

interface ErrorState<T> extends BaseState<T> {
  data: undefined;
  error: Error & { response?: Response };
  isLoading: false;
}

interface LoadingState<T> extends BaseState<T> {
  isLoading: true;
}

/**
 * All useAsync states
 */
export type AsyncState<T> = LoadedState<T> | ErrorState<T> | LoadingState<T>;

type SimplePromiseFn<T> = (controller: AbortController) => Promise<T>;
type ParamsPromiseFn<T, P> = (params: P, controller: AbortController) => Promise<T>;
type PromiseFn<T, P> = ParamsPromiseFn<T, P> | SimplePromiseFn<T>;

/**
 * Load promises declaratively, strongly typed
 * Makes it easy to handle loading and error states,
 * without assumptions about the shape of your data or the type of request
 *
 * @param promiseFn Function that returns a Promise, automatically invoked.
 */
export function useAsync<T, P extends object>(
  promiseFn: PromiseFn<T, P>,
  options: Options<T, P> = {},
): AsyncState<T> {
  const { initialValue, params, defer = false, watch = [] } = options;
  const isMounted = useRef(true);
  const counter = useRef(0);
  const abortController = useRef(new AbortController());

  const [state, setState] = useState<InternalState<T>>({
    data: initialValue instanceof Error ? undefined : initialValue,
    error: initialValue instanceof Error ? initialValue : undefined,
    startedAt: new Date(),
    finishedAt: initialValue ? new Date() : undefined,
  });

  const cancel = () => {
    counter.current = counter.current += 1;
    abortController.current.abort();
    setState(oldState => ({ ...oldState, startedAt: undefined }));
  };

  const handleData = (data: T | ((oldData: T) => T)) => {
    if (isMounted.current) {
      setState(oldState => ({
        ...oldState,
        oldData: oldState.data,
        data: typeof data === 'function' ? (data as any)(oldState.data) : data,
        error: undefined,
        finishedAt: new Date(),
      }));
    }
    return data;
  };

  const handleError = (error?: Error) => {
    if (isMounted.current) {
      if (error && error.name === 'AbortError') {
        setState(oldState => ({ ...oldState, startedAt: undefined }));
      } else {
        setState(oldState => ({ ...oldState, error, finishedAt: new Date() }));
      }
    }
    return error;
  };

  // Promise resolve handler, scoped with counter for non-abortable promises
  const handleResolve = (count: number) => (data: T) => {
    if (count === counter.current) handleData(data);
    return data;
  };

  // Promise reject handler, scoped with counter for non-abortable promises
  const handleReject = (count: number) => (error: Error) => {
    if (count === counter.current) handleError(error);
    if (error.name === 'HTTPError') error.message = `${error.message} [IGNORE]`;
    throw error;
  };

  // Execute and handle promise
  const load = async (localParams?: P) => {
    if (initialValue && counter.current === 0 && state.data) return Promise.resolve(state.data);
    if (!defer) {
      abortController.current.abort();
      abortController.current = new AbortController();
    }
    counter.current = counter.current += 1;

    setState(oldState => ({
      ...oldState,
      startedAt: new Date(),
      finishedAt: undefined,
    }));

    const args = [];

    if (localParams) {
      args.push(params ? { ...params, ...localParams } : localParams);
    } else if (params) {
      args.push(params);
    }

    args.push(abortController.current);

    return ((promiseFn as any)(...args) as Promise<T>).then(
      handleResolve(counter.current),
      handleReject(counter.current),
    );
  };

  // Sets mount state
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  // Abort fetch on unmount
  useEffect(() => () => abortController.current.abort(), []);

  // Start promise loading unless it is deferred, only when promiseFn changes or any watched props
  useEffect(() => {
    if (promiseFn && !defer) {
      // Catch abort errors
      load().catch((error: Error) => {
        if (error.name !== 'AbortError') throw error;
      });
    } else {
      cancel();
    }
  }, [promiseFn, useDeepCompareMemoize(params), ...watch]);

  useDebugValue(state, ({ startedAt, finishedAt, error }) => {
    if (startedAt && (!finishedAt || finishedAt < startedAt)) return 'Loading';
    if (finishedAt) return error ? 'Rejected' : 'Resolved';
    return 'Pending';
  });

  return useMemo<AsyncState<T>>(
    () => ({
      cancel,
      data: state.data,
      error: state.error,
      isLoading: (!!state.startedAt &&
        (!state.finishedAt || state.finishedAt < state.startedAt)) as any,
      async reload(localParams?: any) {
        // Clear memoized promise on reload
        const promiseFunc = promiseFn as any;
        if (promiseFunc.clear) promiseFunc.clear();
        return load(localParams);
      },
      setData(data: T | ((oldData: T) => T)) {
        handleData(data);
      },
      revertData() {
        if (state.oldData) handleData(state.oldData);
      },
      setError(error?: Error) {
        handleError(error);
      },
    }),
    [state],
  );
}

/**
 * Load promise actions declaratively
 *
 * @param promiseFn Function that returns a Promise, invoked with first argument
 */
export function useAsyncAction<T, P extends object>(
  promiseFn: PromiseFn<T, P>,
): [(params?: P) => Promise<T>, AsyncState<T>] {
  const state = useAsync(promiseFn, { defer: true });
  return [state.reload, state];
}

/**
 * Deep comparison
 */
function useDeepCompareMemoize(value: any) {
  const ref = useRef();
  if (!isEqual(value, ref.current)) ref.current = value;
  return ref.current;
}
