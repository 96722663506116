import { PisaScore, UAPopulationEstimate } from '../types';

/**
 * Format pisa score result label
 */
export const pisaResultLabel = (score: PisaScore) => {
  const perc = (score.ua_rank / score.total_in_rank) * 100;
  if (perc < 6) return 'Top 5%';
  if (perc < 11) return 'Top 10%';
  if (perc < 21) return 'Top 20%';
  if (perc < 31) return 'Top 30%';
  if (perc < 71) return 'Middle 40%';
  if (perc < 81) return 'Bottom 30%';
  if (perc < 91) return 'Bottom 20%';
  return 'Bottom 10%';
};

const closestNumber = (target: number, list: number[]) =>
  list.reduce((prev, curr) => (Math.abs(curr - target) < Math.abs(prev - target) ? curr : prev));

const findPopulationEstimate = (slug: string, populationEstimates: UAPopulationEstimate[]) => {
  const populationData = populationEstimates.find(p => p.slug === slug);
  return (populationData && populationData.population) || null;
};

/**
 * Calculate most recent population growth
 */
export function recentPopulationGrowth(slug: string, populationEstimates: UAPopulationEstimate[]) {
  const population = findPopulationEstimate(slug, populationEstimates);
  if (!population) return null;

  const datasetYears = Object.keys(population).map(parseFloat);
  const currentYear = new Date().getFullYear();
  const closestYear = closestNumber(currentYear, datasetYears);
  let closestIndex = datasetYears.findIndex(k => k === closestYear);
  if (closestIndex === datasetYears.length - 1) closestIndex = closestIndex - 1;
  const fromYear = datasetYears[closestIndex];
  const toYear = datasetYears[closestIndex + 1];

  const percentage = (population[toYear] - population[fromYear]) / population[fromYear];
  return { fromYear, toYear, percentage };
}

/**
 * Prepare data series for line graph
 */
export function growthLineData(slug: string, populationEstimates: UAPopulationEstimate[]) {
  const population = findPopulationEstimate(slug, populationEstimates);
  if (!population) return null;

  return Object.entries(population).map(([year, count]) => ({
    year,
    count,
  }));
}
