import { featureCollection, point } from '@turf/helpers';
import center from '@turf/center';
import bbox from '@turf/bbox';
import area from '@turf/area';
import bboxPolygon from '@turf/bbox-polygon';

import config from '../../config';
import { Neighborhood } from './types';
import { padBBox } from '../neighborhoods/utils/map';

/**
 * Generate TMS url function
 */
export const mapboxTMS = (mapboxId: string) => (x: number, y: number, z: number) =>
  [
    'https://api.mapbox.com/styles/v1/',
    mapboxId,
    `/tiles/256/${z}/${x}/${y}`,
    `${window.devicePixelRatio >= 2 ? '@2x' : ''}?access_token=${config.mapboxKey}`,
  ].join('');

/**
 * Convert neighborhood to GeoJSON feature
 */
const neighborhoodToFeature = ({ latitude, longitude, ...neighborhood }: Neighborhood) =>
  point([latitude, longitude], neighborhood);

/**
 * Calculate center point of neighborhoods
 */
export const getCenter = (neighborhoods: Neighborhood[]) => {
  const features = featureCollection(neighborhoods.map(neighborhoodToFeature));
  const centerPoint = center(features);
  return centerPoint.geometry.coordinates as [number, number];
};

/**
 * Generate a bbox from neighborhood points
 */
export const getBBox = (neighborhoods: Neighborhood[]) => {
  const features = featureCollection(neighborhoods.map(neighborhoodToFeature));
  const [a, b, c, d] = bbox(features);
  return padBBox([b, a, d, c], 0.5);
};

/**
 * Calculate initial zoom level
 */
export const getZoom = (neighborhoods: Neighborhood[]) => {
  const features = featureCollection(neighborhoods.map(neighborhoodToFeature));
  const sqkm = area(bboxPolygon(bbox(features))) / (1000 * 1000);
  return sqkm > 75 ? 11 : 12;
};

/**
 * toTitleCase
 * TODO: Get rid of this when neighborhoods titles are fixed in DB
 */
export function toTitleCase(text: string) {
  const smallWords = /^(a|an|and|as|at|but|by|en|for|if|in|nor|of|on|or|per|the|to|vs?\.?|via)$/i;

  return sanitizeDescription(text)
    .toLowerCase()
    .replace(/[A-Za-z0-9\u00C0-\u00FF]+[^\s-]*/g, (match, index, title) => {
      if (
        index > 0 &&
        index + match.length !== title.length &&
        match.search(smallWords) > -1 &&
        title.charAt(index - 2) !== ':' &&
        (title.charAt(index + match.length) !== '-' || title.charAt(index - 1) === '-') &&
        title.charAt(index - 1).search(/[^\s-]/) < 0
      ) {
        return match.toLowerCase();
      }

      if (match.substr(1).search(/[A-Z]|\../) > -1) {
        return match;
      }

      return match.charAt(0).toUpperCase() + match.substr(1);
    });
}

/**
 * Sanitize description - remove HTML linebreaks and replace html entities
 */
export const sanitizeDescription = (description: string) =>
  description
    .replace(/<\S+?>/g, '')
    .replace(/&amp;/g, '&')
    .replace(/&nbsp;/g, ' ');
