/**
 * Get a duration string for specified duration in minutes
 */
export function getDurationStringFor(time: number) {
  const remainingMinutes = time % 3600;
  const hours = (time - remainingMinutes) / 3600;
  const minutes = Math.round(remainingMinutes / 60);
  return `${hours < 1 ? '' : `${hours}h `}${minutes}min`;
}

/**
 * Format currency value
 */
export const currencyFormatter = (currency: string) =>
  new Intl.NumberFormat('en-US', {
    currency,
    style: 'currency',
    minimumFractionDigits: 0,
  }).format;
