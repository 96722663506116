import React, { useState, useContext } from 'react';
import { Spinner, ErrorOverlay } from '@topia.com/ui-kit';
import { useAsync } from '@topia.com/ui-kit/hooks';

import { Office } from '../types';
import { getOffices } from '../api';
import { OfficePicker } from './OfficePicker';

interface Props {
  bbox: GeoJSON.BBox;
  editableOffice: boolean;
  children: (offices: Office[]) => React.ReactNode;
}

interface OfficeContextValue {
  clearOffices: () => void;
  editableOffice: boolean;
}

const OfficeContext = React.createContext<OfficeContextValue | null>(null);

/**
 * Office list related helpers
 */
export function useOfficeContext() {
  const context = useContext(OfficeContext);
  if (!context) throw new Error('OfficeContext missing');
  return context;
}

/**
 * Office location provider, either from API result or manual input
 */
export const OfficeProvider = ({ bbox, children, editableOffice }: Props) => {
  const offices = useAsync(getOffices);
  const [givenOffice, setGivenOffice] = useState<Office | undefined>();

  /**
   * Clear office list - shows office picker
   */
  const clearOffices = () => {
    setGivenOffice(undefined);
    offices.setData([]);
  };

  return (
    <OfficeContext.Provider value={{ clearOffices, editableOffice }}>
      {offices.isLoading && <Spinner overlayOpacity={0} label="Loading offices" />}
      {offices.error && (
        <ErrorOverlay error={offices.error} buttonText="Reload" onClose={offices.reload}>
          Failed to load offices.
        </ErrorOverlay>
      )}
      {offices.data && offices.data.length === 0 && !givenOffice && (
        <OfficePicker bbox={bbox} onPick={setGivenOffice} />
      )}
      {children(givenOffice ? [givenOffice] : offices.data || [])}
    </OfficeContext.Provider>
  );
};
