/**
 * Text input line height
 */
export const LineHeight = 28;

/**
 * Dropdown horizontal padding
 */
export const HorizontalMenuPadding = 16;

/**
 * Option vertical padding in the dropdown
 */
export const OptionVerticalPadding = 4;

/**
 * Select All option value
 */
export const SelectAllValue = '<SELECT_ALL>';
