import React from 'react';
import styled from 'styled-components';
import { Colors, FontWeight, TextSize } from '@topia.com/ui-kit';
import { BORDER_RADIUS } from '@topia.com/ui-kit/variables';
import { Link } from '@topia.com/context-path';

import { PlannerModeRoutes } from '../../data/labels';

/**
 * Wrapper for neighborhood mode switcher
 */
export const ModeSwitcherWrapper = styled.nav`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 11;

  border-radius: ${BORDER_RADIUS};
  box-shadow: 0 1px 4px 0 ${Colors.DarkGray(0.16)};
  background-color: ${Colors.White()};
`;

const ModeLink = styled(Link)`
  display: inline-block;
  padding: 10px 16px;
  opacity: 0.4;

  ${TextSize.Small};
  font-weight: ${FontWeight.SemiBold};
  text-transform: uppercase;
  color: ${Colors.DarkGray()};

  &[aria-current='page'],
  &:hover {
    opacity: 1;
    text-decoration: none;
  }
`;

/**
 * Tabbed UI for switching modes in planner
 */
export const ModeSwitcher = () => (
  <ModeSwitcherWrapper data-testid="mode-switcher">
    {PlannerModeRoutes.map(({ label, value }) => (
      <ModeLink data-testid="mode-link" key={label} replace to={value}>
        {label}
      </ModeLink>
    ))}
  </ModeSwitcherWrapper>
);
