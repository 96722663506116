import React from 'react';
import { Grid, FontWeight, Colors } from '@topia.com/ui-kit';
import styled from 'styled-components';

import { UniversitiesData } from '../../types';
import { Subtitle, Label } from '../WidgetComponents';
import { formatPercentage, formatNumber, ordinal } from '../../utils/formatting';

const DEFAULT_SUBJECTS = ['Life sciences', 'Physical sciences', 'Social sciences'];

/**
 * Clean subject label
 */
function cleanSubjectLabel(label: string) {
  return label
    .replace('Clinical, pre-clinical & health', 'Medical')
    .replace('Engineering & technology', 'Tech & Engineering');
}

/**
 * Universities section in education widget
 */
export const UniversitiesSection = ({ universities }: UniversitiesData) => {
  const topUniversity = universities.sort((a, b) => a.rank - b.rank)[0];
  const topThreeSubjects = topUniversity.subject_rankings
    ? Object.entries(topUniversity.subject_rankings)
        .sort(([, a], [, b]) => a - b)
        .slice(0, 3)
        .map(([subject]) => subject)
    : DEFAULT_SUBJECTS;

  const topFiveUniversities = universities.slice(0, 5);

  return (
    <>
      <Subtitle>University Rankings</Subtitle>
      <Grid>
        <Grid.Item size={8} mobileSize={12}>
          <UniversityTable>
            <thead>
              <tr>
                <th />
                <th>
                  <Label>Overall</Label>
                </th>
                {topThreeSubjects.map(subject => (
                  <th key={subject}>
                    <Label>{cleanSubjectLabel(subject)}</Label>
                  </th>
                ))}
                <th>
                  <Label>Total Students</Label>
                </th>
                <th>
                  <Label>International students</Label>
                </th>
              </tr>
            </thead>
            <tbody>
              {topFiveUniversities.map((university, idx) => (
                <tr key={idx}>
                  <td dangerouslySetInnerHTML={{ __html: university.name }} />
                  <td>{ordinal(university.rank)}</td>
                  {topThreeSubjects.map(subject => (
                    <td key={subject}>
                      {university.subject_rankings &&
                        university.subject_rankings[subject] &&
                        ordinal(university.subject_rankings[subject])}
                    </td>
                  ))}
                  <td>{formatNumber(university.students.total)}</td>
                  <td>{formatPercentage(university.students.international_percent / 100)}</td>
                </tr>
              ))}
            </tbody>
          </UniversityTable>
        </Grid.Item>
      </Grid>
    </>
  );
};

const UniversityTable = styled.table`
  a {
    color: ${Colors.DarkGray()};
  }

  tbody tr {
    border-bottom: solid 1px ${Colors.DarkGray(0.04)};
  }

  thead tr,
  tbody tr:last-child {
    border-bottom: solid 2px ${Colors.DarkGray(0.08)};
  }

  th,
  td {
    width: 8%;
    text-align: right;
  }

  td {
    padding: 7px 0;
  }

  th {
    vertical-align: bottom;

    ${Label} {
      display: inline-block;
    }
  }

  td:first-child {
    font-weight: ${FontWeight.SemiBold};
  }

  td:first-child,
  th:first-child {
    width: 16%;
    text-align: left;
  }

  td:last-child,
  th:last-child {
    width: 10%;
  }
`;
