import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Colors, media, MediaQuery } from '@topia.com/ui-kit';
import { ASSETS_URL_ROOT } from '@topia.com/ui-kit/variables';
import { Link } from '@topia.com/context-path';

import { ExternalNavLink, NavLink } from './Links';
import { MovePicker } from './MovePicker';
import config from '../../../../config';
import { CreateMenu } from './CreateMenu';
import { UserMenu } from './UserMenu';
import { MobileNavBar } from './Mobile/MobileNavBar';
import { MobileNavOverlay } from './Mobile/MobileNavOverlay';

/**
 * Navigation bar height
 */
export const NAV_HEIGHT = 96;

/**
 * Top level navigation
 */
export const Header = ({ children  }: { children: React.ReactNode }) => (
  <>
    <Shadow />
    <Wrapper data-testid="go-header">
      <MediaQuery.Mobile>
        {matches =>
          matches ? (
            <MobileHeader>{children}</MobileHeader>
          ) : (
            <>
              <LeftActionSection>
                <LogoLink data-testid="topia-logo" to="/">
                  <img alt="Topia" src={`${ASSETS_URL_ROOT}/logo-small.svg`} />
                </LogoLink>
                {config.fusionEnv && <MovePicker />}
              </LeftActionSection>
              <MainLinks data-testid="nav-links">{children}</MainLinks>
              <RightActionSection>
                {config.fusionEnv && <CreateMenu />}
                <StyledUserMenu />
              </RightActionSection>
            </>
          )
        }
      </MediaQuery.Mobile>
    </Wrapper>
  </>
);

const MobileHeader = ({ children }: { children: React.ReactNode }) => {
  const [open, setOpen] = useState(false);

  const setOverflowHidden = (hidden: boolean) => {
    document.body.style.overflow = hidden ? 'hidden' : '';
  };

  useEffect(() => {
    setOverflowHidden(open);
  }, [open]);

  useEffect(
    () => () => {
      setOverflowHidden(false);
    },
    [],
  );

  return (
    <>
      <MobileNavBar open={open} setOpen={setOpen} />
      <MobileNavOverlay open={open} setOpen={setOpen}>
        <NavLink to="/">Dashboard</NavLink>
        {children}
      </MobileNavOverlay>
    </>
  );
};

const LogoLink = styled(Link)`
  margin-bottom: auto;
  margin-top: auto;
  padding-right: 44px;
`;

const MainLinks = styled.div`
  flex: 2;
  display: flex;
  justify-content: center;
  z-index: -1;
  padding: 0 32px;

  & ${/* sc-selector */ NavLink}, ${ExternalNavLink} {
    white-space: nowrap;
    opacity: 0.4;

    &:hover,
    &.active {
      opacity: 1;
    }
  }
`;

const LeftActionSection = styled.div`
  display: flex;
  flex: 1;
`;

const RightActionSection = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

const StyledUserMenu = styled(UserMenu)`
  margin-left: 32px;
`;

const Shadow = styled.div`
  .full-width &::before {
    pointer-events: none;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 200px;
    background-image: linear-gradient(to bottom, ${Colors.White(0.8)} 40px, ${Colors.White(0)});
    transform: translate3d(0, 0, 0);

    ${media.default`
      content: ' ';
    `};
  }
`;

const Wrapper = styled.nav`
  position: relative;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  padding: 32px 32px 16px;
  transform: translate3d(0, 0, 0);

  ${media.mobile`
    position: fixed;
    z-index: 10000;
    width: 100%;
    background-color: ${Colors.White()};
    align-items: center;
    padding: 8px 20px;
  `};
`;
