import React from 'react';
import { IndicatorProps } from 'react-select';
import styled, { css } from 'styled-components';

import { MultiSelectProps } from '../MultiSelect';
import SearchIcon from '../../../icons/SearchIcon';
import ArrowIcon from '../../../icons/small/ArrowIcon';
import { Colors } from '../../../Colors';

/**
 * Multi-select dropdown indicator component
 */
export const DropdownIndicator = (props: IndicatorProps<any>) => {
  const searchField = (props.selectProps.multiSelectProps as MultiSelectProps<any>).searchField;
  const { onMouseDown, onTouchEnd, ...otherInnerProps } = props.innerProps;

  return (
    <Root
      {...(searchField ? {} : { onMouseDown, onTouchEnd })}
      {...otherInnerProps}
      tabIndex={-1}
      searchField={searchField}
      disabled={props.selectProps.isDisabled}>
      {searchField ? <SearchIcon /> : <ArrowIcon />}
    </Root>
  );
};

const Root = styled.button.attrs({ type: 'button' })<{ searchField: boolean }>`
  margin-right: ${props => (props.searchField ? 8 : 10)}px;
  display: flex;
  align-items: center;
  border: none;
  padding: 0;
  background-color: transparent;
  ${props =>
    !props.searchField &&
    !props.disabled &&
    css`
      cursor: pointer;
    `}

  ${props =>
    props.disabled &&
    css`
      svg {
        color: ${Colors.Disabled()};
      }
    `}
`;
