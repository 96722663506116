import { FilterColumnType, Operator, OperatorType, OperatorTypeMapping } from './types';

/**
 * Operators supported by given type
 */
export const availableOperators = {
  [FilterColumnType.Boolean]: [Operator.Equals],
  [FilterColumnType.String]: [
    Operator.Contains,
    Operator.NotContains,
    Operator.Equals,
    Operator.NotEquals,
    Operator.IsEmpty,
    Operator.IsNotEmpty,
  ],
  [FilterColumnType.Number]: [
    Operator.Equals,
    Operator.NotEquals,
    Operator.LesserThan,
    Operator.LesserThanOrEquals,
    Operator.GreaterThan,
    Operator.GreaterThanOrEquals,
    Operator.IsBetween,
    Operator.IsNotBetween,
    Operator.IsEmpty,
    Operator.IsNotEmpty,
  ],
  [FilterColumnType.Date]: [
    Operator.Equals,
    Operator.IsEmpty,
    Operator.IsNotEmpty,
    Operator.IsIn,
    Operator.IsInRange,
    Operator.IsInYear,
    Operator.IsInPast,
    Operator.IsInNext,
    Operator.IsBefore,
    Operator.IsOnOrAfter,
  ],
  [FilterColumnType.Array]: [Operator.Contains, Operator.NotContains],
};

/**
 * Default mapping for all Operators to their types
 */
export const masterOperatorTypeMappings: OperatorTypeMapping = new Map([
  [Operator.Equals, [OperatorType.Absolute, OperatorType.Relative]],
  [Operator.NotEquals, [OperatorType.Absolute, OperatorType.Relative]],
  [Operator.IsEmpty, [OperatorType.None]],
  [Operator.IsNotEmpty, [OperatorType.None]],
  [Operator.Contains, [OperatorType.Absolute]],
  [Operator.NotContains, [OperatorType.Absolute]],
  [Operator.LesserThan, [OperatorType.Absolute]],
  [Operator.LesserThanOrEquals, [OperatorType.Absolute]],
  [Operator.GreaterThan, [OperatorType.Absolute]],
  [Operator.GreaterThanOrEquals, [OperatorType.Absolute]],
  [Operator.IsBetween, [OperatorType.Range]],
  [Operator.IsNotBetween, [OperatorType.Range]],
  [Operator.IsIn, [OperatorType.Relative]],
  [Operator.IsInRange, [OperatorType.Range]],
  [Operator.IsInYear, [OperatorType.Absolute]],
  [Operator.IsInPast, [OperatorType.Relative]],
  [Operator.IsInNext, [OperatorType.Relative]],
  [Operator.IsBefore, [OperatorType.Absolute, OperatorType.Relative]],
  [Operator.IsOnOrAfter, [OperatorType.Absolute, OperatorType.Relative]],
]);

/**
 * Readable labels for operators
 */
export const operatorLabels = {
  [Operator.Equals]: 'Is equal to',
  [Operator.NotEquals]: 'Is not equal to',
  [Operator.IsEmpty]: 'Is empty',
  [Operator.IsNotEmpty]: 'Is not empty',
  [Operator.Contains]: 'Contains',
  [Operator.NotContains]: 'Does not contain',
  [Operator.LesserThan]: 'Lesser than',
  [Operator.LesserThanOrEquals]: 'Lesser than or equal to',
  [Operator.GreaterThan]: 'Greater than',
  [Operator.GreaterThanOrEquals]: 'Greater than or equal to',
  [Operator.IsBetween]: 'Is between',
  [Operator.IsNotBetween]: 'Is not between',
  [Operator.IsIn]: 'Is in',
  [Operator.IsInRange]: 'Is in range',
  [Operator.IsInYear]: 'Is in year',
  [Operator.IsInPast]: 'Is in past',
  [Operator.IsInNext]: 'Is in next',
  [Operator.IsBefore]: 'Is before',
  [Operator.IsOnOrAfter]: 'Is on or after',
};

/**
 * Options shown when field has predefined values
 */
export const predefinedValueOperatorOptions = [
  { label: operatorLabels[Operator.Equals], value: Operator.Equals, operatorTypes: [] },
  { label: operatorLabels[Operator.NotEquals], value: Operator.NotEquals, operatorTypes: [] },
];

/**
 * Options shown for boolean field
 */
export const booleanFieldOptions = [
  { label: 'Yes', value: 'true', operatorTypes: [OperatorType.None] },
  { label: 'No', value: 'false', operatorTypes: [OperatorType.None] },
];
