import React from 'react';
const SvgOfficeIcon = React.forwardRef(
  (
    { color = '#003049', ...props }: React.SVGProps<SVGSVGElement>,
    ref: React.Ref<SVGSVGElement>,
  ) => (
    <svg height={24} viewBox="0 0 24 24" width={24} ref={ref} color={color} {...props}>
      <path
        d="M16 3v4.905h5v13H3V3zm0 6.905v9h3v-9zM14 5H5v13.906h9zM9 15v2H7v-2zm3 0v2h-2v-2zm-3-4v2H7v-2zm3 0v2h-2v-2zM9 7v2H7V7zm3 0v2h-2V7z"
        fill="currentColor"
      />
    </svg>
  ),
);
export default SvgOfficeIcon;
