import React from 'react';
import styled from 'styled-components';

import { BORDER_RADIUS_LARGE } from '../variables';
import { Colors } from '../Colors';
import { FontWeight } from '../FontWeight';
import { Easings } from '../Easings';

interface Props {
  /**
   * Number to be shown in the number card
   */
  tileNumber: number | string;
  /**
   * text to be shown in the number card
   */
  tileText: string;
  className?: string;
  color?: string;
  align?: 'left' | 'center';
}

/**
 * Number tile
 */
export const NumberTile: React.FC<Props> = ({ className, tileNumber, tileText, color, align }) => (
  <Wrapper className={className} color={color} align={align}>
    <TileNumber>{tileNumber}</TileNumber>
    <TileText>{tileText}</TileText>
  </Wrapper>
);

interface WrapperProps {
  color: string | undefined;
  align?: string | number;
}

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: ${(props: WrapperProps) => props.align === 'center' ? 'center' : 'flex-start'};
  padding: 16px 20px;
  color: ${Colors.NavyBlue()};
  background: ${(props: WrapperProps) =>
    `linear-gradient(225deg, rgba(255,255,255,.64) 0%, rgba(255,255,255,0) 100%),
    ${props.color || Colors.LightBlue()}`};
  border-radius: ${BORDER_RADIUS_LARGE};
  cursor: pointer;
  transition: all 560ms ${Easings.ExpoOut};

  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 16px 40px 0 ${Colors.DarkGray(0.08)};
  }
`;

const TileNumber = styled.div`
  font-size: 56px;
  font-weight: ${FontWeight.Bold};
  line-height: 1;
  letter-spacing: -2px;
  margin-bottom: 8px;
`;

const TileText = styled.div`
  line-height: 1.33;
  font-size: 12px;
  font-weight: ${FontWeight.SemiBold};
`;
