import { ConfigFunctionHelpers } from '@topia.com/env-config';

/**
 * Frontend configuration to expose through server
 */
export function clientConfiguration({
  getStringFromEnv,
  getBooleanFromEnv,
}: ConfigFunctionHelpers) {
  return {
    uiDebugger: getBooleanFromEnv('UI_DEBUGGER', false),
    gitCommit: getStringFromEnv('GIT_COMMIT', ''),
    // Mapbox key for their REST and tiling services
    mapboxKey: getStringFromEnv('MAPBOX_KEY'),
    contextPath: getStringFromEnv('CONTEXT_PATH', ''),
    fusionEnv: getBooleanFromEnv('FUSION_ENV', false),
    supportEmail: getBooleanFromEnv('FUSION_ENV', false) ? 'support@topia.com' : 'help@topia.com',
    loginPage: getStringFromEnv('LOGIN_PAGE_URL'),
  };
}
