import styled, { css } from 'styled-components';
import { Colors, Easings } from '@topia.com/ui-kit';

/**
 * BurgerMenu - mobile navigation button
 */
export const BurgerMenu = styled.button.attrs({
  'aria-haspopup': true,
  'aria-controls': 'menu',
  'aria-label': 'Navigation',
})`
  appearance: none;
  position: relative;
  width: 32px;
  height: 32px;
  padding: 0;
  border: 0;
  background-color: transparent;

  &::before,
  &::after {
    position: absolute;
    top: 50%;
    left: 0;
    width: 24px;
    height: 2px;
    margin: 0 4px;
    background-color: ${Colors.NavyBlue()};
    content: '';
    transform-origin: 50%;
    transform: translateY(-50%);
    transition: transform 560ms ${Easings.ExpoOut};
  }

  &::before {
    transform: translateY(-5px);
  }

  &::after {
    transform: translateY(3px);
  }

  ${(props: { isOpen: boolean }) =>
    props.isOpen &&
    css`
      &::before {
        transform: translateY(-50%) rotateZ(45deg);
      }
      &::after {
        transform: translateY(-50%) rotateZ(-45deg);
      }
    `}
`;
