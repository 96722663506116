import React from 'react';
import styled, { css } from 'styled-components';

import { Colors } from '../Colors';
import { FontWeight } from '../FontWeight';
import { TextSize } from '../TextSize';
import { Easings } from '../Easings';

interface Props {
  label?: string;
  secondary?: boolean;
  selectedValue?: string | number;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  children: React.ReactElement[];
}

interface SegmentedButtonProps extends React.HTMLProps<HTMLInputElement> {
  secondary?: boolean;
}

/**
 * Segmented control
 */
export const SegmentedControl = ({
  label,
  secondary,
  children,
  selectedValue,
  onChange,
}: Props) => (
  <Root>
    {label && <Label>{label}</Label>}
    <Buttons secondary={secondary}>
      {React.Children.map(children, child =>
        React.cloneElement(child, {
          ...child.props,
          secondary,
          onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
            if (onChange) {
              onChange(event);
            }
          },
          checked: child.props.value === selectedValue,
        }),
      )}
    </Buttons>
  </Root>
);

/**
 * Segmented control button
 */
export const SegmentedButton = ({ secondary, label, value, ...props }: SegmentedButtonProps) => (
  <ButtonWrapper checked={props.checked} secondary={secondary}>
    <input type="radio" value={value} {...props} />
    <ButtonLabel disable={props.disabled}>{label}</ButtonLabel>
  </ButtonWrapper>
);

const Root = styled.div`
  display: flex;
`;

const Label = styled.div<{ disable?: boolean }>`
  margin: 4px 12px 4px 0;
  ${TextSize.Default};
  font-weight: ${FontWeight.SemiBold};
  color: ${props => (props.disable ? Colors.White() : Colors.DarkGray())};
`;

const Buttons = styled.div<{ secondary?: boolean }>`
  display: flex;
  background-color: ${props => (props.secondary ? Colors.White() : Colors.DarkGray(0.08))};
  border-radius: 16px;
  padding: 4px;
  height: 32px;
`;

const ButtonLabel = styled(Label)`
  margin: 0 20px;
`;

const ButtonWrapper = styled.label<{ secondary?: boolean; checked?: boolean }>`
  background-color: ${props => {
    if (props.secondary && props.checked) {
      return Colors.DarkGray(0.08);
    }
    if (props.checked) {
      return Colors.White();
    }
    return 'none';
  }};
  ${props =>
    props.checked &&
    css`
      box-shadow: 0 24px 40px 0 ${Colors.DarkGray(0.02)}, 0 8px 40px -8px ${Colors.DarkGray(0.04)},
        0 4px 16px -2px ${Colors.DarkGray(0.08)}, 0 0.5px 1px 0.5px ${Colors.DarkGray(0.12)},
        0 0 1px 0.5px ${Colors.DarkGray(0.08)};
    `}

  border-radius: 16px;
  height: 24px;
  cursor: pointer;
  transition: background-color 560ms ${Easings.ExpoOut}, box-shadow 560ms ${Easings.ExpoOut};

  & > input {
    visibility: hidden;
    position: absolute;
  }
`;
