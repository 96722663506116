import React from 'react';
import { Grid } from '@topia.com/ui-kit';

import { PeopleWidgetData, UrbanArea } from '../../types';
import { Label, LargeFigure, Subtitle } from '../WidgetComponents';
import { Row } from '.';
import { formatNumber, formatPercentage, formatUSD } from '../../utils/formatting';

interface Props extends PeopleWidgetData {
  urban_area: UrbanArea;
}

/**
 * Demographics section in people widget
 */
export const DemographicsSection = ({
  median_age,
  elderly,
  life_expectancy,
  gdp_per_capita,
  unemployment,
  urban_area,
}: Props) => (
  <>
    <Subtitle>Demographics of {urban_area.country}</Subtitle>
    <Row>
      {median_age && (
        <Grid.Item size={3} mobileSize={4}>
          <Label>Median age</Label>
          <LargeFigure>{formatNumber(median_age.ua.value)}</LargeFigure>
        </Grid.Item>
      )}
      {elderly && (
        <Grid.Item size={3} mobileSize={4}>
          <Label>Elderly people</Label>
          <LargeFigure>{formatPercentage(elderly.ua.value)}</LargeFigure>
        </Grid.Item>
      )}
      {life_expectancy && (
        <Grid.Item size={3} mobileSize={4}>
          <Label>Life expectancy</Label>
          <LargeFigure>{formatNumber(life_expectancy.ua.value)}</LargeFigure>
        </Grid.Item>
      )}
    </Row>
    <Row>
      {gdp_per_capita && (
        <Grid.Item size={5} mobileSize={6}>
          <Label>GDP per capita</Label>
          <LargeFigure>{formatUSD(gdp_per_capita.ua.value)}</LargeFigure>
        </Grid.Item>
      )}
      {unemployment && (
        <Grid.Item size={4} mobileSize={6}>
          <Label>Unemployment</Label>
          <LargeFigure>{formatPercentage(unemployment.ua.value)}</LargeFigure>
        </Grid.Item>
      )}
    </Row>
  </>
);
