import React, { useState } from 'react';
import { Grid, Colors, media, TextSize } from '@topia.com/ui-kit';
import styled from 'styled-components';

import { VideoWidgetData } from '../types';

/**
 * Video widget
 */
export const VideoWidget = (video: VideoWidgetData) => {
  const [isVisible, setVisibility] = useState(false);

  return (
    <Grid>
      <Grid.Item size={8} mobileSize={12}>
        <Wrapper
          backgroundImage={video.image_url.replace('hqdefault', 'maxresdefault')}
          onClick={() => setVisibility(true)}>
          {isVisible ? (
            <iframe src={video.src} allowFullScreen />
          ) : (
            <PlayButton>
              <svg
                height="100%"
                width="100%"
                viewBox="0 0 120 120"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M94.4 60.5l-55-43.7a1 1 0 0 1 1.2-1.6l56 44.5a1 1 0 0 1 0 1.6l-56 43.5a1 1 0 0 1-1.6-.8V46.6a1 1 0 0 1 2 0V102z" />
              </svg>
            </PlayButton>
          )}
        </Wrapper>
        <Title>{video.title}</Title>
        <Author>By {video.author}</Author>
      </Grid.Item>
    </Grid>
  );
};

const Wrapper = styled.div`
  cursor: pointer;
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  background: center center no-repeat;
  background-size: cover;
  background-image: url(${(props: { backgroundImage: string }) => props.backgroundImage});
  display: flex;
  justify-content: center;
  align-items: center;

  ${media.mobile`
    margin-left: -24px;
    margin-right: -24px
  `};

  & > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
`;

const PlayButton = styled.div`
  width: 136px;
  height: 136px;
  padding: 8px;
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${Colors.SeaShell()};
  display: flex;
  justify-content: center;
  align-items: center;

  ${media.mobile`
    width: 68px;
    height: 68px;
  `}
`;

const Title = styled.div`
  ${TextSize.Medium};
  margin-top: 16px;
`;

const Author = styled(Title)`
  color: ${Colors.DarkGray(0.4)};
  margin-top: 0;
`;
