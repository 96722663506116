import React from 'react';
import styled, { css } from 'styled-components';

import { Button, Colors, Easings, FontWeight, TextSize } from '@topia.com/ui-kit';
import { useContextPathSupport } from '@topia.com/context-path';

import { MovePicker } from '../MovePicker';
import config from '../../../../../config';
import { ExternalNavLink, NavLink } from '../Links';

interface Props {
  children: React.ReactNode;
  open: boolean;
  setOpen: (open: boolean) => void;
}

/**
 * MobileNavOverlay
 */
export const MobileNavOverlay = ({ children, open, setOpen }: Props) => {
  const { navigate } = useContextPathSupport();

  return (
    <Overlay
      open={open}
      onClick={event => {
        const target = event.target as Element | null;
        if (target && target instanceof HTMLAnchorElement) setOpen(false);
      }}>
      <LinkPanel open={open}>{children}</LinkPanel>
      <BottomActions>
        {config.fusionEnv && <MovePicker />}
        <SignOutButton
          secondary
          color={Colors.Blonde()}
          large
          onClick={() => navigate('/auth/logout')}>
          Sign Out
        </SignOutButton>
      </BottomActions>
    </Overlay>
  );
};

const staggeredLinkIntros = [...Array(10)].map(
  (_v, idx) => css`
    &:nth-child(${idx + 1}) {
      transition-delay: ${idx * 40 + 120}ms;
    }
  `,
);

const Overlay = styled.div<{ open: boolean }>`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  position: fixed;
  z-index: -1;
  left: 0;
  top: -100vh;
  padding: 48px 0 44px;
  width: 100%;
  height: 100vh;
  background-color: ${Colors.White()};
  transition: top 560ms ${Easings.ExpoOut};

  ${({ open }) =>
    open &&
    css`
      top: 0;
    `};
`;

const LinkPanel = styled.div<{ open: boolean }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;

  ${NavLink}, ${ExternalNavLink /* sc-custom */} {
    justify-content: center;
    opacity: 0;
    padding: 16px 56px;

    font-weight: ${FontWeight.SemiBold};
    ${TextSize.Large};
    text-transform: none;

    transition: all 240ms ease-out;
    transform: translateY(-10px);
    ${staggeredLinkIntros}
  }

  ${({ open }) =>
    open &&
    css`
      && ${/* sc-selector */ NavLink}, && ${ExternalNavLink} {
        opacity: 0.4;
        transform: none;

        &.active {
          opacity: 1;
        }
      }
    `};
`;

const BottomActions = styled.div`
  margin: 40px 20px 20px;
`;

const SignOutButton = styled(Button)`
  margin-top: 16px;
  width: 100%;
`;
