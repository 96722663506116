import memoize from 'lodash/memoize';
import { quantile } from 'd3-array';

import {
  PlannerSettings,
  RawScoreFeatureCollection,
  ScoreFeatureCollection,
  LatLngLiteral,
  Tradeoff,
} from '../types';
import { stepExpressionColorStops } from './map';
import { currencyFormatter, getDurationStringFor } from './formatting';

/**
 * Q value for quantiles
 */
const Q = 2.5 / 100;

/**
 * Calculate weekly commute time in hours
 */
const calculateWeeklyCommute = (time: number) => (time * 5 * 2) / 60 / 60;

/**
 * Scoring depends only of these fields
 */
export type ScoringSettings = Pick<PlannerSettings, 'commuteMode' | 'bedroomSize' | 'tradeoff'>;

interface ScoreList {
  [key: string]: number;
}

/**
 * Calculate neighborhood scores based on the data and scoring options provided
 */
function calculateNeighborhoodScores(
  geoJSON: RawScoreFeatureCollection,
  { commuteMode, bedroomSize, tradeoff }: ScoringSettings,
): ScoreList {
  // Calculate raw match scores
  const rawScores = geoJSON.features.reduce<{ [key: string]: number }>(
    (scores, { properties: { id, rent, commute } }) => {
      // Filter out neighborhoods that have both layers for score calculation
      if (!rent || !commute) return scores;
      if (!commute[commuteMode] || !rent[bedroomSize]) return scores;

      const weeklyCommute = calculateWeeklyCommute(commute[commuteMode]);
      scores[id] = weeklyCommute * tradeoff + rent[bedroomSize];
      return scores;
    },
    {},
  );

  const scoreValues = Object.keys(rawScores)
    .map(key => rawScores[key])
    .sort((a, b) => a - b);
  const scoreMin = quantile(scoreValues, Q);
  const scoreMax = quantile(scoreValues, 1 - Q);
  if (!scoreMin || !scoreMax) return {};

  // Rescale scores to be in the 0..10 range
  return Object.keys(rawScores).reduce<ScoreList>((scores, key) => {
    const val = Math.max(Math.min(rawScores[key], scoreMax), scoreMin);

    return {
      ...scores,
      [key]: (1 - (val - scoreMin) / (scoreMax - scoreMin)) * 10,
    };
  }, {});
}

/**
 * Calculate score property for FeatureCollection
 */
export const generateScoreProperties = memoize(
  (geoJSON: RawScoreFeatureCollection, settings: ScoringSettings, _commuteStart: LatLngLiteral) => {
    const scores = calculateNeighborhoodScores(geoJSON, settings);

    const features = geoJSON.features
      .map(feature => {
        const { id, rent, commute } = feature.properties;
        const score = scores[id];

        const rentValue = rent ? rent[settings.bedroomSize] : 0;
        const commuteValue = commute ? commute[settings.commuteMode] : 0;

        const properties = { ...feature.properties, score, rentValue, commuteValue };
        return { ...feature, properties };
      })
      .filter(feature => feature.properties.score);

    const featureCollection: ScoreFeatureCollection = { features, type: 'FeatureCollection' };
    return featureCollection;
  },
  (
    geoJSON: RawScoreFeatureCollection,
    scoringSettings: ScoringSettings,
    commuteStart: LatLngLiteral,
  ) => {
    const idList = geoJSON.features.reduce((acc, { properties: { id } }) => acc + id, '');
    return `${idList}-${JSON.stringify(scoringSettings)}-${commuteStart.lat}-${commuteStart.lng}`;
  },
);

const scoreColorMap = stepExpressionColorStops(10, 0).reduce<{ [key: number]: string }>(
  (obj, v, index) => {
    if (typeof v === 'string') obj[index / 2] = v;
    return obj;
  },
  {},
);

/**
 * Get top 10 neighborhoods from scored neighborhoods GeoJSON
 */
export const topNeighborhoods = (
  geoJSON: ScoreFeatureCollection,
  settings: PlannerSettings,
  currencyCode: string,
) => {
  const formatMoney = currencyFormatter(currencyCode);

  return geoJSON.features
    .map(({ properties: { id, name, score, rentValue, commuteValue } }) => ({
      id,
      name,
      score,
      rentValue,
      commuteValue,
      rent: formatMoney(rentValue),
      commute: getDurationStringFor(commuteValue),
      color: scoreColorMap[Math.round(score)],
    }))
    .filter(
      ({ rentValue, commuteValue }) =>
        rentValue <= settings.maxRent && commuteValue <= settings.maxCommute,
    )
    .sort((a, b) => {
      const score = b.score - a.score;
      if (score !== 0) return score;

      switch (settings.tradeoff) {
        case Tradeoff.LowerRent:
          return a.rentValue - b.rentValue;
        case Tradeoff.FasterCommute:
          return a.commuteValue - b.commuteValue;
        case Tradeoff.Balanced:
          return a.rentValue - b.rentValue || a.commuteValue - b.commuteValue;
      }
    })
    .slice(0, 10);
};
