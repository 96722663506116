import { Match } from '@reach/router';
import React from 'react';
import styled from 'styled-components';

import { Colors, ErrorOverlay, media, MediaQuery } from '@topia.com/ui-kit';
import PageTitle from '@topia.com/ui-kit/PageTitle';
import { Redirect, useContextPathSupport } from '@topia.com/context-path';

import { GuidePages } from '../types';
import { TopNav } from './TopNav';
import { SideNav } from './SideNav';
import { Section } from './Section';
import { useSectionViewTracker } from '../utils/hooks';

/**
 * Guide body
 */
export const Guide = ({ header, pages }: GuidePages) => {
  useSectionViewTracker();
  const { navigate, withContextPath } = useContextPathSupport();

  if (pages.length === 0) {
    return (
      <ErrorOverlay onClose={() => navigate('/', { replace: true })} buttonText="Go home">
        Unfortunately, the guide you are looking for is not yet available.
      </ErrorOverlay>
    );
  }

  return (
    <>
      <Header background={withContextPath(header.imageUrl)}>{header.title}</Header>
      <TopNav pages={pages} />
      <Match<{ page: string }> path={withContextPath('/guide/:page')}>
        {({ match }) => {
          // Redirect guide root to first page
          if (match === null) return <Redirect noThrow to={`/guide/${pages[0].id}`} />;
          const activePage = pages.find(p => p.id === match.page);
          if (!activePage) return null;

          return (
            <PageWrapper>
              <PageTitle>{`${activePage.label} - Guide`}</PageTitle>
              <MediaQuery.Default>
                <SideNav sections={activePage.sections} />
              </MediaQuery.Default>

              {activePage.sections.map(section => (
                <Section key={section.id} {...section} />
              ))}
            </PageWrapper>
          );
        }}
      </Match>
    </>
  );
};

const Header = styled.header`
  min-height: 240px;
  max-height: 640px;
  height: 64vh;
  margin: 0 32px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-family: 'Reader', sans-serif;
  font-size: calc(64px + 8vh);
  line-height: 0.96;
  letter-spacing: -0.056em;
  text-shadow: 0 1vh 16vh rgba(0, 0, 0, 0.72);
  color: ${Colors.White()};
  text-align: center;

  background-size: cover;
  background-position: center;
  ${(props: { background?: string }) =>
    props.background && `background-image: url('${props.background}');`};

  ${media.mobile`
    margin: 0;
    font-size: 80px;
  `};

  @media (max-width: 320px) {
    font-size: 60px;
    height: 48vh;
  }
`;

const PageWrapper = styled.div`
  position: relative;

  > div:last-child {
    min-height: 100vh;
    padding-bottom: 10vh;
  }
`;
