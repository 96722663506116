import React from 'react';
import styled, { css } from 'styled-components';

import { media, Colors } from '@topia.com/ui-kit';
import { useContextPathSupport } from '@topia.com/context-path';

interface Props {
  images: string[];
}

/**
 * Inline photos grid
 */
export const PhotoGrid: React.FunctionComponent<Props> = ({ images }) => {
  const { withContextPath } = useContextPathSupport();
  if (images.length === 0) return null;

  return (
    <Wrapper>
      {images.map((url, idx) => (
        <Photo key={url} index={idx} url={withContextPath(url)} hasHighPhoto={images.length % 2 === 1} />
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  z-index: 2;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: auto;
  grid-template-rows: auto;
  grid-gap: 4px;
  width: 100%;
  margin-top: 64px;

  ${media.default`
    padding: 0 32px;
  `};

  &::after {
    content: '';
    position: absolute;
    pointer-events: none;
    background: linear-gradient(
      to bottom,
      ${Colors.White(0.01)},
      ${Colors.White()} 12.5%,
      ${Colors.White()} 87.5%,
      ${Colors.White(0.01)}
    );
    top: -25%;
    bottom: -25%;
    right: 0;
    width: 33%;
    z-index: -1;
  }
`;

interface PhotoProps {
  url: string;
  index: number;
  hasHighPhoto: boolean;
}

const Photo = styled.div`
  height: 56vh;
  max-height: ${(props: PhotoProps) => (props.hasHighPhoto ? 285 : 570)}px;

  ${media.mobile`
    max-height: ${(props: PhotoProps) => (props.hasHighPhoto ? 110 : 220)}px;
  `}

  background-position: center;
  background-size: cover;
  background-image: url('${(props: PhotoProps) => props.url}');
  ${(props: PhotoProps) =>
    props.index % 3 === 2 &&
    css`
      grid-row-start: span 2;
      max-height: 100%;
      height: 100%;

      ${media.mobile`
        max-height: 100%;
      `}
    `}
`;
