import { Router, Redirect } from '@reach/router';
import React from 'react';
import styled from 'styled-components';

import { Spinner, media, ErrorBoundary, ErrorOverlay, TLoader } from '@topia.com/ui-kit';
import PageTitle, { HeadProvider } from '@topia.com/ui-kit/PageTitle';
import { useAsync } from '@topia.com/ui-kit/hooks';
import { ContextPathProvider } from '@topia.com/context-path';

import GlobalStyle from '../go/modules/application/components/GlobalStyle';
import { SessionContext, loadSession } from '../go/modules/application/api';

import GuideView from '../go/modules/guide/GuideView';
import NeighborhoodsGoView from '../go/modules/neighborhoods/NeighborhoodsView';

import { LeftPane } from '../go/modules/neighborhoods/components/Overlay/Default';
import { ModeSwitcherWrapper } from '../go/modules/neighborhoods/components/Overlay/ModeSwitcher';
import { FlagProvider } from '@topia.com/go/features';

const App = () => {
  const session = useAsync(loadSession);

  return (
    <HeadProvider>
      <ErrorBoundary
        fallback={(error?: Error) => (
          <ErrorOverlay
            error={error}
            buttonText="Reload"
            onClose={() => {
              window.location.reload(true); // tslint:disable-line deprecation
            }}>
            <b>An unexpected error has occurred.</b> Please try reloading.
          </ErrorOverlay>
        )}>
        <GlobalStyle />
        <PageTitle />
        <ContextPathProvider contextPath="">
          <FlagProvider>
            <SessionContext.Provider value={session.data}>
              <TLoader isLoading={session.isLoading} />
              {session.error && (
                <ErrorOverlay error={session.error} buttonText="Reload" onClose={session.reload}>
                  <b>There was an error loading this page.</b> Try reloading the page, if this
                  doesn’t work please contact Support.
                </ErrorOverlay>
              )}
              {session.data && (
                <React.Suspense fallback={<Spinner label="Loading page" />}>
                  <Router>
                    <GuideView path="/guide/*" />
                    <Redirect noThrow from="/neighborhoods" to="/neighborhoods/score" />
                    <NeighborhoodsView editableOffice path="/neighborhoods/:mode" />
                  </Router>
                </React.Suspense>
              )}
            </SessionContext.Provider>
          </FlagProvider>
        </ContextPathProvider>
      </ErrorBoundary>
    </HeadProvider>
  );
};

export default App;

const NeighborhoodsView = styled(NeighborhoodsGoView)`
  margin-top: 0;

  ${media.mobile`
    height: 100vh;
  `};

  ${LeftPane} {
    max-height: calc(100% - 64px);
  }

  ${LeftPane /* sc-sel */}, ${ModeSwitcherWrapper} {
    top: 32px;
  }
`;
