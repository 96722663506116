import ky, { HTTPError } from 'ky';
import pMemoize from 'promise-memoize';

import client from '../../shared/api';
import {
  CityMetadata,
  CommuteFeatureCollection,
  GeocodeResult,
  LatLngLiteral,
  MapboxGeocodeFeatureCollection,
  Office,
  RentFeatureCollection,
  ScoreFeatureCollection
} from './types';
import config from '../../config';

/**
 * Load neighborhoods planner metadata
 */
export async function loadNeighborhoodsMetadata(cityId: number) {
  return client.get(`v2/neighborhood-planner/city/${cityId}`).json<CityMetadata | null>();
}

/**
 * Get S2 cell cover for commute
 */
export async function getCommuteCells(cityId: number, { lat, lng }: LatLngLiteral) {
  return client.get(`v2/neighborhood-planner/cells/commute/${cityId}/${lat},${lng}`).json<CommuteFeatureCollection>();
}

/**
 * Get S2 cell cover for rent
 */
export async function getRentCells(cityId: number) {
  return client.get(`v2/neighborhood-planner/cells/rent/${cityId}`).json<RentFeatureCollection>();
}

/**
 * Get data for calculating neighborhood scores
 */
export async function getScoreData(
  cityId: number,
  { lat, lng }: LatLngLiteral,
): Promise<ScoreFeatureCollection> {
  const url = `v2/neighborhood-planner/neighborhoods/score-data/${cityId}/${lat},${lng}`;
  return client.get(url).json<ScoreFeatureCollection>();
}

/**
 * Get offices in given bounding box
 */
export const getOffices = pMemoize(
  async () => {
    const data = await client.get('v1/group/offices').json<any>();
    const offices: Office[] = data.map((o: any, idx: number) => ({
      id: o.id || idx + 10000,
      label: o.label,
      address: o.postalAddress,
      latLng: { lat: o.latitude, lng: o.longitude },
    }));

    return offices;
  },
  { maxAge: 60 * 1000 },
);

/**
 * Geocode address
 */
export async function geocodeForward(query: string, bbox: GeoJSON.BBox) {
  let results: GeocodeResult[] = [];
  const searchParams = {
    access_token: config.mapboxKey,
    autocomplete: 'true',
    bbox: bbox.join(','),
    types: 'locality,neighborhood,address,poi',
  };

  const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(query)}.json`;

  try {
    const response = await ky.get(url, { searchParams }).json<MapboxGeocodeFeatureCollection>();

    results = response.features.map(({ id, place_name, text, center: [lng, lat] }) => ({
      id,
      shortName: text,
      name: place_name,
      location: { lng, lat },
    }));
  } catch (err) {
    if (err instanceof HTTPError && err.response.status === 403) {
      console.info('Invalid query to geocoder, returning empty resultset.');
    } else {
      throw err;
    }
  }

  return results;
}
