import React from 'react';
import styled from 'styled-components';
import { MenuButtonProps } from '@reach/menu-button';

import { Menu, MenuList, UnstyledMenuButton, MenuItem } from '../MenuButton';
import MoreIcon from '../../icons/small/MoreIcon';
import { BORDER_RADIUS } from '../../variables';
import { Colors } from '../../Colors';

import { RowAction, ColumnDefinition, TableSizeVariant } from './types';

interface Props<D> {
  size: TableSizeVariant;
  columns: ColumnDefinition<D>[];
  rowActions: RowAction<D>[] | ((row: D) => RowAction<D>[]);
}

/**
 * Table row action helper hook
 */
export const useRowAction = <D extends object>({ size, rowActions, columns }: Props<D>) => {
  if (rowActions.length === 0) return columns;

  return [
    ...columns,
    {
      label: '',
      cellStyle: {
        padding: 0,
      },
      render(row: D) {
        const actions = typeof rowActions === 'function' ? rowActions(row) : rowActions;
        return (
          <Wrapper>
            {actions && actions.length > 0 && (
              <Menu>
                <MenuButton
                  size={size}
                  onClick={(event: React.MouseEvent<HTMLElement>) => event.stopPropagation()}>
                  <MoreIcon width={24} height={24} />
                </MenuButton>
                <MenuList>
                  {actions.map((s, idx) => (
                    <MenuItem
                      key={idx}
                      onSelect={() => s.onClick(row)}
                      onClick={(event: React.MouseEvent<HTMLElement>) => event.stopPropagation()}>
                      {s.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
            )}
          </Wrapper>
        );
      },
    },
  ];
};

const Wrapper = styled.div`
  position: relative;
  height: 32px;
`;

type RowActionMenuButtonProps = React.FC<
  MenuButtonProps & {
    compact?: boolean;
  }
>;

const UnstyledMenuButtonWrapper: RowActionMenuButtonProps = ({ compact, ...props }) => (
  <UnstyledMenuButton {...props} />
);

/**
 * Row action menu button
 */
export const MenuButton = styled(UnstyledMenuButtonWrapper)`
  position: absolute;
  left: 8px;
  top: 50%;
  width: 32px;
  height: ${(props: { size: TableSizeVariant }) => (props.size === 'compact' ? '24px' : '32px')};
  margin-top: ${(props: { size: TableSizeVariant }) =>
    props.size === 'compact' ? '-12px' : '-16px'};
  cursor: pointer;
  padding: 0;
  line-height: 1;
  opacity: 0;
  background: ${Colors.White()};
  border: none;
  border-radius: ${BORDER_RADIUS};

  tr:hover &,
  &[aria-expanded='true'] {
    opacity: 1;
  }

  tr:not(:hover) &[aria-expanded='true'] {
    background: ${Colors.DarkGray(0.04)};
  }
`;
