/**
 * LatLng literal
 */
export interface LatLngLiteral {
  lat: number;
  lng: number;
}

/**
 * Mapbox geocode feature
 */
interface MapboxGeocodeFeature {
  id: string;
  text: string;
  place_name: string;
  center: [number, number];
}

/**
 * Mapbox geocode feature collection
 */
export interface MapboxGeocodeFeatureCollection {
  features: MapboxGeocodeFeature[];
}

/**
 * Geocoder result
 */
export interface GeocodeResult {
  id: string;
  shortName: string;
  name: string;
  location: LatLngLiteral;
}

/**
 * Minimum / maximum limits for data layer
 */
export interface LayerLimits {
  min: number;
  max: number;
}

/**
 * City Metadata
 */
export interface CityMetadata {
  id: number;
  name: string;
  currency: string;
  bbox: GeoJSON.BBox;
  commute: {
    available: CommuteMode[];
    limits: { [key in CommuteMode]: LayerLimits };
  };
  rent: {
    available: RentBedroomSize[];
    limits: { [key in RentBedroomSize]: LayerLimits };
  };
}

/**
 * Commute modes
 */
export enum CommuteMode {
  Driving = 'driving',
  Transit = 'transit',
  Bicycling = 'bicycling',
  Walking = 'walking',
}

/**
 * Bedroom sizes
 */
export enum RentBedroomSize {
  Studio = 'beds_0',
  Beds1 = 'beds_1',
  Beds2 = 'beds_2',
  Beds3 = 'beds_3',
}

/**
 * Rent-commute tradeoff modes for neighborhood scoring
 */
export enum Tradeoff {
  Balanced = 120,
  LowerRent = 60,
  FasterCommute = 240,
}

/**
 * Planner settings
 */
export interface PlannerSettings {
  commuteMode: CommuteMode;
  bedroomSize: RentBedroomSize;
  tradeoff: Tradeoff;
  maxCommute: number;
  maxRent: number;
  activeOffice: Office | undefined;
}

/**
 * Neighborhood planner modes
 */
export enum PlannerMode {
  Rent = 'rent',
  Commute = 'commute',
  Score = 'score',
}

/**
 * Commute data feature properties
 */
interface CommuteFeatureProperties {
  commute: { [mode in CommuteMode]: number };
}

/**
 * Rent data feature properties
 */
interface RentFeatureProperties {
  rent: { [mode in RentBedroomSize]: number };
}

/**
 * Score data feature properties
 */
interface RawScoreFeatureProperties
  extends Partial<RentFeatureProperties>,
    Partial<CommuteFeatureProperties> {
  id: string;
  type: 'origin' | 'ua' | 'boundary';
  name: string;
}

/**
 * Score data feature properties with calculated scores
 * has both top level rent and commute and score data
 */
export interface ScoreFeatureProperties extends RawScoreFeatureProperties {
  score: number;
  rentValue: number;
  commuteValue: number;
}

/**
 * GeoJSON FeatureCollection for commute data
 */
export type CommuteFeatureCollection = GeoJSON.FeatureCollection<
  GeoJSON.Polygon,
  CommuteFeatureProperties
>;

/**
 * GeoJSON FeatureCollection for rent data
 */
export type RentFeatureCollection = GeoJSON.FeatureCollection<
  GeoJSON.Polygon,
  RentFeatureProperties
>;

/**
 * GeoJSON FeatureCollection for score data
 */
export type RawScoreFeatureCollection = GeoJSON.FeatureCollection<
  GeoJSON.Point,
  RawScoreFeatureProperties
>;

/**
 * GeoJSON FeatureCollection for calculated score data
 */
export type ScoreFeatureCollection = GeoJSON.FeatureCollection<
  GeoJSON.Point,
  ScoreFeatureProperties
>;

/**
 * Union of planner GeoJSON FeatureCollections
 */
export type PlannerFeatureCollection =
  | ScoreFeatureCollection
  | RentFeatureCollection
  | CommuteFeatureCollection;

/**
 * Office
 */
export interface Office {
  id: number;
  label: string;
  latLng: LatLngLiteral;
  address?: string;
}
