import React, { useState } from 'react';
import { Grid, Colors, Dropdown, FontWeight } from '@topia.com/ui-kit';
import styled from 'styled-components';

import { CostsWidgetData } from '../../types';
import { Subtitle, Label, LargeFigure } from '../WidgetComponents';
import { HousingCostGraph } from './Graph';

/**
 * Costs widget
 */
export const CostsWidget = ({ living_costs, housing }: CostsWidgetData) => {
  const apartmentSizes = Object.keys(housing.ranges);
  const [apartmentType, setApartmentType] = useState(apartmentSizes[0]);
  const rentPrices = housing.ranges[apartmentType];

  const formatCurrency = new Intl.NumberFormat('en', {
    style: 'currency',
    currencyDisplay: 'symbol',
    currency: housing.currency.code,
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  }).format;

  const livingCostItems = Object.keys(living_costs.detail.this).map(id => {
    const { labels, this: current } = living_costs.detail;
    return (
      <LivingCostItem key={id}>
        {labels[id]} <b>{formatCurrency(current[id])}</b>
      </LivingCostItem>
    );
  });

  return (
    <>
      <Grid>
        <Grid.Item
          size={6}
          mobileSize={12}
          dangerouslySetInnerHTML={{ __html: living_costs.blurb }}
        />
      </Grid>
      <Grid>
        <Grid.Item size={8} mobileSize={12}>
          <Subtitle>
            Housing costs
            <Dropdown
              onChange={event => setApartmentType(event.target.value)}
              value={apartmentType}
              options={apartmentSizes.map(type => ({ label: type, value: type }))}
            />
          </Subtitle>
        </Grid.Item>
      </Grid>
      <HousingCostRow>
        <Grid.Item size={4} mobileSize={12}>
          <LargeFigure>{formatCurrency(rentPrices.mean)}</LargeFigure>
          <Label>Median rent for apartments in city center</Label>
        </Grid.Item>
        <Grid.Item size={4} mobileSize={12}>
          <HousingCostGraph formatCurrency={formatCurrency} {...rentPrices} />
        </Grid.Item>
      </HousingCostRow>
      <Subtitle>Living costs</Subtitle>
      <Grid>
        <Grid.Item size={8} mobileSize={12}>
          <LivingCostsWrapper>{livingCostItems}</LivingCostsWrapper>
        </Grid.Item>
      </Grid>
    </>
  );
};

const HousingCostRow = styled(Grid)`
  align-items: flex-end;
`;

const LivingCostsWrapper = styled.div`
  columns: 2;
  column-gap: 24px;
`;

const LivingCostItem = styled.div`
  margin-bottom: 1em;

  b {
    font-weight: ${FontWeight.SemiBold};
    color: ${Colors.SkyBlue()};
  }
`;
