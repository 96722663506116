import React from 'react';
import { Grid, Colors, FontWeight } from '@topia.com/ui-kit';
import styled from 'styled-components';

import { HighSchoolsData } from '../../types';
import { Label, LargeFigure, Subtitle, ComparisonGraph } from '../WidgetComponents';
import { formatPercentage, formatNumber, ordinal } from '../../utils/formatting';
import { pisaResultLabel } from '../../utils/widget';

const PISA_SUBJECTS: { [subject: string]: string } = {
  math: 'Mathematics',
  reading: 'Reading',
  science: 'Science',
};

/**
 * HighSchools section in education widget
 */
export const HighSchoolsSection = ({ pisa, score }: HighSchoolsData) => (
  <>
    <Subtitle>Nationwide High School Performance</Subtitle>
    <Row>
      <Grid.Item size={4} mobileSize={6}>
        <LargeFigure>{pisaResultLabel(score)}</LargeFigure>
        <Label>according to PISA test results</Label>
      </Grid.Item>
      <Grid.Item size={4} mobileSize={6}>
        <LargeFigure>{formatPercentage(pisa.happiness.responders_percent / 100)}</LargeFigure>
        <Label>Students report being happy at school</Label>
      </Grid.Item>
    </Row>
    <Grid>
      <SubjectTable size={8} mobileSize={12}>
        <Grid columns={8}>
          <Grid.Item offset={2} size={2}>
            <Label>
              PISA Score
              <br />
              (higher is better)
            </Label>
          </Grid.Item>
          <Grid.Item size={2}>
            <Label>Compared to other cities</Label>
          </Grid.Item>
          <Grid.Item size={2}>
            <Label>Teleport City ranking</Label>
          </Grid.Item>
        </Grid>
        {Object.entries(pisa.subjects).map(([id, subject]) => (
          <Grid key={id} columns={8}>
            <SubjectField>
              <b>{PISA_SUBJECTS[id]}</b>
            </SubjectField>
            <SubjectField>{formatNumber(subject.scores.mean)}</SubjectField>
            <SubjectField>
              <ComparisonGraph
                value={subject.scores.mean}
                max={subject.scores.all_uas[subject.scores.all_uas.length - 1].mean}
                min={subject.scores.all_uas[0].mean}
              />
            </SubjectField>
            <SubjectField>
              {ordinal(subject.scores.ua_rank)} of {subject.scores.total_in_rank}
            </SubjectField>
          </Grid>
        ))}
      </SubjectTable>
    </Grid>
  </>
);

/**
 * People widget rows
 */
export const Row = styled(Grid)`
  margin-bottom: 64px;
`;

const SubjectTable = styled(Grid.Item)`
  ${Grid} {
    border-bottom: solid 1px ${Colors.DarkGray(0.04)};

    &:first-child,
    &:last-child {
      border-bottom: solid 2px ${Colors.DarkGray(0.08)};
    }
  }
`;

const SubjectField = styled(Grid.Item).attrs({
  size: 2,
})`
  padding: 7px 0;

  b {
    font-weight: ${FontWeight.SemiBold};
  }
`;
