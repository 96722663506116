import React from 'react';
import styled from 'styled-components';
import { isValid } from 'date-fns';
import { groupBy } from 'lodash';

import { Colors } from '../../../Colors';
import { Button } from '../../Button';
import PlusIcon from '../../../icons/small/PlusIcon';
import { IconButton } from '../../IconButton';
import { Tag } from '../../Tag';
import { FontWeight } from '../../../FontWeight';
import DateFormatter from '../../../utility-components/DateFormatter';

import { FilterColumnType, FilterDefinition, FilterValue, Operator } from './types';
import { operatorLabels } from './labels';

interface Props {
  filter: FilterDefinition;
  onAdd: () => void;
}

const getDisplayValue = (
  value: string | number | boolean,
  rangeValues?: string[],
  isDate?: boolean,
) => {
  if (rangeValues && rangeValues.length === 2) {
    return isDate ? (
      <>
        <DateFormatter date={rangeValues[0]} /> - <DateFormatter date={rangeValues[1]} />
      </>
    ) : (
      `${rangeValues[0]} - ${rangeValues[1]}`
    );
  }
  if (typeof value === 'boolean') return value ? 'Yes' : 'No';
  if (isDate && isValid(new Date(value as string))) return <DateFormatter date={value as string} />;
  return value.toString();
};

/**
 * Shows all applied filters as tags
 */
export const AppliedFiltersBar = ({ onAdd, filter }: Props) => {
  const appliedFilterColumns = filter.columns.filter(x =>
    filter.values.find(y => y.field === x.field),
  );
  return (
    <Container>
      <FilterList>
        {appliedFilterColumns.map((filterCol, filterColIdx) => {
          const groupedFilterValues = groupBy(
            filter.values.filter(x => x.field === filterCol.field),
            'operator',
          );
          return (
            <React.Fragment key={filterColIdx}>
              {Object.entries(groupedFilterValues).map(([key, operatorFilterValues]) => (
                <React.Fragment key={key}>
                  {!showMultiTag(operatorFilterValues) ? (
                    <StyledSingleTag
                      color={filterCol && filterCol.color ? filterCol.color : Colors.LightBlue()}
                      onRemove={() =>
                        filter.onChange(
                          filter.values.filter(
                            appliedFilterValue =>
                              !(
                                appliedFilterValue.field === filterCol.field &&
                                operatorFilterValues.find(
                                  val => val.operator === appliedFilterValue.operator,
                                ) &&
                                appliedFilterValue.value === operatorFilterValues[0].value
                              ),
                          ),
                        )
                      }>
                      <GroupLabel>
                        {filterCol.label}
                        {showOperatorLabel(operatorFilterValues[0].operator) && (
                          <OperatorLabel>
                            {' '}
                            {operatorLabels[operatorFilterValues[0].operator].toLowerCase()}
                          </OperatorLabel>
                        )}
                        {showValue(operatorFilterValues[0]) && ':'}
                      </GroupLabel>
                      {showValue(operatorFilterValues[0]) && (
                        <>
                          {getDisplayValue(
                            operatorFilterValues[0].value,
                            operatorFilterValues[0].rangeValues,
                            filterCol.type === FilterColumnType.Date,
                          )}
                        </>
                      )}
                    </StyledSingleTag>
                  ) : (
                    <StyledMultiTag
                      color={filterCol && filterCol.color ? filterCol.color : Colors.LightBlue()}>
                      <MultiGroupLabel>
                        {filterCol.label}
                        {showOperatorLabel(operatorFilterValues[0].operator) && (
                          <OperatorLabel>
                            {' '}
                            {operatorLabels[operatorFilterValues[0].operator].toLowerCase()}
                          </OperatorLabel>
                        )}
                        {': '}
                      </MultiGroupLabel>
                      {(operatorFilterValues || []).map(filterVal => (
                        <StyledInnerTag
                          key={`${filterVal.field}-${filterVal.operator}-${filterVal.value}`}
                          color={Colors.White(0.64)}
                          onRemove={() =>
                            filter.onChange(
                              filter.values.filter(
                                appliedFilterValue =>
                                  !(
                                    appliedFilterValue.field === filterCol.field &&
                                    appliedFilterValue.operator === filterVal.operator &&
                                    checkRangeValuesOrValues(appliedFilterValue, filterVal)
                                  ),
                              ),
                            )
                          }>
                          {getDisplayValue(
                            filterVal.value,
                            filterVal.rangeValues,
                            filterCol.type === FilterColumnType.Date,
                          )}
                        </StyledInnerTag>
                      ))}
                    </StyledMultiTag>
                  )}
                </React.Fragment>
              ))}
            </React.Fragment>
          );
        })}
        <IconButton onClick={onAdd}>
          <PlusIcon />
        </IconButton>
      </FilterList>

      <ClearButton secondary color={Colors.DarkGray(0.04)} onClick={() => filter.onChange([])}>
        Clear
      </ClearButton>
    </Container>
  );
};

const showMultiTag = (filterValues: FilterValue[]) => {
  return filterValues.length > 1;
};

const showOperatorLabel = (operator: Operator) => {
  return ![Operator.Equals, Operator.IsBetween, Operator.IsInRange].some(x => x === operator);
};

const showValue = (filterValue: FilterValue) => {
  return filterValue.value === false || filterValue.value || filterValue.rangeValues;
};

const checkRangeValuesOrValues = (appliedFilterValue: FilterValue, filterVal: FilterValue) => {
  if (
    appliedFilterValue.rangeValues &&
    appliedFilterValue.rangeValues.length > 1 &&
    filterVal.rangeValues &&
    filterVal.rangeValues.length > 1
  ) {
    return JSON.stringify(appliedFilterValue.rangeValues) === JSON.stringify(filterVal.rangeValues);
  }
  return appliedFilterValue.value === filterVal.value;
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: solid 2px ${Colors.DarkGray(0.08)};
  padding: 24px 0;
`;

const FilterList = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  > div {
    margin-bottom: 8px;
  }
  > * {
    margin-bottom: 8px;
  }
`;

const GroupLabel = styled.span`
  margin-right: 4px;
`;

const MultiGroupLabel = styled(GroupLabel)`
  margin-bottom: 4px;
`;

const StyledMultiTag = styled(Tag)`
  display: flex;
  margin-right: 8px;
  > * {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: -4px;
    margin-right: -4px;
  }
`;

const StyledSingleTag = styled(Tag)`
  margin-right: 8px;
  min-height: 32px;
  align-items: center;
  > * {
    display: flex;
  }
`;

const StyledInnerTag = styled(Tag)`
  margin-right: 4px;
  margin-bottom: 4px;
  &:last-child {
    margin-right: 0;
  }
`;

const OperatorLabel = styled.span`
  font-weight: ${FontWeight.Bold};
`;

const ClearButton = styled(Button)`
  flex-shrink: 0;
  align-self: flex-start;
`;
