import React, { ChangeEvent, useEffect, useState } from 'react';

import styled from 'styled-components';

import { TextSize } from '../../../TextSize';
import { Colors } from '../../../Colors';
import { FontWeight } from '../../../FontWeight';
import { Button } from '../../Button';
import { Dropdown, DropdownOption, SelectElement } from '../../Dropdown';
import { Input } from '../../Input';
import { InlineFormField } from '../../InlineFormField';
import { Radio, RadioGroup } from '../../Radio';
import { DatePickerInput } from '../../DatePickerInput/DatePickerInput';
import { useKeyPress } from '../../../hooks/useKeyPress';

import {
  FilterColumnDefinition,
  FilterColumnType,
  FilterValue,
  Operator,
  OperatorType,
} from './types';
import {
  availableOperators,
  booleanFieldOptions,
  masterOperatorTypeMappings,
  operatorLabels,
  predefinedValueOperatorOptions,
} from './labels';

interface Props {
  filterColumns: FilterColumnDefinition[];
  currentValues: FilterValue[];
  onApply: (value: FilterValue) => void;
}

interface OperatorOption {
  value: Operator;
  label: string;
  operatorTypes?: OperatorType[] | undefined;
}

const filterErrorMessage = 'Filter already exists';

/**
 * Popover contents for editing filters
 */
export const FilterEditorPane = ({ filterColumns, onApply, currentValues }: Props) => {
  const columnOptions = filterColumns.map(({ label, field }) => ({ label, value: field }));
  const [activeField, setActiveField] = useState(filterColumns[0].field);
  const { type, predefinedValues, enabledOperatorMappings } = filterColumns.find(
    c => c.field === activeField,
  )!;
  const operatorsForField = enabledOperatorMappings
    ? availableOperators[type].filter(x => enabledOperatorMappings.has(x))
    : availableOperators[type];

  const operatorOptions: OperatorOption[] = operatorsForField.map(value => {
    const label = operatorLabels[value];
    const operatorTypes = enabledOperatorMappings
      ? enabledOperatorMappings.get(value)
      : masterOperatorTypeMappings.get(value);
    return { value, label, operatorTypes };
  });

  let predefinedOptions: DropdownOption[] | undefined;
  predefinedOptions = updatePredefinedOptions(predefinedOptions, predefinedValues, type);

  const [radioGroupValue, setRadioGroupValue] = React.useState(OperatorType.Absolute);

  const [filterValue, setFilterValue] = useState(
    predefinedOptions ? predefinedOptions[0].value : '',
  );
  const [filterValueLow, setFilterValueLow] = useState('');
  const [filterValueHigh, setFilterValueHigh] = useState('');

  const [datePickerFilterValue, setDatePickerFilterValue] = useState('');
  const [datePickerFilterValueLow, setDatePickerFilterValueLow] = useState('');
  const [datePickerFilterValueHigh, setDatePickerFilterValueHigh] = useState('');

  const finalOperatorOptions = shouldUsePredefinedOperators(predefinedOptions, type)
    ? predefinedValueOperatorOptions
    : operatorOptions;

  const [activeOperator, setActiveOperator] = useState(finalOperatorOptions[0]);
  const [filterError, setFilterError] = useState(false);

  useEffect(() => {
    setFilterError(false);
    setActiveOperator(finalOperatorOptions[0]);
    setFilterValue(
      shouldSetPredefinedValue(predefinedOptions, type, radioGroupValue)
        ? predefinedOptions![0].value
        : '',
    );
  }, [activeField]);

  const applyFilter = () => {
    const valueToApply = {
      field: activeField,
      operator: activeOperator.value,
      value: getCurrentFilterValue(
        filterValue,
        datePickerFilterValue,
        radioGroupValue,
        activeOperator,
        type,
        predefinedOptions,
      ),
      rangeValues: getRangeValues(
        activeOperator,
        type,
        filterValueLow,
        filterValueHigh,
        datePickerFilterValueLow,
        datePickerFilterValueHigh,
      ),
    };

    if (!isFilterPresent(currentValues, valueToApply)) {
      setFilterValue(
        shouldSetPredefinedValue(predefinedOptions, type, radioGroupValue)
          ? predefinedOptions![0].value
          : '',
      );
      onApply(valueToApply);
      setFilterError(false);
    } else {
      setFilterError(true);
    }
  };

  useKeyPress('enter', () => {
    if (
      !shouldDisableApply(
        filterValue,
        datePickerFilterValue,
        radioGroupValue,
        activeOperator,
        type,
        filterValueLow,
        filterValueHigh,
        datePickerFilterValueLow,
        datePickerFilterValueHigh,
      )
    ) {
      applyFilter();
    }
  });

  return (
    <Container>
      <Title>Filter</Title>
      <DropdownGroup>
        {renderDropdown({
          predefinedOptions: columnOptions,
          filterValue: activeField,
          onValueChange: ({ target: { value } }) => {
            setActiveField(value);
            if (predefinedValues) setActiveOperator(activeOperator);
          },
          testId: 'field-filter-dropdown',
        })}
        {type !== FilterColumnType.Boolean && (
          <StyledInlineFormField
            error={
              !shouldShowValueField(type, activeOperator) && filterError
                ? filterErrorMessage
                : undefined
            }>
            {renderDropdown({
              predefinedOptions: finalOperatorOptions,
              filterValue: activeOperator.value,
              onValueChange: event => {
                const operator = event.target.value as Operator;
                setActiveOperator({
                  value: operator,
                  label: operator,
                  operatorTypes: enabledOperatorMappings
                    ? enabledOperatorMappings.get(operator)
                    : masterOperatorTypeMappings.get(operator),
                });
              },
              testId: 'operator-filter-dropdown',
            })}
          </StyledInlineFormField>
        )}
        {shouldShowValueField(type, activeOperator) && (
          <>
            {hasRange(activeOperator.operatorTypes) ? (
              <>
                {type === FilterColumnType.Number && (
                  <>
                    {renderInput({
                      filterValue: filterValueLow,
                      onValueChange: event => {
                        setFilterValueLow(event.target.value);
                      },
                      numeric: true,
                      error: filterError,
                    })}
                    {renderInput({
                      error: filterError,
                      filterValue: filterValueHigh,
                      onValueChange: event => {
                        setFilterValueHigh(event.target.value);
                      },
                      numeric: true,
                    })}
                  </>
                )}
                {type === FilterColumnType.Date && (
                  <>
                    {renderDatePicker({
                      filterValue: datePickerFilterValueLow,
                      onValueChange: date => setDatePickerFilterValueLow(date ? date : ''),
                      error: filterError,
                    })}
                    {renderDatePicker({
                      filterValue: datePickerFilterValueHigh,
                      onValueChange: date => setDatePickerFilterValueHigh(date ? date : ''),
                      error: filterError,
                    })}
                  </>
                )}
              </>
            ) : (
              <>
                {type === FilterColumnType.Date ? (
                  <>
                    {predefinedOptions &&
                    shouldShowMultipleOptions(predefinedOptions, activeOperator) ? (
                      <StyledRadioGroup
                        name="fieldGroup"
                        selectedValue={radioGroupValue}
                        onChange={event => {
                          if (event.target.value === OperatorType.Relative) {
                            setFilterValue(predefinedOptions![0].value);
                          }
                          setRadioGroupValue(event.target.value as OperatorType);
                        }}>
                        <StyledRadio label="" value={OperatorType.Absolute}>
                          {renderDatePicker({
                            filterValue: datePickerFilterValue,
                            onValueChange: date => {
                              setDatePickerFilterValue(date ? date : '');
                              setRadioGroupValue(OperatorType.Absolute);
                            },
                            error: filterError && radioGroupValue === OperatorType.Absolute,
                          })}
                        </StyledRadio>
                        <StyledRadio label="" value={OperatorType.Relative}>
                          {renderDropdown({
                            predefinedOptions,
                            filterValue,
                            onValueChange: ({ target: { value } }) => {
                              setFilterValue(value);
                              setRadioGroupValue(OperatorType.Relative);
                            },
                            error: filterError && radioGroupValue === OperatorType.Relative,
                          })}
                        </StyledRadio>
                      </StyledRadioGroup>
                    ) : shouldShowOnlyRelative(predefinedOptions, activeOperator.operatorTypes) ? (
                      renderDropdown({
                        filterValue,
                        onValueChange: ({ target: { value } }) => setFilterValue(value),
                        predefinedOptions: predefinedOptions!,
                        error: filterError,
                      })
                    ) : (
                      renderDatePicker({
                        filterValue: datePickerFilterValue,
                        onValueChange: date => setDatePickerFilterValue(date ? date : ''),
                        error: filterError,
                      })
                    )}
                  </>
                ) : predefinedOptions ? (
                  renderDropdown({
                    predefinedOptions,
                    filterValue,
                    onValueChange: ({ target: { value } }) => setFilterValue(value),
                    error: filterError,
                    testId: 'predefined-filter-dropdown',
                  })
                ) : (
                  renderInput({
                    filterValue,
                    onValueChange: ({ target: { value } }) => setFilterValue(value),
                    numeric: type === FilterColumnType.Number,
                    error: filterError,
                  })
                )}
              </>
            )}
          </>
        )}
      </DropdownGroup>
      <ApplyButton
        data-testid="filter-apply-button"
        disabled={shouldDisableApply(
          filterValue,
          datePickerFilterValue,
          radioGroupValue,
          activeOperator,
          type,
          filterValueLow,
          filterValueHigh,
          datePickerFilterValueLow,
          datePickerFilterValueHigh,
        )}
        onClick={applyFilter}>
        Apply
      </ApplyButton>
    </Container>
  );
};

const renderDatePicker = (props: {
  filterValue: string;
  onValueChange: (date?: string) => void;
  error?: boolean;
}) => (
  <StyledInlineFormField error={props.error ? filterErrorMessage : undefined}>
    <StyledDatePickerInput value={props.filterValue} onChange={props.onValueChange} />
  </StyledInlineFormField>
);

const renderDropdown = (props: {
  predefinedOptions: DropdownOption[];
  filterValue: string;
  onValueChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  error?: boolean;
  testId?: string;
}) => (
  <StyledInlineFormField error={props.error ? filterErrorMessage : undefined}>
    <Dropdown
      data-testid={props.testId}
      options={props.predefinedOptions}
      value={props.filterValue}
      onChange={props.onValueChange}
      disabled={props.predefinedOptions && props.predefinedOptions.length === 1}
    />
  </StyledInlineFormField>
);

const renderInput = (props: {
  filterValue: string;
  onValueChange: (event: ChangeEvent<HTMLInputElement>) => void;
  error?: boolean;
  numeric?: boolean;
}) => (
  <StyledInlineFormField error={props.error ? filterErrorMessage : undefined}>
    <Input
      type={props.numeric ? 'number' : undefined}
      value={props.filterValue}
      onChange={props.onValueChange}
    />
  </StyledInlineFormField>
);

const updatePredefinedOptions = (
  predefinedOptions: DropdownOption[] | undefined,
  predefinedValues: any[] | undefined,
  type: FilterColumnType,
) => {
  return predefinedValues
    ? predefinedValues.map(v => {
        if (typeof v === 'object') return v;
        const value = v.toString();
        return { value, label: value };
      })
    : type === FilterColumnType.Boolean
    ? booleanFieldOptions
    : predefinedOptions;
};

const shouldSetPredefinedValue = (
  predefinedOptions: DropdownOption[] | undefined,
  type: FilterColumnType,
  radioGroupValue: string,
) => {
  return predefinedOptions && (type !== FilterColumnType.Date || radioGroupValue === 'relative');
};

const isFilterPresent = (currentValues: FilterValue[], valueToApply: FilterValue) => {
  if (!currentValues || currentValues.length === 0 || !valueToApply) {
    return false;
  }
  return currentValues.find(x => {
    if (x.field === valueToApply.field && x.operator === valueToApply.operator) {
      if (
        x.rangeValues &&
        valueToApply.rangeValues &&
        x.rangeValues.length > 0 &&
        valueToApply.rangeValues.length > 0
      ) {
        return JSON.stringify(x.rangeValues) === JSON.stringify(valueToApply.rangeValues);
      }
      return x.value === valueToApply.value;
    }
    return false;
  });
};

const shouldUsePredefinedOperators = (
  predefinedOptions: DropdownOption[] | undefined,
  type: FilterColumnType,
) => {
  return predefinedOptions && ![FilterColumnType.Date, FilterColumnType.Array].includes(type);
};

const shouldPickDatepickerValue = (
  type: FilterColumnType,
  radioGroupValue: string,
  predefinedOptions: DropdownOption[] | undefined,
  activeOperator: OperatorOption,
) => {
  if (type === FilterColumnType.Date) {
    if (shouldShowMultipleOptions(predefinedOptions, activeOperator)) {
      return radioGroupValue === 'absolute';
    }
    if (
      activeOperator.operatorTypes &&
      activeOperator.operatorTypes.includes(OperatorType.Absolute)
    ) {
      return true;
    }
  }
  return false;
};

const shouldShowValueField = (type: FilterColumnType, activeOperator: OperatorOption) => {
  if (type === FilterColumnType.Boolean) return true;
  if (activeOperator.operatorTypes && activeOperator.operatorTypes.includes(OperatorType.None)) {
    return false;
  }
  return true;
};

const shouldShowMultipleOptions = (
  predefinedOptions: DropdownOption[] | undefined,
  activeOperator: OperatorOption,
) => {
  return predefinedOptions && hasAbsoluteAndRelative(activeOperator.operatorTypes);
};

const hasAbsoluteAndRelative = (operatorTypes: OperatorType[] | undefined) => {
  return (
    operatorTypes &&
    [OperatorType.Absolute, OperatorType.Relative].every(x => operatorTypes.includes(x))
  );
};

const hasOnlyRelative = (operatorTypes: OperatorType[] | undefined) => {
  return (
    operatorTypes &&
    !operatorTypes.includes(OperatorType.Absolute) &&
    operatorTypes.includes(OperatorType.Relative)
  );
};

const shouldShowOnlyRelative = (
  predefinedOptions: DropdownOption[] | undefined,
  operatorTypes: OperatorType[] | undefined,
) => {
  return predefinedOptions && hasOnlyRelative(operatorTypes);
};

const hasRange = (operatorTypes: OperatorType[] | undefined) => {
  return operatorTypes && operatorTypes.includes(OperatorType.Range);
};

const getCurrentFilterValue = (
  filterValue: string,
  dateFilterValue: string,
  radioGroupValue: string,
  activeOperator: OperatorOption,
  type: FilterColumnType,
  predefinedOptions: DropdownOption[] | undefined,
) => {
  return hasRange(activeOperator.operatorTypes)
    ? ''
    : type === FilterColumnType.Boolean
    ? filterValue === 'true'
    : shouldPickDatepickerValue(type, radioGroupValue, predefinedOptions, activeOperator)
    ? dateFilterValue
    : type === FilterColumnType.Number
    ? parseFloat(filterValue)
    : activeOperator.operatorTypes && activeOperator.operatorTypes.includes(OperatorType.None)
    ? ''
    : filterValue;
};

const getRangeValues = (
  activeOperator: OperatorOption,
  type: FilterColumnType,
  filterValueLow: string,
  filterValueHigh: string,
  dateFilterValueLow: string,
  dateFilterValueHigh: string,
) => {
  return hasRange(activeOperator.operatorTypes)
    ? type === FilterColumnType.Number
      ? [filterValueLow, filterValueHigh]
      : [dateFilterValueLow, dateFilterValueHigh]
    : undefined;
};

const shouldDisableApply = (
  filterValue: string,
  dateFilterValue: string,
  radioGroupValue: string,
  activeOperator: OperatorOption,
  type: FilterColumnType,
  filterValueLow: string,
  filterValueHigh: string,
  dateFilterValueLow: string,
  dateFilterValueHigh: string,
) => {
  if (activeOperator.operatorTypes && activeOperator.operatorTypes.includes(OperatorType.None)) {
    return false;
  }
  if (activeOperator.operatorTypes && activeOperator.operatorTypes.includes(OperatorType.Range)) {
    if (type === FilterColumnType.Number) {
      return !filterValueLow.trim() || !filterValueHigh.trim();
    }
    if (type === FilterColumnType.Date) {
      return !dateFilterValueLow.trim() || !dateFilterValueHigh.trim();
    }
  }
  if (type === FilterColumnType.Date && radioGroupValue === 'absolute') {
    return !dateFilterValue;
  }
  return !filterValue;
};

const Container = styled.div`
  padding: 24px;
  padding-bottom: 32px;
  width: 288px;
`;

const DropdownGroup = styled.div`
  ${Input /* sc-sel */},
  ${SelectElement} {
    width: 100%;
    margin-bottom: 8px;
  }
`;

const Title = styled.div`
  ${TextSize.SemiMedium};
  font-size: 20px;
  font-weight: ${FontWeight.Bold};
  color: ${Colors.NavyBlue()};
  margin-bottom: 16px;
`;

const StyledRadioGroup = styled(RadioGroup)`
  > * {
    margin: 0;
  }
`;

const StyledRadio = styled(Radio)`
  margin-bottom: 8px;
`;

const StyledDatePickerInput = styled(DatePickerInput)`
  > div {
    width: 100%;
  }
`;

const StyledInlineFormField = styled(InlineFormField)`
  width: 100%;
  > div {
    margin-top: 0;
  }
`;

const ApplyButton = styled(Button).attrs({ secondary: true })`
  margin-top: 8px;
  display: block;
  width: 100%;
`;
