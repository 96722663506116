import memoize from 'lodash/memoize';
import flatten from 'lodash/flatten';

import { PlannerMode, PlannerSettings, CityMetadata } from '../types';

/**
 * Cholorpleth palette
 */
const PALETTE = [
  '#006837',
  '#1a9850',
  '#66bd63',
  '#a6d96a',
  '#d9ef8b',
  '#ffffbf',
  '#fee08b',
  '#fdae61',
  '#f46d43',
  '#d73027',
  '#a50026',
];

/**
 * Convert GeoJSON.BBox to LatLngBoundsLike
 * https://www.mapbox.com/mapbox-gl-js/api#lnglatboundslike
 */
export const convertToLatLngBounds = memoize(
  (bbox: GeoJSON.BBox) =>
    [[bbox[0], bbox[1]], [bbox[2], bbox[3]]] as [[number, number], [number, number]],
);

/**
 * Pad bounding box by percentage ratio
 * https://github.com/Leaflet/Leaflet/blob/9fda888df2791ca0df6f182a9d91d393ee49034c/src/geo/LatLngBounds.js#L87
 */
export const padBBox = memoize(
  ([swLng, swLat, neLng, neLat]: GeoJSON.BBox, bufferRatio: number): GeoJSON.BBox => {
    const heightBuffer = Math.abs(swLat - neLat) * bufferRatio;
    const widthBuffer = Math.abs(swLng - neLng) * bufferRatio;
    return [swLng - widthBuffer, swLat - heightBuffer, neLng + widthBuffer, neLat + heightBuffer];
  },
);

/**
 * Calculate array of color stops for step expressions
 * https://www.mapbox.com/mapbox-gl-js/style-spec#expressions-step
 */
export const stepExpressionColorStops: (
  minValue: number,
  maxValue: number,
) => (string | number)[] = (minValue, maxValue) => {
  // Reverse palette if ranking is inverted
  const [first, ...colors] = minValue > maxValue ? [...PALETTE].reverse() : PALETTE;
  const [min, max] = minValue > maxValue ? [maxValue, minValue] : [minValue, maxValue];
  const stepSize = (max - min) / colors.length;

  // First stop value is separate
  const zeroStop: (string | number)[] = [first];
  const nStops = flatten(colors.map((c, i) => [min + i * stepSize, c]));
  return zeroStop.concat(nStops);
};

/**
 * Generate MapboxGL lookup expression
 * https://www.mapbox.com/mapbox-gl-js/style-spec/#expressions-lookup
 */
const lookupExpression = (type: 'get' | 'has', ...keypath: string[]) =>
  keypath.reduce(
    (exp, path, idx) => {
      // Switch lookup type on last element only
      const lookup = idx === keypath.length - 1 ? type : 'get';
      if (exp.length === 0) return [lookup, path];
      return [lookup, path, exp];
    },
    [] as any[],
  );

/**
 * MapboxGL filter expression
 * https://www.mapbox.com/mapbox-gl-js/style-spec/#layer-filter
 */
export const filterExpression = (mode: PlannerMode, settings: PlannerSettings) => {
  const { maxCommute, maxRent, commuteMode, bedroomSize } = settings;
  const rentLimiter = ['<=', lookupExpression('get', 'rent', bedroomSize), maxRent];
  const commuteLimiter = ['<=', lookupExpression('get', 'commute', commuteMode), maxCommute];

  switch (mode) {
    case PlannerMode.Score:
      return ['all', lookupExpression('has', 'score'), rentLimiter, commuteLimiter];
    case PlannerMode.Commute:
      return ['all', lookupExpression('has', 'commute', commuteMode), commuteLimiter];
    case PlannerMode.Rent:
      return ['all', lookupExpression('has', 'rent', bedroomSize), rentLimiter];
  }
};

/**
 * Generate full step expression for coloring map
 * https://www.mapbox.com/mapbox-gl-js/style-spec#expressions-step
 */
export const colorStepExpression = (
  mode: PlannerMode,
  settings: PlannerSettings,
  meta: CityMetadata,
) => {
  switch (mode) {
    // Score palette is reversed because 10 is the best score
    case PlannerMode.Score: {
      return ['step', lookupExpression('get', 'score'), ...stepExpressionColorStops(10, 0)];
    }
    // Commute palette is capped to sane default of 2 hours of maximum commute
    case PlannerMode.Commute: {
      const limits = meta.commute.limits[settings.commuteMode];
      return [
        'step',
        lookupExpression('get', 'commute', settings.commuteMode),
        ...stepExpressionColorStops(limits.min, limits.max),
      ];
    }
    // Rent palette is dynamic as rent ranges are different per town
    case PlannerMode.Rent: {
      const limits = meta.rent.limits[settings.bedroomSize];
      return [
        'step',
        lookupExpression('get', 'rent', settings.bedroomSize),
        ...stepExpressionColorStops(limits.min, limits.max),
      ];
    }
  }
};
