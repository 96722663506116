import React from 'react';
import { GeoJSONLayer, Marker } from 'react-mapbox-gl';
import center from '@turf/center';
import styled from 'styled-components';

import { Colors } from '@topia.com/ui-kit';
import OfficeIcon from '@topia.com/ui-kit/icons/OfficeIcon';

import {
  CityMetadata,
  PlannerMode,
  PlannerSettings,
  LatLngLiteral,
  ScoreFeatureProperties,
  PlannerFeatureCollection,
} from '../../types';
import { filterExpression, colorStepExpression } from '../../utils/map';
import { Popup } from './Popup';
import { GLMap } from './Wrapper';
import { getDurationStringFor, currencyFormatter } from '../../utils/formatting';
import { COMMUTE_LABELS } from '../../data/labels';

import pinIcon from './assets/pin.svg';

const TEXT_LAYER = 'waterway-label';

interface Props {
  mode: PlannerMode;
  geoJSON?: PlannerFeatureCollection;
  meta: CityMetadata;
  settings: PlannerSettings;
  pinLocation: LatLngLiteral | null;
  onFeatureHover: (mode: PlannerMode) => void;
}

interface State {
  hoverTooltip?: React.ReactElement<unknown>;
}

/**
 * Map - handles display logic
 */
export default class Map extends React.Component<Props, State> {
  state: State = { hoverTooltip: undefined };

  onMouseMove = ({ features, target }: mapboxgl.MapLayerMouseEvent) => {
    if (!features || features.length === 0) return;
    target.getCanvas().style.cursor = 'pointer';

    const feature = features[0] as unknown;
    const { meta, settings, mode } = this.props;
    const { commuteMode, bedroomSize } = settings;
    const formatMoney = currencyFormatter(meta.currency);
    this.props.onFeatureHover(mode);

    const commuteModeLabel = COMMUTE_LABELS[commuteMode].toLowerCase();

    let hoverTooltip;

    if (mode === PlannerMode.Score) {
      const { properties, geometry } = feature as GeoJSON.Feature<
        GeoJSON.Point,
        ScoreFeatureProperties
      >;
      hoverTooltip = (
        <Popup coordinates={geometry.coordinates} offset={[0, -5]}>
          <div>
            <b>{properties.name}</b>
          </div>
          <div>
            {getDurationStringFor(properties.commuteValue)} {commuteModeLabel}
          </div>
          <div>{formatMoney(properties.rentValue)} rent</div>
        </Popup>
      );
    } else {
      const { properties, geometry } = feature as GeoJSON.Feature<
        GeoJSON.Polygon,
        { rent?: string; commute?: string }
      >;
      let value;
      if (properties.rent) {
        const rent = JSON.parse(properties.rent);
        value = `${formatMoney(rent[bedroomSize])} rent`;
      }

      if (properties.commute) {
        const commute = JSON.parse(properties.commute);
        value = `${getDurationStringFor(commute[commuteMode])} ${commuteModeLabel}`;
      }

      hoverTooltip = <Popup coordinates={center(geometry).geometry.coordinates}>{value}</Popup>;
    }

    this.setState({ hoverTooltip });
  };

  onMouseLeave = (event: mapboxgl.MapLayerMouseEvent) => {
    event.target.getCanvas().style.cursor = '';
    this.setState({ hoverTooltip: undefined });
  };

  renderGeoJSONLayer(geoJSON: PlannerFeatureCollection) {
    const { meta, mode, settings } = this.props;
    const isScore = mode === PlannerMode.Score;
    const type = isScore ? 'circle' : 'fill';
    const id = `${isScore ? 'neighborhoods' : 's2'}-overlay`;

    const mouseProps = {
      [`${type}OnMouseEnter`]: this.onMouseMove, // For mobile tap
      [`${type}OnMouseMove`]: this.onMouseMove, // Overlapping features don't trigger otherwise
      [`${type}OnMouseLeave`]: this.onMouseLeave,
    };

    return (
      <GeoJSONLayer
        id={id}
        key={id}
        before={TEXT_LAYER}
        layerOptions={{ filter: filterExpression(mode, settings) }}
        {...mouseProps}
        data={geoJSON}
        circlePaint={
          isScore && {
            'circle-color': colorStepExpression(mode, settings, meta),
            'circle-radius': ['interpolate', ['linear'], ['zoom'], 10, 5, 13, 12, 16, 24],
            'circle-opacity': 1,
            'circle-stroke-width': ['interpolate', ['linear'], ['zoom'], 10, 1, 16, 4],
            'circle-stroke-color': [
              'interpolate',
              ['linear'],
              ['zoom'],
              10,
              '#666',
              12,
              '#fff',
              16,
              '#fff',
            ],
          }
        }
        fillPaint={
          !isScore && {
            'fill-color': colorStepExpression(mode, settings, meta),
            'fill-opacity': 0.5,
          }
        }
      />
    );
  }

  render() {
    const { meta, pinLocation, settings, geoJSON } = this.props;
    const { activeOffice } = settings;

    return (
      <GLMap bbox={meta.bbox}>
        {geoJSON && this.renderGeoJSONLayer(geoJSON)}
        {activeOffice && (
          <Popup type="secondary" coordinates={[activeOffice.latLng.lng, activeOffice.latLng.lat]}>
            <OfficeIcon data-testid="office-popup" color={Colors.White()} />
          </Popup>
        )}
        {pinLocation && (
          <PinMarker coordinates={[pinLocation.lng, pinLocation.lat]}>
            <img data-testid="pin-marker" src={pinIcon} />
          </PinMarker>
        )}
        {this.state.hoverTooltip}
      </GLMap>
    );
  }
}

const PinMarker = styled(Marker)`
  pointer-events: none;
`;
