import React from 'react';
import styled from 'styled-components';

import { BORDER_RADIUS } from '../variables';
import { Colors } from '../Colors';
import { FontWeight } from '../FontWeight';
import AlertIcon from '../icons/AlertIcon';

interface Props {
  /**
   * Error to show some debug details about
   */
  error?: Error;
  className?: string;
}

/**
 * Inline error message
 */
export const ErrorMessage: React.FC<Props> = ({ className, error, children }) => (
  <Wrapper className={className}>
    <Message>
      <AlertIcon />
      {children}
    </Message>
    {error && (
      <DebugText>
        {new Date().toISOString()} <br />
        {formatError(error)}
      </DebugText>
    )}
  </Wrapper>
);

const Wrapper = styled.div`
  display: inline-block;
  padding: 6px 12px;

  font-weight: ${FontWeight.SemiBold};
  border-radius: ${BORDER_RADIUS};
  background-color: ${Colors.YellowSoda()};
`;

const Message = styled.span`
  display: inline-flex;
  align-items: center;
  vertical-align: top;

  svg {
    color: ${Colors.DarkGray()};
    margin-right: 4px;
  }

  a {
    color: ${Colors.DarkGray()};
  }
`;

/**
 * Small debug text
 */
export const DebugText = styled.div`
  font-size: 10px;
  line-height: 10px;
  margin-top: 4px;
  color: ${Colors.Disabled()};
  text-align: right;
`;

/**
 * Format error message
 */
export const formatError = (err: Error) => {
  if (err.name === 'HTTPError') {
    const { status, url } = (err as any).response;
    return `HTTPError ${status} ${url.replace(window.location.origin, '')}`;
  }

  return `${err.name}: ${err.message}`;
};
