import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Colors, FontWeight, TextSize, media } from '@topia.com/ui-kit';

import { AddressSuggest } from './AddressSuggest';
import { Office } from '../types';

interface Props {
  bbox: GeoJSON.BBox;
  onPick: (office: Office) => void;
}

/**
 * Office picker overlay
 */
export const OfficePicker = ({ bbox, onPick }: Props) => {
  const [pickedOffice, setPickedOffice] = useState<Office | undefined>();

  return (
    <OfficePickerWrapper>
      <Message>
        For the best neighborhood match, we need <b>your new office location.</b>
      </Message>
      <AddressSuggest
        bbox={bbox}
        value={pickedOffice ? pickedOffice.latLng : null}
        onChange={(latLng, item) => {
          if (!latLng) return setPickedOffice(undefined);
          setPickedOffice({
            latLng,
            id: -1,
            label: item ? item.shortName : 'My office',
            address: item && item.name,
          });
        }}
      />
      <ContinueButton disabled={!pickedOffice} onClick={() => onPick(pickedOffice!)}>
        Continue to the Map
      </ContinueButton>
    </OfficePickerWrapper>
  );
};

const OfficePickerWrapper = styled.div`
  background-color: ${Colors.White()};
  padding: 32px 40px;
  max-width: 448px;
  position: absolute;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  ${media.mobile`
    width: 100%;
  `};
`;

const Message = styled.div`
  ${TextSize.Medium};
  margin-bottom: 32px;
  color: ${Colors.NavyBlue()};

  b {
    font-weight: ${FontWeight.SemiBold};
  }
`;

const ContinueButton = styled(Button).attrs({ large: true })`
  margin-top: 32px;
  display: block;
  width: 100%;
`;
