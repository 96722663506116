import React from 'react';

import { Widgets, WidgetSection } from '../types';
import { VideoWidget } from './VideoWidget';
import { WeatherWidget } from './WeatherWidget';
import { PeopleWidget } from './PeopleWidget';
import { CostsWidget } from './CostsWidget';
import { EducationWidget } from './EducationWidget';
import { NeighborhoodButtonWidget } from './NeighborhoodButtonWidget';

/**
 * Widgets wrapper
 */
export const WidgetWrapper = (section: WidgetSection) => {
  switch (section.id) {
    case Widgets.Video:
      return <VideoWidget {...section.data.video} />;
    case Widgets.Weather:
      return <WeatherWidget {...section.data.weather} />;
    case Widgets.People:
      return <PeopleWidget {...section.data} />;
    case Widgets.Costs:
      return <CostsWidget {...section.data} />;
    case Widgets.Education:
      return <EducationWidget {...section.data} />;
    case Widgets.NeighborhoodButton:
      return <NeighborhoodButtonWidget />;
    default:
      return null;
  }
};
