import React from 'react';
import { IndicatorProps } from 'react-select';
import styled from 'styled-components';

import { MultiSelectProps } from '../MultiSelect';
import { Colors } from '../../../Colors';
import CloseIcon from '../../../icons/small/CloseIcon';

/**
 * Multi-select clear indicator component
 */
export const ClearIndicator = (props: IndicatorProps<any>) => (
  <Root
    {...props.innerProps}
    tabIndex={-1}
    searchField={(props.selectProps.multiSelectProps as MultiSelectProps<any>).searchField}>
    <CloseIcon />
  </Root>
);

const Root = styled.button<{ searchField: boolean }>`
  width: 20px;
  height: 20px;
  margin-right: ${props => (props.searchField ? 8 : 6)}px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: none;
  border-radius: 50%;
  background-color: ${Colors.DarkGray(0.04)};
  cursor: pointer;

  &:hover {
    background-color: ${Colors.DarkGray(0.08)};
  }
`;
