import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { animated, SpringValue } from 'react-spring';

import { DEFAULT_BOX_SHADOW, BORDER_RADIUS } from '../../variables';
import { Button } from '../Button';
import { Colors } from '../../Colors';
import InfoIcon from '../../icons/small/InfoIcon';
import CheckIcon from '../../icons/small/CheckIcon';

import { AlertStatus, AlertItem } from './types';

interface Props {
  item: AlertItem;
  style?: { opacity: SpringValue<number> };
  onClose?: () => void;
}

/**
 * Alert item
 */
export const Alert = ({ item, style, onClose }: Props) => {
  const Icon = Icons[item.status];

  useEffect(() => {
    if (item.duration && onClose) {
      const timeoutId = setTimeout(onClose, item.duration);
      return () => clearTimeout(timeoutId);
    }
  }, []);

  return (
    <Item status={item.status} style={style || {}}>
      <Icon />
      {item.message}
      {item.button && (
        <AlertAction
          secondary={item.status !== AlertStatus.Critical}
          onClick={() => {
            item.button!.onClick();
            if (onClose) onClose();
          }}>
          {item.button.label}
        </AlertAction>
      )}
    </Item>
  );
};

// TODO: Fix the infinite type instantiation bug
const Item = styled(animated.div as any)`
  transform: translate3d(0, 0, 0);
  padding: 8px 12px;
  margin-bottom: 8px;
  box-shadow: ${DEFAULT_BOX_SHADOW};
  border-radius: ${BORDER_RADIUS};
  white-space: nowrap;

  background-color: ${(props: { status: AlertStatus }) => {
    switch (props.status) {
      case AlertStatus.Critical:
        return Colors.YellowSoda();
      case AlertStatus.Error:
        return '#f5f5f5';
      case AlertStatus.Success:
        return Colors.White();
    }
  }};
`;

const iconStyle = css`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  padding: 4px;
  margin-right: 16px;
`;

const Icons = {
  [AlertStatus.Error]: styled(InfoIcon)`
    ${iconStyle};
    background-color: ${Colors.YellowSoda()};
    color: ${Colors.DarkGray()};
    transform: rotateZ(180deg);
  `,
  [AlertStatus.Critical]: styled(InfoIcon)`
    ${iconStyle};
    background-color: ${Colors.DarkGray()};
    color: ${Colors.YellowSoda()};
    transform: rotateZ(180deg);
  `,
  [AlertStatus.Success]: styled(CheckIcon)`
    ${iconStyle};
    background-color: ${Colors.Green()};
    color: white;
  `,
};

const AlertAction = styled(Button)`
  margin-left: 16px;
`;
