import pMemoize from 'promise-memoize';

import client from '../../shared/api';
import { Neighborhood } from './types';
import { toTitleCase, sanitizeDescription } from './utils';

/**
 * Get closer look neighborhoods from content API
 */
export const getNeighborhoods = pMemoize(
  async () => {
    const response = await client.get('v1/neighbourhoods').json<any[]>();

    const neighborhoods: Neighborhood[] = response.map(({ rents, description, title, ...n }) => ({
      ...n,
      title: toTitleCase(title),
      description: sanitizeDescription(description || ''),
    }));

    return neighborhoods;
  },
  { maxAge: 600 * 100 },
);
