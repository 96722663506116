import pMemoize from 'promise-memoize';

import api from '../../shared/api';

import { GuidePages } from './types';

/**
 * Load guide data
 */
export const loadGuide = pMemoize(async () => api.get(`v2/guide/content`).json<GuidePages>(), {
  maxAge: 600 * 100,
});
