import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'whatwg-fetch';
import 'abortcontroller-polyfill';

import React from 'react';
import { render } from 'react-dom';

import Application from './Application';

render(React.createElement(Application), document.getElementById('app'));

const appLoader = document.getElementById('t-loader');
if (appLoader && appLoader.parentElement) {
  appLoader.parentElement.removeChild(appLoader);
}
