import React from 'react';
import styled from 'styled-components';

import { PlannerSettings, ScoreFeatureCollection } from '../../types';
import { NeighborhoodItem } from './NeighborhoodItem';
import { Section, SectionDetails } from './SettingsSection';
import { topNeighborhoods } from '../../utils/score';

interface Props {
  data: ScoreFeatureCollection;
  settings: PlannerSettings;
  currencyCode: string;
}

/**
 * Neighborhoods list
 */
export const NeighborhoodList: React.FunctionComponent<Props> = React.memo(
  ({ data, settings, currencyCode }) => (
    <Section testId="top-neighborhoods" title="Top neighborhoods">
      <ListDetails data-testid="top-neighborhoods-list">
        {topNeighborhoods(data, settings, currencyCode).map(item => (
          <NeighborhoodItem key={item.id} {...item} />
        ))}
      </ListDetails>
    </Section>
  ),
);

const ListDetails = styled(SectionDetails)`
  margin: 8px -8px 0;
  width: calc(100% + 16px);
`;
