import { StylesConfig } from 'react-select';
import { styleFn } from 'react-select/src/styles';

import { Colors } from '../../Colors';
import { HorizontalMenuPadding, OptionVerticalPadding, LineHeight } from './constants';
import { BORDER_RADIUS, DEFAULT_BOX_SHADOW } from '../../variables';
import { MultiSelectProps } from './MultiSelect';

const dropdownMessage: styleFn = provided => ({
  ...provided,
  color: Colors.DarkGray(0.48),
  padding: `${OptionVerticalPadding}px ${HorizontalMenuPadding}px`,
  display: 'flex',
  alignItems: 'center',
});

/**
 * Style overrides
 */
export const styles: (props: MultiSelectProps<any>) => StylesConfig = props => ({
  // parent of control
  container: (provided, state) => ({
    ...provided,
    ...(state.isDisabled
      ? {
          pointerEvents: 'auto',
        }
      : {}),
  }),
  // parent of valueContainer & indicatorsContainer
  control: (provided, state) => {
    return {
      ...provided,
      lineHeight: `${LineHeight}px`,
      padding: '2px 12px',
      paddingRight: 0,
      minHeight: 0,
      appearance: 'none',
      color: Colors.DarkGray(),
      borderRadius: BORDER_RADIUS,
      border: 'none',
      backgroundColor: Colors.White(),
      boxShadow: `0 1px 4px 0 ${Colors.NavyBlue(0.16)}`,
      transition: 'none',
      cursor: 'text',
      ...(!props.singleValue && state.getValue().length > 0
        ? {
            paddingLeft: '4px',
          }
        : {}),
      ...(state.isFocused
        ? {
            boxShadow: `0 0 0 2px ${Colors.LightBlue(0.4)}, 0 1px 4px 0 ${Colors.DarkGray(0.08)}`,
          }
        : {}),
      ...(state.isDisabled
        ? {
            cursor: 'not-allowed',
            color: Colors.DarkGray(0.56),
          }
        : {}),
    };
  },
  valueContainer: provided => ({
    ...provided,
    padding: 0,
  }),
  indicatorsContainer: provided => ({
    ...provided,
    height: `${LineHeight}px`,
  }),
  placeholder: provided => ({
    ...provided,
    margin: 0,
    color: Colors.DarkGray(0.4),
  }),
  input: provided => ({
    ...provided,
    margin: 0,
    padding: 0,
  }),
  // parent of menuList
  menu: provided => ({
    ...provided,
    boxShadow: DEFAULT_BOX_SHADOW,
    backgroundColor: Colors.White(),
    border: 'none',
    borderRadius: BORDER_RADIUS,
  }),
  menuList: provided => ({
    ...provided,
    borderRadius: BORDER_RADIUS,
    padding: '8px 0',
  }),
  group: provided => ({
    ...provided,
    paddingTop: '8px',
    paddingBottom: '12px',
  }),
  noOptionsMessage: dropdownMessage,
  loadingMessage: dropdownMessage,
});
