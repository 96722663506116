import React from 'react';
import styled from 'styled-components';
import throttle from 'lodash/throttle';

import { Colors, RangeSlider } from '@topia.com/ui-kit';
import { BORDER_RADIUS } from '@topia.com/ui-kit/variables';

import {
  PlannerMode,
  CityMetadata,
  CommuteMode,
  RentBedroomSize,
  PlannerSettings,
  Office,
} from '../../types';
import { getDurationStringFor, currencyFormatter } from '../../utils/formatting';
import { Section, SmallDropdown, SectionDetails } from './SettingsSection';
import { COMMUTE_LABELS, BEDROOM_LABELS, TRADEOFF_LABELS } from '../../data/labels';
import { SingleOfficeEditor } from './SingleOfficeEditor';

const TRADEOFF_OPTIONS = Object.entries(TRADEOFF_LABELS).map(([value, label]) => ({
  label,
  value,
}));

/**
 * Settings panel props
 */
export interface SettingsProps {
  className?: string;
  settings: PlannerSettings;
  offices: Office[];
  mode: PlannerMode;
  meta: CityMetadata;
  onChange: (settings: PlannerSettings) => void;
  onClickOutside?: () => void;
  onSettingsUpdated: (changed: string, settings: PlannerSettings) => void;
}

/**
 * Settings
 */
export default class Settings extends React.PureComponent<SettingsProps, PlannerSettings> {
  throttledChange: (settings: PlannerSettings) => void;
  formatCurrency: (value: number) => string;

  constructor(props: SettingsProps) {
    super(props);
    this.state = props.settings;
    this.throttledChange = throttle(props.onChange, 32);
    this.formatCurrency = currencyFormatter(props.meta.currency);
  }

  handleChange = <K extends keyof PlannerSettings>(
    field: K,
    value: PlannerSettings[K],
    throttleUpdate = false,
  ) => {
    this.setState(
      prevState => ({
        ...prevState,
        [field]: value,
      }),
      () => {
        this.props.onSettingsUpdated(field, this.state);
        if (field === 'bedroomSize') this.setDefaultMaxRent();
        throttleUpdate ? this.throttledChange(this.state) : this.props.onChange(this.state);
      },
    );
  };

  setDefaultMaxRent() {
    const { bedroomSize } = this.state;
    const {
      meta: { rent },
    } = this.props;
    const limits = rent.limits[bedroomSize];
    this.handleChange('maxRent', Math.round((limits.max + limits.min) / 2));
  }

  renderCommute() {
    const { commuteMode, maxCommute } = this.state;
    const { commute } = this.props.meta;
    const limits = commute.limits[commuteMode];

    return (
      <Section testId="daily-commute" title="Daily commute">
        <SmallDropdown
          data-testid="commute-mode"
          options={commute.available.map(value => ({ value, label: COMMUTE_LABELS[value] }))}
          value={commuteMode}
          onChange={event => this.handleChange('commuteMode', event.target.value as CommuteMode)}
        />
        <SectionDetails>
          <RangeSlider
            data-testid="max-commute"
            {...limits}
            value={maxCommute}
            formatLabel={getDurationStringFor}
            onChange={value => this.handleChange('maxCommute', value, true)}
          />
        </SectionDetails>
      </Section>
    );
  }

  renderRent() {
    const { bedroomSize, maxRent } = this.state;
    const {
      meta: { rent },
    } = this.props;
    const limits = rent.limits[bedroomSize];

    return (
      <Section testId="monthly-rent" title="Monthly rent">
        <SmallDropdown
          data-testid="apartment-type"
          options={rent.available.map(value => ({ value, label: BEDROOM_LABELS[value] }))}
          value={bedroomSize}
          onChange={event =>
            this.handleChange('bedroomSize', event.target.value as RentBedroomSize)
          }
        />
        <SectionDetails>
          <RangeSlider
            data-testid="max-rent"
            {...limits}
            value={maxRent}
            onChange={value => this.handleChange('maxRent', value, true)}
            formatLabel={value => this.formatCurrency(value)}
          />
        </SectionDetails>
      </Section>
    );
  }

  render() {
    const { activeOffice, tradeoff } = this.state;
    const { mode, offices, children, className } = this.props;

    return (
      <Container data-testid="settings" className={className}>
        <Section testId="office" title="Office">
          {offices.length === 1 ? (
            <SingleOfficeEditor office={offices[0]} />
          ) : (
            <SmallDropdown
              data-testid="active-office"
              options={offices.map(o => ({ label: o.label, value: o.id.toString() }))}
              value={(activeOffice || offices[0]).id.toString()}
              onChange={event => {
                const newOffice = offices.find(o => o.id === parseInt(event.target.value, 10));
                this.handleChange('activeOffice', newOffice);
              }}
            />
          )}
        </Section>
        {(mode === PlannerMode.Commute || mode === PlannerMode.Score) && this.renderCommute()}
        {(mode === PlannerMode.Rent || mode === PlannerMode.Score) && this.renderRent()}
        {mode === PlannerMode.Score && (
          <Section testId="care-most" title="I care most about">
            <SmallDropdown
              data-testid="tradeoff-type"
              options={TRADEOFF_OPTIONS}
              value={tradeoff.toString()}
              onChange={event => this.handleChange('tradeoff', parseInt(event.target.value, 10))}
            />
          </Section>
        )}
        {children}
      </Container>
    );
  }
}

const Container = styled.div`
  margin-top: 16px;
  width: 320px;
  padding: 20px;

  overflow: auto;
  border-radius: ${BORDER_RADIUS};
  background-color: ${Colors.White()};
`;
