import React from 'react';

interface Props {
  amount: number;
  currency?: string;
  showFractions?: boolean;
}

/**
 * Number Formatter component
 */
export const NumberFormatter = ({ amount, currency, showFractions }: Props) => {
  const formattedNumber = new Intl.NumberFormat('en-US', {
    currency,
    style: currency ? 'currency' : 'decimal',
    minimumFractionDigits: showFractions ? 2 : 0,
  }).format(amount);

  return <span>{formattedNumber}</span>;
};
