import React from 'react';
import styled from 'styled-components';
import { Match } from '@reach/router';

import { Colors, Dropdown, Easings, Grid, media, MediaQuery, TextSize } from '@topia.com/ui-kit';
import { Link, useContextPathSupport } from '@topia.com/context-path';

import { GuidePage } from '../types';
import { PageSearch } from './PageSearch';
import { useMetrics } from '../../../analytics';

interface Props {
  pages: GuidePage[];
}

/**
 * Guide top navigation
 */
export const TopNav = ({ pages }: Props) => {
  const { navigate } = useContextPathSupport();
  const { events } = useMetrics();

  return (
    <Wrapper>
      <Grid>
        <Grid.Item offset={1} size={8} mobileSize={6} mobileOffset={0}>
          <MediaQuery.Default>
            {pages.map(p => (
              <TabLink data-testid="guide-topnav-link" key={p.id} to={`/guide/${p.id}`}>
                {p.label}
              </TabLink>
            ))}
          </MediaQuery.Default>
          <MediaQuery.Mobile>
            <PageSelect pages={pages} />
          </MediaQuery.Mobile>
        </Grid.Item>
        <PageSearchWrapper size={3} mobileSize={6}>
          <PageSearch pages={pages} navigate={navigate} onSearch={events.Guide.SearchResult} />
        </PageSearchWrapper>
      </Grid>
    </Wrapper>
  );
};

const Wrapper = styled.nav`
  width: 100%;
  max-width: 1032px;
  margin: 0 auto;
  box-shadow: inset 0 -2px 0 0 ${Colors.DarkGray(0.08)};

  ${Grid} {
    align-items: center;
  }

  ${media.mobile`
    padding: 2px 0;
  `};
`;

const PageSearchWrapper = styled(Grid.Item)`
  display: flex;
  justify-content: flex-end;
`;

const TabLink = styled(Link)`
  display: inline-block;
  margin: 0 20px;
  padding: 16px 0;

  ${TextSize.Medium};
  color: ${Colors.NavyBlue()};
  opacity: 0.4;
  border-bottom: solid 2px transparent;
  transition: all 560ms ${Easings.ExpoOut};

  &:first-child {
    margin-left: 0;
  }

  &[aria-current] {
    opacity: 1;
    border-bottom-color: ${Colors.NavyBlue()};
  }

  &:hover {
    opacity: 1;
    text-decoration: none;
  }
`;

const PageDropdown = styled(Dropdown)`
  ${TextSize.Medium};
  padding-left: 24px;
  box-shadow: none;
  color: ${Colors.NavyBlue()};
`;

/**
 * Dropdown UI for switching pages
 */
export const PageSelect = (props: { pages: GuidePage[] }) => (
  <Match<{ page: string }> path="/guide/:page">
    {({ match, navigate }) =>
      match && (
        <PageDropdown
          options={props.pages.map(p => ({ value: p.id, label: p.label }))}
          value={match.page}
          onChange={event => navigate(event.target.value)}
        />
      )
    }
  </Match>
);
