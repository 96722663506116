import { useEffect } from 'react';
import { Events } from 'react-scroll';

import { useMetrics } from '../../../analytics';

/**
 * Track section views
 */
export function useSectionViewTracker() {
  const { events } = useMetrics();
  useEffect(() => {
    Events.scrollEvent.register('begin', to => events.Guide.SectionView(to));
    return () => Events.scrollEvent.remove('begin');
  }, []);
}
