import { useState } from 'react';
import ky, { HTTPError, Input, Options } from 'ky';

import config from '../../../config';
import { useMetrics } from '../../../analytics';

/**
 * Session becomes idle in
 */
export const IDLE_IN = 5 * 60 * 1000; // 5 minutes
// Maximum session length
const SESSION_LENGTH = 60 * 60 * 1000; // 60 minutes
// Session extend inverval
const SESSION_EXTEND_INTERVAL = 15 * 60 * 1000; // 15 minutes
// Expire extend interval amount earlier;
const EXPIRES_IN = SESSION_LENGTH - SESSION_EXTEND_INTERVAL;

/**
 * Module cached session extend promise for deduping and delaying new API requests
 */
let extendPromise: Promise<string> | undefined;

const client = ky.extend({
  prefixUrl: config.contextPath || '/',
});

/**
 * Expire session with logout
 */
const expireSession = (reason: 'inactivity' | 'unknown') => {
  window.location.href = `${config.contextPath}/auth/logout?reason=${reason}`;
};

const extendSession = () => {
  if (!extendPromise) extendPromise = client.post('auth/extend', { timeout: 30000 }).text();

  extendPromise
    .then(() => {
      extendPromise = undefined;
    })
    .catch(err => {
      extendPromise = undefined;
      if (err instanceof HTTPError && err.response.status === 401) {
        expireSession('unknown');
      } else {
        throw err;
      }
    });
};

/**
 * Ky hook for waiting out until session extend has completed
 */
export const waitForSessionExtendHook = async () => {
  await new Promise(resolve => setTimeout(resolve, 100));
  if (extendPromise) return extendPromise.then(() => undefined);
  return Promise.resolve();
};

/**
 * Ky hook for handling unauthenticated API calls
 */
export const handleFailedAuthenticationHook = (_i: Input, _: Options, response: Response) => {
  if (response.status === 401) {
    expireSession('unknown');
    // Stop further API call processing with pending promise
    return new Promise(() => undefined) as any;
  }

  return response;
};

/**
 * Hook to update session active/idle state
 */
export const useSessionExtension = () => {
  const { events } = useMetrics();
  const [expirationTimeout, setExpirationTimeout] = useState<number>();
  const [extendInterval, setExtendInterval] = useState<number>();

  return {
    onActive: () => {
      events.UserActivity.Active();
      // Start extending session periodically, stop any expiration timeout
      window.clearTimeout(expirationTimeout);
      setExtendInterval(window.setInterval(extendSession, SESSION_EXTEND_INTERVAL));
      extendSession();
    },
    onIdle: () => {
      events.UserActivity.Idle();
      // Stop extending session periodically, expire session at session end
      window.clearInterval(extendInterval);
      setExpirationTimeout(window.setTimeout(() => expireSession('inactivity'), EXPIRES_IN));
    },
  };
};
