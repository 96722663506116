import React from 'react';
import { Grid } from '@topia.com/ui-kit';
import styled from 'styled-components';

import { PeopleWidgetData, UrbanArea } from '../../types';
import { PopulationSection } from './Population';
import { DemographicsSection } from './Demographics';
import { LanguagesSection } from './Languages';

interface Props {
  urban_area: UrbanArea;
  population: PeopleWidgetData;
}

/**
 * People widget
 */
export const PeopleWidget = ({ population, urban_area }: Props) => (
  <>
    <PopulationSection {...population} urban_area={urban_area} />
    <DemographicsSection {...population} urban_area={urban_area} />
    <LanguagesSection {...population} />
  </>
);

/**
 * People widget rows
 */
export const Row = styled(Grid)`
  margin-bottom: 64px;
`;
