import { SelectComponentsConfig } from 'react-select';

import { ClearIndicator } from './ClearIndicator';
import { DropdownIndicator } from './DropdownIndicator';
import { IndicatorSeparator } from './IndicatorSeparator';
import { GroupHeading } from './GroupHeading';
import { MultiValue } from './MultiValue';
import { Option } from './Option';

/**
 * Component overrides
 */
export const components: () => SelectComponentsConfig<any> = () => ({
  MultiValue,
  IndicatorSeparator,
  ClearIndicator,
  DropdownIndicator,
  GroupHeading,
  Option,
});
