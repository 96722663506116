import React from 'react';
import styled, { css } from 'styled-components';
import { darken, shade, transparentize } from 'polished';

import { media } from '../MediaQuery';
import { Colors } from '../Colors';
import { FontWeight } from '../FontWeight';
import { Easings } from '../Easings';
import { TextSize } from '../TextSize';

/**
 * Custom button props
 */
export interface ButtonProps {
  /**
   * Large variant
   */
  large?: boolean;
  secondary?: boolean;
  warning?: boolean;
  color?: string;
}

const regularStyle = css`
  font-size: 14px;
  line-height: 32px;
  padding: 0 24px;
`;

const largeStyle = css`
  ${TextSize.Medium};
  line-height: 48px;

  padding: 0 40px;

  ${media.mobile`
    ${regularStyle};
  `}
`;

const primaryStyle = css`
  color: ${Colors.SeaShell()};
  background-color: ${Colors.NavyBlue()};

  &:hover {
    color: ${shade(0.08, Colors.SeaShell())};
  }

  &:focus {
    box-shadow: 0 0 0 4px ${Colors.NavyBlue(0.16)};
  }
`;

const defaultSecondaryStyle = css`
  color: ${Colors.NavyBlue()};
  background-color: ${(props: ButtonProps) => props.color || Colors.LightBlue()};

  &:hover {
    background-color: ${(props: ButtonProps) => darken(0.04, props.color || Colors.LightBlue())};
  }

  &:focus {
    box-shadow: 0 0 0 4px
      ${(props: ButtonProps) => transparentize(0.4, props.color || Colors.LightBlue())};
  }
`;

const warningSecondaryStyle = css`
  color: ${Colors.NavyBlue()};
  background-color: ${(props: ButtonProps) => props.color || Colors.Blonde()};

  &:hover {
    background-color: ${(props: ButtonProps) => shade(0.04, props.color || Colors.Blonde())};
  }

  &:focus {
    box-shadow: 0 0 0 4px
      ${(props: ButtonProps) => transparentize(0.4, props.color || Colors.Blonde())};
  }
`;

/**
 * Button style
 */
export const buttonStyle = css`
  font-weight: ${FontWeight.SemiBold};
  border-radius: 24px;
  text-align: center;
  border: none;
  cursor: pointer;
  transition: background-color 240ms ${Easings.ExpoOut}, color 240ms ${Easings.ExpoOut};

  ${(props: ButtonProps) => (props.large ? largeStyle : regularStyle)};
  ${(props: ButtonProps) => (props.secondary ? (props.warning ? warningSecondaryStyle : defaultSecondaryStyle) : primaryStyle)};

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.4;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    text-decoration: none;
  }
`;

const ButtonEl = React.forwardRef<
  HTMLButtonElement,
  ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>
>(({ large, secondary, warning, color, type = 'button', ...props }, ref) => (
  <button ref={ref} type={type} {...props} />
));

/**
 * Rounded button
 */
export const Button = styled(ButtonEl)`
  ${buttonStyle};
`;
