import React from 'react';
const SvgPlusIcon = React.forwardRef(
  (
    { color = '#003049', ...props }: React.SVGProps<SVGSVGElement>,
    ref: React.Ref<SVGSVGElement>,
  ) => (
    <svg height={16} viewBox="0 0 16 16" width={16} ref={ref} color={color} {...props}>
      <path d="M8.9 7.2H14v1.6H8.9V14H7.3V8.8H2V7.2h5.3V2h1.6z" fill="currentColor" />
    </svg>
  ),
);
export default SvgPlusIcon;
