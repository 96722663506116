import React from 'react';
import styled, { css } from 'styled-components';

import { Colors } from '../../Colors';
import { FontWeight } from '../../FontWeight';

import { SortDefinition, ColumnDefinition, RowAction, TableSizeVariant } from './types';
import { InternalTableHeader } from './InternalTableHeader';

/**
 * Basic static data table props
 */
export interface InternalTableProps<D> {
  data: D[];
  columns: ColumnDefinition<D>[];
  sort?: SortDefinition<D>;
  renderFooter?: () => React.ReactNode;
  renderSubcontent?: (row: D) => React.ReactNode;
  onRowClick?: (row: D) => void;
  onRowHover?: (row?: D) => void;
  rowActions: RowAction<D>[] | ((row: D) => RowAction<D>[]);
  size: TableSizeVariant;
  noBorders?: boolean;
}

/**
 * Table
 */
export const InternalTable = <D extends object>({
  data,
  columns,
  sort,
  renderFooter,
  renderSubcontent,
  size,
  noBorders = false,
  onRowClick,
  onRowHover,
  rowActions,
}: InternalTableProps<D>) => (
  <StyledTable
    size={size}
    rowClickable={typeof onRowClick === 'function'}
    rowActionPresent={typeof rowActions === 'function' || rowActions.length > 0}
    noBorders={noBorders}
    hasFooter={typeof renderFooter === 'function'}>
    <thead>
      <tr>
        {columns.map((column, idx) => (
          <InternalTableHeader key={idx} column={column} sort={sort} />
        ))}
      </tr>
    </thead>
    <tbody>
      {data.map((row, idx) => (
        <React.Fragment key={(row as any).id || idx}>
          <tr
            onMouseEnter={() => onRowHover && onRowHover(row)}
            onMouseLeave={() => onRowHover && onRowHover(undefined)}
            onClick={() => onRowClick && onRowClick(row)}>
            {columns.map((col, colIdx) =>
              'field' in col ? (
                <td key={col.field.toString()} align={col.align} style={col.cellStyle}>
                  {row[col.field]}
                </td>
              ) : (
                <td key={colIdx} align={col.align} style={col.cellStyle}>
                  {col.render(row, idx)}
                </td>
              ),
            )}
          </tr>

          {renderSubcontent && (
            <tr className="subcontent">
              <td colSpan={columns.length}>{renderSubcontent(row)}</td>
            </tr>
          )}
        </React.Fragment>
      ))}
    </tbody>
    {renderFooter && (
      <tfoot>
        <tr>
          <td align="right" colSpan={columns.length}>
            {renderFooter()}
          </td>
        </tr>
      </tfoot>
    )}
  </StyledTable>
);

interface TableElementProps {
  rowActionPresent: boolean;
  rowClickable: boolean;
  size: TableSizeVariant;
  noBorders: boolean;
  hasFooter?: boolean;
}

const StyledTable = styled.table<TableElementProps>`
  width: 100%;
  ${({ noBorders }) =>
    noBorders &&
    css`
      border-spacing: 0;

      && thead > tr,
      && tbody > tr,
      && tbody > tr:last-child {
        border: none;
      }
    `};

  th {
    font-weight: ${FontWeight.SemiBold};
  }

  th,
  td {
    height: 48px;
    padding: 4px;
    font-size: 14px;
    line-height: 1;
    white-space: nowrap;
    vertical-align: middle;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }

    ${({ size }) =>
      (size === 'compact' &&
        css`
          height: 32px;
          padding: 2px;
          font-size: 12px;
        `) ||
      (size === 'large' &&
        css`
          height: 56px;
          padding: 8px 4px;
        `)}
  }

  td:empty {
    height: auto;
    padding: 0;
  }

  th {
    padding: 8px 4px;

    ${({ size }) =>
      size === 'compact' &&
      css`
        padding: 8px 2px;
      `}
  }

  th:empty {
    padding: 0;
  }

  thead > tr {
    border-bottom: solid 2px ${Colors.DarkGray(0.06)};
  }

  tbody > tr {
    border-top: solid 1px ${Colors.DarkGray(0.04)};

    ${({ rowClickable }) =>
      rowClickable &&
      css`
        cursor: pointer;
      `};

    ${({ rowClickable, rowActionPresent }) =>
      (rowClickable || rowActionPresent) &&
      css`
        &:hover:not(.subcontent),
        &:hover:not(.subcontent) + tr {
          border-top-color: transparent;
        }

        &:hover:not(.subcontent) td {
          position: relative;
          background-color: ${Colors.Selection()};

          &:first-child::before,
          &:last-child::after {
            content: ' ';
            position: absolute;
            top: 0;
            background-color: ${Colors.Selection()};
            height: 100%;
          }

          &:first-child::before {
            left: -8px;
            border-top-left-radius: 9px;
            border-bottom-left-radius: 9px;
            width: 8px;
          }

          &:last-child::after {
            right: ${rowActionPresent ? '-48px' : '-8px'};
            border-top-right-radius: 9px;
            border-bottom-right-radius: 9px;
            width: ${rowActionPresent ? '48px' : '8px'};
            z-index: -1;
          }
        }
      `};

    &.subcontent {
      border-top: none;
    }

    &.subcontent > td {
      padding: 0;
    }

    &:first-child {
      border-top: none;
    }

    &:last-child {
      border-bottom: solid 2px ${({ hasFooter }) => Colors.DarkGray(hasFooter ? 1 : 0.08)};
    }
  }
`;
