import React from 'react';
import PageTitle from '@topia.com/ui-kit/PageTitle';
import { RouteComponentProps } from '@reach/router';
import styled from 'styled-components';
import { media, ErrorBoundary } from '@topia.com/ui-kit';

import { useSession } from '../application/api';

import { PlannerMode } from './types';
import { OfficeProvider } from './components/OfficeProvider';
import { Planner } from './components/Planner';
import { MapboxGlCSS } from './components/Map/Wrapper';
import { defaultPlannerSettings } from './utils/defaults';
import { NAV_HEIGHT } from '../application/components/Nav/Header';
import { useFullAppWidth, useWithoutFooter } from '../../shared/hooks';
import { useMetrics } from '../../analytics';

interface Props extends RouteComponentProps<{ mode: PlannerMode }> {
  editableOffice?: boolean;
  className?: string;
}

/**
 * Neighborhoods view - provides the planner metadata and offices
 */
const NeighborhoodsView = ({
  mode = PlannerMode.Score,
  className,
  editableOffice = false,
}: Props) => {
  const { neighborhoods } = useSession();
  const { events } = useMetrics();
  if (!neighborhoods) throw new Error('Neighborhoods view is disabled');

  useFullAppWidth();
  useWithoutFooter();

  return (
    <ErrorBoundary>
      <Wrapper className={className}>
        <PageTitle>Neighborhoods</PageTitle>
        <MapboxGlCSS />
        <OfficeProvider bbox={neighborhoods.bbox} editableOffice={editableOffice}>
          {offices => (
            <Planner
              initialSettings={defaultPlannerSettings(neighborhoods)}
              mode={mode}
              meta={neighborhoods}
              offices={offices}
              onPinSet={events.NeighborhoodsPlanner.PinSet}
              onSettingsUpdated={events.NeighborhoodsPlanner.SettingsUpdate}
              onFeatureHover={events.NeighborhoodsPlanner.FeatureHover}
            />
          )}
        </OfficeProvider>
      </Wrapper>
    </ErrorBoundary>
  );
};

export default NeighborhoodsView;

const Wrapper = styled.div`
  overflow: hidden;

  width: 100vw;
  height: 100vh;
  margin-top: -${NAV_HEIGHT}px;

  ${media.mobile`
    position: relative;
    margin-top: 0;
    height: calc(100vh - 48px);
  `}
`;
