import React from 'react';
const SvgSearchIcon = React.forwardRef(
  (
    { color = '#003049', ...props }: React.SVGProps<SVGSVGElement>,
    ref: React.Ref<SVGSVGElement>,
  ) => (
    <svg height={24} viewBox="0 0 24 24" width={24} ref={ref} color={color} {...props}>
      <path
        d="M15.717 14.303l5.172 5.172-1.414 1.414-5.172-5.172a7 7 0 111.414-1.414zm-5.606.808a5 5 0 100-10 5 5 0 000 10z"
        fill="currentColor"
      />
    </svg>
  ),
);
export default SvgSearchIcon;
