import { MultiSelectProps, GroupedOption } from './MultiSelect';

/**
 * Are options grouped?
 */
export const isGrouped = (props: MultiSelectProps<any>) =>
  !!(props.options && props.options.length > 0 && (props.options[0] as GroupedOption).options);

/**
 * Does select load options asynchronously?
 */
export const isAsync = (props: MultiSelectProps<any>) => !!props.loadOptions;

/**
 * Validate prop combinations
 */
export const validateProps = (props: MultiSelectProps<any>) => {
  if ((!props.options && !props.loadOptions) || (props.options && props.loadOptions)) {
    throw new Error('Either options or loadOptions must be specified');
  }

  if (isAsync(props)) {
    if (!props.initialOptions) {
      throw new Error('Asynchronous select must have initial options');
    }

    if (!props.searchField) {
      throw new Error('Asynchronous select must be a search field');
    }
  }

  if (props.addSelectAllOption) {
    if (isAsync(props)) {
      throw new Error('Asynchronous select cannot have Select All option');
    }

    if (isGrouped(props)) {
      throw new Error('Grouped options cannot have Select All option');
    }

    if (props.searchField) {
      throw new Error('Search field cannot have Select All option');
    }

    if (props.singleValue) {
      throw new Error('Single-value select cannot have Select All option');
    }
  }

  if (props.singleValue) {
    if (Array.isArray(props.value)) {
      throw new Error('Single-value select cannot have an array of selected values');
    }
  } else {
    if (!Array.isArray(props.value)) {
      throw new Error('Multi-value select must have an array of selected values');
    }
  }
};
