/**
 * Generate a color function
 */
const colorFn = (r: number, g: number, b: number): ((opacity?: number) => string) => (
  opacity = 1,
) => `rgba(${r}, ${g}, ${b}, ${opacity})`;

/**
 * Topia color set
 */
export const TopiaColors = {
  DarkGray: colorFn(35, 31, 32),
  NavyBlue: colorFn(0, 48, 73),
  SkyBlue: colorFn(111, 154, 199),
  LightGray: colorFn(217, 220, 212),
  LightBlue: colorFn(188, 220, 245),
  SeaShell: colorFn(241, 204, 188),
  Orange: colorFn(248, 128, 1),
  YellowSoda: colorFn(253, 210, 70),
  Blonde: colorFn(246, 221, 159),
};

/**
 * UI related colors
 */
export const UIColors = {
  White: colorFn(255, 255, 255),
  Red: colorFn(238, 34, 41),
  Green: colorFn(111, 194, 56),
};

/**
 * Utility colors
 */
export const UtilityColors = {
  Selection: () => TopiaColors.DarkGray(0.04),
  Separator: () => TopiaColors.DarkGray(0.08),
  Disabled: () => TopiaColors.DarkGray(0.4),
  SecondaryGray: () => TopiaColors.DarkGray(0.4),
  TertiaryGray: () => TopiaColors.DarkGray(0.24),
};

/**
 * Topia colors
 */
export const Colors = {
  ...TopiaColors,
  ...UIColors,
  ...UtilityColors,
};
