import React from 'react';
import styled from 'styled-components';
import { Element } from 'react-scroll';
import { Colors, Grid, media, TextSize, FontWeight, ErrorBoundary } from '@topia.com/ui-kit';

import { GuideSection, SectionType } from '../types';
import { PhotoGrid } from './PhotoGrid';
import { Article } from './Article';
import { WidgetWrapper } from './WidgetWrapper';

/**
 * Linkable guide section
 */
export const Section = (section: GuideSection) => (
  <Element id={section.id} name={section.id}>
    <PhotoGrid images={section.beforeImages} />
    <Wrapper>
      <Title>{section.label}</Title>
      {section.type === SectionType.Text &&
        section.articles.map(article => (
          <Article
            key={article.id}
            noTitle={section.articles.length === 1 && section.label === article.label}
            article={article}
          />
        ))}
      <ErrorBoundary inline>
        {section.type === SectionType.Widget && <WidgetWrapper {...section} />}
      </ErrorBoundary>
    </Wrapper>
    <PhotoGrid images={section.afterImages} />
  </Element>
);

const Wrapper = styled.div`
  position: relative;
  margin: 0 auto;
  padding: 0 24px;
  max-width: 1080px;
`;

const Title = styled.h2`
  ${TextSize.ExtraLarge};
  font-weight: ${FontWeight.Bold};
  color: ${Colors.NavyBlue()};

  margin-top: 64px;
  margin-bottom: 48px;

  ${media.mobile`
    margin-top: 54px;
    margin-bottom: 32px;
  `};

  & + ${/* sc-selector */ Grid} .title:first-child {
    margin-top: 0;
  }
`;
