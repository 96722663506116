import { NavigateOptions } from '@reach/router';
import Downshift, { DownshiftInterface } from 'downshift';
import Fuse from 'fuse.js';
import React from 'react';
import { scroller } from 'react-scroll';
import styled from 'styled-components';

import { Colors, Easings, media, SearchInput, TextSize } from '@topia.com/ui-kit';
import { BORDER_RADIUS, DEFAULT_BOX_SHADOW } from '@topia.com/ui-kit/variables';

import { GuidePage } from '../types';
import { extractSearchableItems } from '../utils/search';

interface Props {
  pages: GuidePage[];
  navigate: (to: string, options?: NavigateOptions<{}>) => Promise<void>;
  onSearch: (search: string) => void;
}

interface State {
  results: SearchResult[];
}

interface SearchResult {
  id: string;
  page: string;
  label: string;
  humanLabel: string;
  content?: string;
}

const TypedDownshift: DownshiftInterface<SearchResult> = Downshift;

/**
 * Mapbox geocoder API based address autocomplete
 */
export class PageSearch extends React.PureComponent<Props, State> {
  inputRef = React.createRef<HTMLInputElement>();
  fuse!: Fuse<SearchResult>;
  state: State = { results: [] };

  componentDidMount() {
    const searchItems = extractSearchableItems(this.props.pages);
    this.fuse = new Fuse<SearchResult>(searchItems, {
      minMatchCharLength: 3,
      shouldSort: true,
      distance: 10000,
      keys: [{ name: 'label', weight: 0.7 }, { name: 'content', weight: 0.3 }],
    });
  }

  handleQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const results = this.fuse.search(event.target.value).slice(0, 6);
    this.setState({ results });
  };

  goTo = (result: SearchResult | null) => {
    if (!result) return;
    this.props.onSearch(result.humanLabel);
    void this.props.navigate(`/guide/${result.page}`, { replace: true }).then(() => {
      setTimeout(() => scroller.scrollTo(result.id, { smooth: 'easeOutQuint', offset: -50 }), 10);
    });
  };

  render() {
    const { results } = this.state;

    return (
      <TypedDownshift
        onChange={this.goTo}
        onSelect={(item, { clearSelection }) => {
          if (item) clearSelection();
          if (this.inputRef.current) this.inputRef.current.blur();
        }}
        itemToString={item => (item == null ? '' : item.humanLabel)}
        defaultHighlightedIndex={0}>
        {({
          clearSelection,
          getRootProps,
          getInputProps,
          getMenuProps,
          getItemProps,
          isOpen,
          openMenu,
          highlightedIndex,
        }) => (
          <SearchContainer {...getRootProps()}>
            <SearchBox
              {...getInputProps({
                ref: this.inputRef,
                onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                  if (!event.target.value) {
                    clearSelection();
                    return this.setState({ results: [] });
                  }
                  this.handleQueryChange(event);
                },
                onFocus: (event: React.FocusEvent<HTMLInputElement>) => {
                  openMenu();
                  event.target.select(); // Select whole input text on focus
                },
                placeholder: 'Search...',
              } as any)}
            />
            <SearchResults {...getMenuProps()}>
              {isOpen &&
                results.map((item, index) => (
                  <SearchItem
                    key={item.id + index}
                    {...getItemProps({ item, index })}
                    highlighted={highlightedIndex === index}>
                    {item.humanLabel}
                  </SearchItem>
                ))}
            </SearchResults>
          </SearchContainer>
        )}
      </TypedDownshift>
    );
  }
}

const SearchContainer = styled.div`
  display: inline-block;
  position: relative;
`;

const SearchBox = styled(SearchInput)`
  input {
    ${TextSize.Medium};

    width: 100%;
    box-shadow: none;

    ${media.mobile`
      transition: width 240ms ${Easings.ExpoOut};
      width: 40px;
      padding-right: 0;
      margin-right: 8px;

      &:focus {
        width: 100vw;
        padding-right: 6px;
        margin-right: 0;
      }
    `};
  }
`;

const SearchResults = styled.ul`
  position: absolute;
  top: calc(100% + 8px);
  z-index: 10;
  width: 100%;
  margin: 0;
  padding-left: 0;
  border-radius: ${BORDER_RADIUS};

  list-style-type: none;
  background-color: ${Colors.White()};
  box-shadow: ${DEFAULT_BOX_SHADOW};
`;

const SearchItem = styled.li`
  padding: 4px 12px;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
  background-color: ${(props: { highlighted: boolean }) =>
    props.highlighted ? Colors.DarkGray(0.04) : Colors.White()};

  &:first-child {
    margin-top: 8px;
  }

  &:last-child {
    margin-bottom: 8px;
  }
`;
