import React from 'react';
const SvgCheckIcon = React.forwardRef(
  (
    { color = '#003049', ...props }: React.SVGProps<SVGSVGElement>,
    ref: React.Ref<SVGSVGElement>,
  ) => (
    <svg height={24} viewBox="0 0 24 24" width={24} ref={ref} color={color} {...props}>
      <path
        d="M21.314 5.757l1.414 1.415L9.172 20.728l-7.9-7.9 1.414-1.414L9.172 17.9z"
        fill="currentColor"
      />
    </svg>
  ),
);
export default SvgCheckIcon;
