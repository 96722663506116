import React from 'react';
import styled from 'styled-components';

import { Button, Colors, FontWeight, Grid, media, TextSize } from '@topia.com/ui-kit';
import { useContextPathSupport } from '@topia.com/context-path';

/**
 * Neighborhood Planner button widget
 */
export const NeighborhoodButtonWidget = () => {
  const { navigate } = useContextPathSupport();

  return (
    <Grid>
      <Grid.Item size={7} mobileSize={12}>
        <Wrapper>
          <Message>
            <b>Discover the neighborhoods that fit you best.</b>
          </Message>
          <Button large secondary onClick={() => navigate('/neighborhoods')}>
            Open Neighborhood Explorer
          </Button>
        </Wrapper>
      </Grid.Item>
    </Grid>
  );
};

const Wrapper = styled.div`
  background-color: ${Colors.DarkGray(0.04)};
  padding: 40px;

  ${media.mobile`
    margin-left: -24px;
    margin-right: -24px;
  `}

  b {
    font-weight: ${FontWeight.SemiBold};
  }
`;

const Message = styled.div`
  ${TextSize.Large};
  color: ${Colors.NavyBlue()};
  margin-bottom: 32px;
  max-width: 420px;
`;
