import memoize from 'lodash/memoize';

import { CityMetadata, CommuteMode, RentBedroomSize, Tradeoff } from '../types';

/**
 * Default planner settings
 */
export const defaultPlannerSettings = memoize(({ commute, rent }: CityMetadata) => {
  // Pick first available commute mode if driving is not there
  const commuteMode = commute.available.includes(CommuteMode.Driving)
    ? CommuteMode.Driving
    : commute.available[0];

  // Pick first available bedroom size if studio is not there
  const bedroomSize = rent.available.includes(RentBedroomSize.Studio)
    ? RentBedroomSize.Studio
    : rent.available[0];

  const commuteLimits = commute.limits[commuteMode];
  const rentLimits = rent.limits[bedroomSize];

  return {
    commuteMode,
    bedroomSize,
    tradeoff: Tradeoff.Balanced,
    activeOffice: undefined,
    maxCommute: (commuteLimits.max + commuteLimits.min) / 2, // 50%
    maxRent: Math.round((rentLimits.max + rentLimits.min) / 2), // 50%
  };
});
