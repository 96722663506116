import React from 'react';
import styled from 'styled-components';
import { Colors, FontWeight, Dropdown, TextSize } from '@topia.com/ui-kit';

/**
 * Small borderless dropdown
 */
export const SmallDropdown = styled(Dropdown)`
  ${TextSize.Small};
  font-weight: ${FontWeight.SemiBold};
  max-width: 200px;
  box-shadow: none;
  direction: rtl;
  background-position-x: 100%;
  margin-right: -6px;

  option {
    direction: ltr;
  }
`;

const SectionWrapper = styled.section`
  margin-top: 16px;
  padding-top: 16px;
  border-top: solid 1px ${Colors.DarkGray(0.08)};
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-wrap: wrap;

  &:first-child:not(:only-child) {
    border-top: none;
    margin-top: 0;
    padding-top: 0;
  }
`;

const Title = styled.label`
  ${TextSize.Medium};
  font-weight: ${FontWeight.SemiBold};
  margin: 0;
  color: ${Colors.NavyBlue()};
`;

/**
 * Section details
 */
export const SectionDetails = styled.div`
  margin-top: 8px;
  width: 100%;
`;

interface SectionProps {
  title: string;
  testId?: string;
}

/**
 * Settings section
 */
export const Section: React.FunctionComponent<SectionProps> = props => (
  <SectionWrapper data-testid={props.testId}>
    <Title>{props.title}</Title>
    {props.children}
  </SectionWrapper>
);
