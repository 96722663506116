import React, { useState } from 'react';
import styled from 'styled-components';
import { Grid, Dropdown, Colors } from '@topia.com/ui-kit';

import { PeopleWidgetData, UrbanArea } from '../../types';
import { Label, LargeFigure } from '../WidgetComponents';
import { Row } from '.';
import { formatNumber, formatPercentage } from '../../utils/formatting';
import { PopulationChart } from './PopulationChart';
import { recentPopulationGrowth } from '../../utils/widget';

interface Props extends PeopleWidgetData {
  urban_area: UrbanArea;
}

/**
 * Population section in people widget
 */
export const PopulationSection = ({
  population,
  density_over_ua,
  all_uas_population_estimates: populationEstimates,
  urban_area,
}: Props) => {
  const [firstComparison, setFirstComparison] = useState('');
  const [secondComparison, setSecondComparison] = useState('');

  if (!populationEstimates) return null;
  const uaList = populationEstimates
    .map(p => ({ value: p.slug, label: p.name }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const growth = recentPopulationGrowth(urban_area.slug, populationEstimates);

  return (
    <>
      <Row>
        {population && (
          <Grid.Item size={4} mobileSize={12}>
            <Label>Population</Label>
            <LargeFigure>{formatNumber(population.ua.value)}</LargeFigure>
          </Grid.Item>
        )}
        {growth && (
          <Grid.Item size={2} mobileSize={6}>
            <Label>
              {growth.fromYear}-{growth.toYear} growth
            </Label>
            <LargeFigure>{formatPercentage(growth.percentage)}</LargeFigure>
          </Grid.Item>
        )}
        {density_over_ua && (
          <Grid.Item size={2} mobileSize={6}>
            <Label>Density per km²</Label>
            <LargeFigure>{formatNumber(density_over_ua.ua.value)}</LargeFigure>
          </Grid.Item>
        )}
      </Row>
      <Row>
        <Grid.Item size={5} mobileSize={12}>
          <PopulationChart
            populationEstimates={populationEstimates}
            current={urban_area.slug}
            firstComparison={firstComparison}
            secondComparison={secondComparison}
          />
        </Grid.Item>
        <Grid.Item size={3} mobileSize={12}>
          <ComparisonCity>
            <ComparisonCityLabel>{urban_area.city}</ComparisonCityLabel>
            <Dot color={Colors.SkyBlue()} />
          </ComparisonCity>
          <ComparisonCity>
            <Dropdown
              value={firstComparison}
              placeholder="Compare to..."
              onChange={event => setFirstComparison(event.target.value)}
              options={uaList}
            />
            <Dot color={Colors.YellowSoda()} />
          </ComparisonCity>
          <ComparisonCity>
            <Dropdown
              value={secondComparison}
              placeholder="Compare to..."
              onChange={event => setSecondComparison(event.target.value)}
              options={uaList}
            />
            <Dot color={Colors.Green()} />
          </ComparisonCity>
        </Grid.Item>
      </Row>
    </>
  );
};

const ComparisonCity = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;

  select {
    width: 100%;
  }
`;

const Dot = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 100%;
  margin-left: 16px;
  flex-shrink: 0;
  background-color: ${(props: { color: string }) => props.color};
`;

const ComparisonCityLabel = styled.span`
  display: inline-block;
  padding-left: 12px;
`;
