/**
 * Widget API compound response
 */
export interface WidgetsResponse {
  urban_area: UrbanArea;
  ib_schools: SchoolsData | null;
  housing: {};
  population: {};
  living_costs: CostsWidgetData | null;
  video: VideoWidgetData | null;
  weather: WeatherWidgetData | null;
  high_schools: HighSchoolsData | null;
  universities: UniversitiesData | null;
  neighborhoods: true | null;
}

/**
 * Image manifest response
 */
export interface ManifestResponse {
  [key: string]: string[] | undefined;
}

/**
 * Article in a guide section
 */
export interface GuideArticle {
  id: string;
  label: string;
  content: string;
}

/**
 * List of widgets
 */
export enum Widgets {
  Video = 'video',
  Weather = 'weather',
  Costs = 'costs',
  People = 'people',
  NeighborhoodButton = 'neighborhood-button',
  Education = 'schools-universities',
}

interface Currency {
  code: string;
  symbol: string;
  rate_per_USD: number;
  name: string;
}

/**
 * Urban Area response
 */
export interface UrbanArea {
  id: string;
  city: string;
  continent: string;
  country: string;
  country_code_iso2: string;
  country_code_iso3: string;
  display_label: string;
  full_name: string;
  geonames_id: string;
  latitude: number;
  longitude: number;
  mayor: string;
  slug: string;
  state?: string;
  state_code?: string;
  bounding_box: {
    west: number;
    east: number;
    north: number;
    south: number;
  };
}

/**
 * Video widget
 */
export interface VideoWidgetData {
  description: string;
  title: string;
  author: string;
  date: string;
  image_url: string;
  duration: number;
  url: string;
  site: string;
  id: string;
  src: string;
  embed: string | { html: string };
}

/**
 * Weather widget
 */
export interface WeatherWidgetData {
  temperature_avg_high_monthly: {
    fahrenheit: number[];
    celsius: number[];
  };
  sunshine_hours_per_year: number;
  climate_type: string;
  custom_metric: {
    value: string;
    label: string;
  };
}

/**
 * Statistics values for different population values
 */
export interface PopulationStats {
  stats: {
    count: number;
    max: number;
    min: number;
    median: number;
    mean: number;
  };
  ua: {
    ranking: number;
    value: number;
  };
}

/**
 * 5-year population estimates for UA
 */
export interface UAPopulationEstimate {
  slug: string;
  name: string;
  population?: { [year: string]: number };
}

/**
 * People widget
 */
export interface PeopleWidgetData {
  life_expectancy?: PopulationStats;
  english_skills?: PopulationStats;
  density_ua_center?: PopulationStats;
  unemployment?: PopulationStats;
  density_over_ua?: PopulationStats;
  median_age?: PopulationStats;
  elderly?: PopulationStats;
  gdp_per_capita?: PopulationStats;
  population?: PopulationStats;
  spoken_languages?: string[];
  all_uas_population_estimates?: UAPopulationEstimate[];
}

/**
 * Costs widget
 */
export interface CostsWidgetData {
  living_costs: {
    detail: {
      this: {
        [id: string]: number;
      };
      labels: {
        [id: string]: string;
      };
      others: {
        [ua: string]: {
          [id: string]: number;
        };
      };
    };
    blurb: string;
    currency: Currency;
  };
  housing: {
    ranges: {
      [type: string]: {
        max: number;
        mean: number;
        min: number;
      };
    };
    selected: string;
    currency: Currency;
  };
}

interface University {
  name: string;
  rank: number;
  subject_rankings?: {
    [key: string]: number;
  };
  area_scores: {
    [key: string]: number;
  };
  students: {
    total: number;
    international_percent: number;
  };
}

/**
 * Universities data for education widget
 */
export interface UniversitiesData {
  universities: University[];
  ua_rank: number;
  total_in_rank: number;
}

interface HighSchoolSubject {
  proficiency: {
    bottom_percent: number;
    top_percent: number;
  };
  scores: {
    ua_rank: number;
    total_in_rank: number;
    mean: number;
    all_uas: {
      ranking: number;
      mean: number;
      slug: string;
      name: string;
    }[];
  };
}

/**
 * Country wide pisa score
 */
export interface PisaScore {
  ua_rank: number;
  total_in_rank: number;
}

/**
 * High scools data for education widget
 */
export interface HighSchoolsData {
  score: PisaScore;
  pisa: {
    subjects: {
      math: HighSchoolSubject;
      reading: HighSchoolSubject;
      science: HighSchoolSubject;
    };
    happiness: {
      ua_rank: number;
      responders_percent: number;
      total_in_rank: number;
    };
  };
}

/**
 * IB Schools data for education widget
 */
export interface SchoolsData {
  count: number;
  languages: { [language: string]: number };
  programs: { [program: string]: number };
  url: string;
}

/**
 * Education widget data
 */
export interface EducationWidgetData {
  ib_schools?: SchoolsData;
  universities?: UniversitiesData;
  high_schools?: HighSchoolsData;
}

/**
 * Neighborhood button widget data
 */
export interface NeighborhoodButtonWidgetData {
  neighborhoods: true;
}

/**
 * Section types
 */
export enum SectionType {
  Text = 'text',
  Widget = 'widget',
}

interface BaseSection {
  type: SectionType;
  label?: string;
  beforeImages: string[];
  afterImages: string[];
}

interface TextSection extends BaseSection {
  id: string;
  type: SectionType.Text;
  articles: GuideArticle[];
}

interface VideoWidget extends BaseSection {
  id: Widgets.Video;
  type: SectionType.Widget;
  data: {
    video: VideoWidgetData;
  };
}

interface WeatherWidget extends BaseSection {
  id: Widgets.Weather;
  type: SectionType.Widget;
  data: {
    weather: WeatherWidgetData;
  };
}

interface PeopleWidget extends BaseSection {
  id: Widgets.People;
  type: SectionType.Widget;
  data: {
    population: PeopleWidgetData;
    urban_area: UrbanArea;
  };
}

interface CostsWidget extends BaseSection {
  id: Widgets.Costs;
  type: SectionType.Widget;
  data: CostsWidgetData;
}

interface EducationWidget extends BaseSection {
  id: Widgets.Education;
  type: SectionType.Widget;
  data: EducationWidgetData;
}

interface NeighborhoodButtonWidget extends BaseSection {
  id: Widgets.NeighborhoodButton;
  type: SectionType.Widget;
  data: NeighborhoodButtonWidgetData;
}

/**
 * Widget section in a page
 */
export type WidgetSection =
  | VideoWidget
  | WeatherWidget
  | PeopleWidget
  | CostsWidget
  | EducationWidget
  | NeighborhoodButtonWidget;

/**
 * Section in a guide page
 */
export type GuideSection = TextSection | WidgetSection;

/**
 * Transformed guide page
 */
export interface GuidePage {
  id: string;
  label: string;
  sections: GuideSection[];
}

/**
 * Transformed guide API response
 */
export interface GuidePages {
  header: {
    title: string;
    imageUrl: string;
  };
  pages: GuidePage[];
}

/**
 * Guide mapping object
 */
export interface GuideMapping {
  pages: {
    id: string;
    label: string;
    sections: {
      id: string;
      label?: string;
      content_blocks?: { category: string; subcategory: string }[];
      widget?: boolean;
      header_image_count?: number;
      footer_image_count?: number;
      linked_to?: { category: string; subcategory: string };
    }[];
  }[];
}
