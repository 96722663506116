import React from 'react';

import { EducationWidgetData } from '../../types';
import { HighSchoolsSection } from './HighSchools';
import { IBSchoolsSection } from './IBSchools';
import { UniversitiesSection } from './Universities';

/**
 * Education widget
 */
export const EducationWidget = ({
  high_schools,
  ib_schools,
  universities,
}: EducationWidgetData) => (
  <>
    {high_schools && <HighSchoolsSection {...high_schools} />}
    {ib_schools && <IBSchoolsSection {...ib_schools} />}
    {universities && <UniversitiesSection {...universities} />}
  </>
);
