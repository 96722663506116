import { Colors, FontWeight, TextSize } from '@topia.com/ui-kit';
import styled from 'styled-components';

/**
 * Data figure label
 */
export const Label = styled.div`
  ${TextSize.Small};
  font-weight: ${FontWeight.SemiBold};
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;

  &:first-child {
    margin-bottom: 8px;
  }

  &:last-child {
    margin-top: 8px;
  }

  &:only-child {
    margin-top: 0;
  }
`;

/**
 * Subsection title
 */
export const Subtitle = styled.div`
  ${TextSize.Medium};
  font-weight: ${FontWeight.Bold};
  color: ${Colors.NavyBlue()};
  margin-top: 88px;
  margin-bottom: 32px;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

/**
 * Large figure value
 */
export const LargeFigure = styled.div`
  ${TextSize.ExtraLarge};
  font-weight: ${FontWeight.Bold};
  color: ${Colors.SkyBlue()};

  display: flex;
  align-items: center;
`;

interface ComparisonGraphProps {
  min: number;
  max: number;
  value: number;
}

/**
 * Tiny comparison graph
 */
export const ComparisonGraph = ({ min, max, value }: ComparisonGraphProps) => (
  <svg width="64px" height="12px" viewBox="0 0 64 12">
    <rect x="0" y="5" width="64" height="2" fill={Colors.LightBlue()} />
    <rect
      x={64 - Math.round(((value - min) / (max - min)) * 64)}
      y="0"
      width="2"
      height="12"
      fill={Colors.NavyBlue()}
    />
  </svg>
);
