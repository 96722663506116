import { createGlobalStyle } from 'styled-components';
import { Colors, TextSize } from '@topia.com/ui-kit';

/**
 * Global styles
 */
export default createGlobalStyle`
  * {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }

  html,
  body {
    font-family: 'Inter', sans-serif;
    width: 100%;
    height: 100%;
    color: ${Colors.DarkGray()};
    ${TextSize.Default};
  }

  a {
    cursor: pointer;
    color: ${Colors.SkyBlue()};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  #app {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    max-width: 1600px;
    margin: 0 auto;

    &.full-width {
      max-width: 100%;
    }
  }

  [data-reach-menu] {
    z-index: 100;
  }

  .react-hot-loader-error-overlay > div {
    z-index: 100;
  }
`;
