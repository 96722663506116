import React from 'react';
import { MultiValueProps } from 'react-select';
import styled, { css } from 'styled-components';

import { Tag } from '../../Tag';
import { Colors } from '../../../Colors';

/**
 * Multi-select multi-value component
 */
export const MultiValue = (props: MultiValueProps<any>) => (
  <Root
    onRemove={props.isDisabled ? undefined : () => props.removeProps.onClick(undefined)}
    disabled={props.isDisabled}>
    {props.children}
  </Root>
);

const Root = styled(Tag)<{ disabled: boolean }>`
  margin-right: 4px;
  margin-top: 2px;
  margin-bottom: 2px;

  ${props =>
    props.disabled &&
    css`
      color: ${Colors.Disabled()};
    `}
`;
