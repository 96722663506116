import React from 'react';
import { Grid, Colors } from '@topia.com/ui-kit';
import styled from 'styled-components';

import { SchoolsData } from '../../types';
import { Label, LargeFigure, Subtitle } from '../WidgetComponents';

/**
 * IBSchools section in education widget
 */
export const IBSchoolsSection = ({ count, programs, languages }: SchoolsData) => (
  <>
    <Subtitle>IB Schools</Subtitle>
    <Grid>
      <Grid.Item size={2} mobileSize={4}>
        <LargeFigure>{count}</LargeFigure>
        <Label>Schools in total</Label>
      </Grid.Item>
      <ProgrammeTable size={6} mobileSize={8}>
        <Grid columns={6}>
          <Grid.Item size={3}>
            <Label>Programme</Label>
          </Grid.Item>
          <Grid.Item size={3}>
            <Label>Number of schools</Label>
          </Grid.Item>
        </Grid>
        {Object.entries(programs).map(([programme, schoolCount]) => (
          <Grid key={programme} columns={6}>
            <ProgrammeField>{programme}</ProgrammeField>
            <ProgrammeField>{schoolCount}</ProgrammeField>
          </Grid>
        ))}
        <Grid columns={6}>
          <ProgrammeField>Language of Instruction:</ProgrammeField>
          <ProgrammeField>
            {Object.entries(languages)
              .map(([language, score]) => `${language} (${score})`)
              .join(', ')}
          </ProgrammeField>
        </Grid>
      </ProgrammeTable>
    </Grid>
  </>
);

const ProgrammeTable = styled(Grid.Item)`
  ${Grid} {
    border-bottom: solid 1px ${Colors.DarkGray(0.04)};

    &:first-child,
    &:nth-last-child(2) {
      border-bottom: solid 2px ${Colors.DarkGray(0.08)};
    }

    &:last-child {
      border-bottom: none;
    }
  }

  ${/* sc-selector */ Grid.Item}:last-child {
    justify-self: flex-end;
  }
`;

const ProgrammeField = styled(Grid.Item).attrs({
  size: 3,
})`
  padding: 7px 0;
`;
