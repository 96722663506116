import { useEffect, useRef, useCallback } from 'react';

const sanitizeKey = (key: string) => key.toLowerCase().replace(' ', 'space');

const areKeysPressed = (keys: string[] = [], pressedKeys: string[] = []) => {
  if (keys.length !== pressedKeys.length) return false;
  const keysToCheck = new Set(keys);
  pressedKeys.forEach(key => keysToCheck.delete(key));
  return keysToCheck.size === 0;
};

/**
 * Check if key or key combination (escape + v) is pressed
 */
export function useKeyPress(target: string[] | string, callback: () => void) {
  const pressedKeysRef = useRef(new Set<string>([]));
  const params = useRef<{ targetKeys: string[]; callback: () => void }>({
    targetKeys: [],
    callback: () => undefined,
  });
  const timeoutRef = useRef<number>(0);

  // Keys like space might never trigger keyup in inputs
  const clearSpecialKeys = () => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      pressedKeysRef.current.clear();
    }, 1000);
  };

  const targetKeys = typeof target === 'string' ? [target] : target;

  useEffect(() => {
    params.current = { callback, targetKeys };
  }, [...targetKeys, callback]);

  const downHandler = useCallback((e: KeyboardEvent) => {
    if (e.key === undefined) {
      return;
    }

    pressedKeysRef.current.add(sanitizeKey(e.key));

    const hasPressedCombo = areKeysPressed(
      params.current.targetKeys,
      Array.from(pressedKeysRef.current),
    );

    if (hasPressedCombo) params.current.callback();

    clearSpecialKeys();
  }, []);

  const upHandler = useCallback((e: KeyboardEvent) => {
    if (e.key === undefined) {
      return
    }

    pressedKeysRef.current.delete(sanitizeKey(e.key));
    clearSpecialKeys();
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);

    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, [downHandler, upHandler]);
}
