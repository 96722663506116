import React from 'react';
import styled from 'styled-components';
import { Colors } from '@topia.com/ui-kit';
import PlusIcon from '@topia.com/ui-kit/icons/PlusIcon';
import MinusIcon from '@topia.com/ui-kit/icons/MinusIcon';
import { BORDER_RADIUS } from '@topia.com/ui-kit/variables';

interface Props {
  onClickZoomIn: () => void;
  onClickZoomOut: () => void;
}

/**
 * Styled zoom control
 */
export const ZoomControl = React.memo((props: Props) => (
  <Container>
    <Button aria-label="Zoom in" onClick={props.onClickZoomIn}>
      <PlusIcon />
    </Button>
    <Button aria-label="Zoom out" onClick={props.onClickZoomOut}>
      <MinusIcon />
    </Button>
  </Container>
));

const Container = styled.div`
  display: flex;
  flex-direction: column;

  position: absolute;
  bottom: 32px;
  right: 32px;
  z-index: 10;
  padding: 2px;

  box-shadow: 0 1px 4px 0 rgba(35, 31, 32, 0.16);
  background-color: ${Colors.White()};
  border-radius: ${BORDER_RADIUS};
`;

const Button = styled.div`
  cursor: pointer;
  padding: 4px;

  &:hover {
    background-color: ${Colors.Selection()};
  }
`;
