import React, { useState } from 'react';
import styled from 'styled-components';

import { Colors } from '../../../Colors';
import { FontWeight } from '../../../FontWeight';
import { Dropdown, SelectElement } from '../../Dropdown';
import { IconButton } from '../../IconButton';
import DeleteIcon from '../../../icons/DeleteIcon';
import ReloadIcon from '../../../icons/ReloadIcon';
import { Button } from '../../Button';
import { Modal } from '../../Modal';
import { FormField } from '../../FormField';
import { Input } from '../../Input';

import { SavedFiltersDefinition, FilterValue } from './types';

interface Props {
  isUnsaved: boolean;
  currentValues: FilterValue[];
  savedFilters: SavedFiltersDefinition;
  onReset: (values: FilterValue[]) => void;
}

/**
 * UI for saving filters
 */
export const FilterSavePane = ({ savedFilters, currentValues, isUnsaved, onReset }: Props) => {
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [saveModalVisible, setSaveModalVisible] = useState(false);
  const current = savedFilters.list.find(sv => sv.id === savedFilters.current);
  const [filterName, setFilterName] = useState(current ? current.name : '');
  const noSavedFilters = savedFilters.list.length === 0;

  const clearOrDelete = () => {
    if (isUnsaved && current) return onReset(current.values);
    setDeleteModalVisible(true);
  };

  return (
    <Container>
      <Title>Saved filters</Title>
      <ListWrapper>
        {noSavedFilters ? (
          <EmptyList />
        ) : (
          <Dropdown
            placeholder="Choose"
            value={current ? current.id : ''}
            options={savedFilters.list.map(l => ({ value: l.id, label: l.name }))}
            onChange={event => savedFilters.onSelect(event.target.value)}
          />
        )}
        {current && (
          <IconButton large onClick={clearOrDelete}>
            {isUnsaved ? <ReloadIcon /> : <DeleteIcon />}
          </IconButton>
        )}
      </ListWrapper>
      <ButtonWrapper>
        {(isUnsaved || noSavedFilters) && (
          <Button
            secondary
            disabled={currentValues.length === 0}
            onClick={() => {
              setSaveModalVisible(true);
              setFilterName('');
            }}>
            Save as…
          </Button>
        )}
        {current && isUnsaved && (
          <Button
            onClick={() => {
              savedFilters.onUpdate({
                id: current.id,
                name: current.name,
                values: currentValues,
              });
            }}>
            Update
          </Button>
        )}
      </ButtonWrapper>

      <Modal
        visible={deleteModalVisible}
        title="Delete saved filter?"
        actions={
          <>
            <Button large secondary onClick={() => setDeleteModalVisible(false)}>
              Cancel
            </Button>
            <Button
              large
              onClick={() => {
                if (current) savedFilters.onDelete(current);
                savedFilters.onSelect('');
                setDeleteModalVisible(false);
              }}>
              Delete Permanently
            </Button>
          </>
        }>
        You are about to delete a saved filter: {current ? current.name : ''}
      </Modal>

      <Modal
        visible={saveModalVisible}
        title={`${current ? 'Update' : 'New'} saved filter`}
        actions={
          <>
            <Button large secondary onClick={() => setSaveModalVisible(false)}>
              Cancel
            </Button>
            <Button
              large
              onClick={() => {
                if (current) {
                  savedFilters.onUpdate({
                    id: current.id,
                    name: filterName,
                    values: currentValues,
                  });
                  savedFilters.onSelect(current.name);
                } else {
                  const newId = savedFilters.onCreate({ name: filterName, values: currentValues });
                  savedFilters.onSelect(newId);
                }
                setSaveModalVisible(false);
              }}>
              Save filter
            </Button>
          </>
        }>
        <FormField label="Filter Name">
          <Input
            placeholder="Required"
            value={filterName}
            onChange={event => setFilterName(event.target.value)}
          />
        </FormField>
      </Modal>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  padding: 35px 24px 28px 24px;
  border-top: solid 1px ${Colors.DarkGray(0.08)};

  ${SelectElement} {
    width: 100%;
  }
`;

const EmptyList = () => <Dropdown placeholder="No saved filters" value="" disabled options={[]} />;

const Title = styled.div`
  position: absolute;
  top: -12px;
  margin-left: -8px;
  display: inline-block;
  padding: 0 8px;
  background-color: ${Colors.White()};
  font-weight: ${FontWeight.Bold};
`;

const ListWrapper = styled.div`
  display: flex;
  align-items: center;

  button {
    margin-left: 8px;
    flex-shrink: 0;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin: 0 -4px;

  button {
    flex: 1;
    margin: 16px 4px 0 4px;
  }
`;
