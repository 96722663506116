import React, { ReactElement } from 'react';
import styled from 'styled-components';
import {
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  TabProps,
  TabsProps,
  TabPanelProps,
  TabContainerProps,
} from '@reach/tabs';

import { Colors } from '../Colors';
import { TextSize } from '../TextSize';
import { FontWeight } from '../FontWeight';
import { Easings } from '../Easings';

/**
 * Wizard navigation with auto-numbered steps
 */
export const WizNav = Tabs as React.FC<TabsProps<any>>;

interface WizNavStepProps extends TabProps {
  /**
   * The default number is provided by WizNavSteps based on the render order on this step
   */
  stepNumber?: number;
  as?: keyof JSX.IntrinsicElements;
}

/**
 * The numbered wizard navigation step of the respective WizNavPanel
 */
export const WizNavStep = React.forwardRef<HTMLElement, WizNavStepProps>(
  ({ stepNumber, ...props }, ref) => (
    <StepRoot {...props} ref={ref}>
      <StepNumber>{stepNumber!}</StepNumber>
      <StepLabel>{props.children}</StepLabel>
    </StepRoot>
  ),
);

const StepNumber = styled.div`
  margin-right: 8px;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  color: white;
  transition: background-color 560ms ${Easings.ExpoOut};
`;

const StepLabel = styled.div`
  transition: color 560ms ${Easings.ExpoOut};
`;

const StepRoot = styled(Tab)`
  display: flex;
  padding: 0;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  font-weight: ${FontWeight.SemiBold};
  ${TextSize.Default};

  &:not(:last-child) {
    margin-right: 32px;
  }

  ${StepNumber} {
    background-color: ${Colors.DarkGray(0.32)};
  }

  ${StepLabel} {
    color: ${Colors.SecondaryGray()};
  }

  &[data-selected] {
    & > {
      ${StepNumber} {
        background-color: ${Colors.NavyBlue()};
      }

      ${StepLabel} {
        color: ${Colors.NavyBlue()};
      }
    }
  }

  &:disabled {
    cursor: default;
  }

  &:disabled:not([data-selected]) {
    ${StepNumber} {
      background-color: ${Colors.DarkGray(0.16)};
    }

    ${StepLabel} {
      color: ${Colors.TertiaryGray()};
    }
  }
`;

interface WizNavStepsProps extends TabContainerProps {
  as?: keyof JSX.IntrinsicElements;
}

/**
 * Container for WizNavStep's
 */
export const WizNavSteps = React.forwardRef<HTMLElement, WizNavStepsProps>((props, ref) => (
  <StepsRoot {...props} ref={ref}>
    {
      React.Children.map(props.children, (child, index) =>
        React.cloneElement(child as ReactElement, { stepNumber: index + 1 }),
      ) as any
    }
  </StepsRoot>
));

const StepsRoot = styled(TabList)`
  display: flex;
`;

interface WizNavPanelProps extends TabPanelProps {
  as?: keyof JSX.IntrinsicElements;
}

/**
 * The panel that is shown when the respective WizNavStep is active
 */
export const WizNavPanel = React.forwardRef<HTMLElement, WizNavPanelProps>((props, ref) => (
  <PanelRoot {...props} ref={ref}>
    {props.children}
  </PanelRoot>
));

const PanelRoot = styled(TabPanel)`
  &:focus {
    outline: none;
  }
`;

/**
 * Container for WizNavPanel's
 */
export const WizNavPanels = TabPanels;
