import React from 'react';
import styled, { css } from 'styled-components';

import { Colors } from '../Colors';
import { Easings } from '../Easings';
import { TextSize } from '../TextSize';

interface RadioGroupProps {
  /**
   * Name of the group, used to link radio buttons together in HTML.
   */
  name: string;
  /**
   * Callback invoked when the currently selected radio changes.
   * Use `event.currentTarget.value` to read the currently selected value.
   * This prop is required because this component only supports controlled usage.
   */
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  /**
   * Whether the group and  its radios are disabled.
   * Individual radios can be disabled using their `disabled` prop.
   */
  disabled?: boolean;

  /** Value of the selected radio. The child with this value will be `:checked`. */
  selectedValue?: string | number;
  className?: string;
  children: React.ReactElement[];
}

/**
 * Grouping helper for radio buttons
 */
export const RadioGroup = ({
  className,
  name,
  children,
  onChange,
  disabled,
  selectedValue,
}: RadioGroupProps) => (
  <div role="radiogroup" className={className}>
    {React.Children.map(children, child => {
      const props: RadioProps = child.props;
      const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (onChange) onChange(event);
      };

      return React.cloneElement(child, {
        ...props,
        name,
        onChange: changeHandler,
        disabled: disabled || props.disabled,
        checked: props.value === selectedValue,
      });
    })}
  </div>
);

interface RadioProps
  extends Omit<React.HTMLProps<HTMLInputElement>, 'ref' | 'as' | 'role' | 'type' | 'label'> {
  /**
   * Radio label
   */
  label: React.ReactNode;
  children?: React.ReactNode;
}

/**
 * Radio button
 */
export const Radio: React.FC<RadioProps> = ({ label, children, disabled, ...props }) => (
  <RadioWrapper disabled={disabled}>
    <RadioButton disabled={disabled} {...props} role="radio" type="radio" />
    {label}
    {children}
  </RadioWrapper>
);

const RadioButton = styled.input`
  appearance: none;
  outline: none;
  cursor: pointer;
  width: 20px;
  min-width: 20px;
  height: 20px;
  min-height: 20px;
  border-radius: 100%;
  border: solid 6px ${Colors.White()};
  background-color: ${Colors.White()};
  box-shadow: 0 1px 2px 0 ${Colors.DarkGray(0.16)}, 0 0 4px 0 ${Colors.DarkGray(0.16)};
  transition: background-color 240ms ${Easings.ExpoOut};

  &:checked {
    background-color: ${Colors.NavyBlue()};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;

      &:checked {
        background-color: ${Colors.NavyBlue(0.4)};
      }
    `}
`;

const RadioWrapper = styled.label<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  ${TextSize.Default};
  line-height: 24px;
  margin: 8px 0;
  cursor: pointer;

  ${RadioButton} {
    margin-right: 12px;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
    `}
`;
