import React from 'react';
import Downshift from 'downshift';
import { HTTPError } from 'ky';
import { Colors, media, Easings, SearchInput } from '@topia.com/ui-kit';
import { BORDER_RADIUS, DEFAULT_BOX_SHADOW } from '@topia.com/ui-kit/variables';
import styled from 'styled-components';
import pDebounce from 'debounce-promise';
import pMemoize from 'promise-memoize';

import { geocodeForward } from '../api';
import { LatLngLiteral, GeocodeResult } from '../types';

/**
 * Address suggest box props
 */
export interface AddressSuggestProps {
  className?: string;
  bbox: GeoJSON.BBox;
  collapseToIcon?: boolean;
  value: LatLngLiteral | null;
  onChange: (value: LatLngLiteral | null, item?: GeocodeResult) => void;
}

interface State {
  results: GeocodeResult[];
}

const queryGeocoder = pMemoize(pDebounce(geocodeForward, 250));

/**
 * Mapbox geocoder API based address autocomplete
 */
export class AddressSuggest extends React.PureComponent<AddressSuggestProps, State> {
  inputRef = React.createRef<HTMLInputElement>();
  state: State = { results: [] };

  loadResults(query: string) {
    queryGeocoder(query, this.props.bbox)
      .then(results => {
        this.setState({ results });
      })
      .catch(err => {
        if (err instanceof HTTPError && window.Rollbar) {
          return window.Rollbar.error('Geocoder error', err.response);
        }
        throw err;
      });
  }

  render() {
    const { className, onChange, collapseToIcon } = this.props;
    const { results } = this.state;

    return (
      <Downshift
        onChange={item => onChange(item ? item.location : null, item)}
        onSelect={item => item !== null && this.inputRef.current && this.inputRef.current.blur()}
        itemToString={item => item && item.name}
        defaultHighlightedIndex={0}>
        {({
          clearSelection,
          getRootProps,
          getInputProps,
          getMenuProps,
          getItemProps,
          isOpen,
          openMenu,
          highlightedIndex,
        }) => (
          <SearchContainer {...getRootProps()} className={className}>
            <SearchBox
              {...getInputProps({
                ref: this.inputRef,
                onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                  if (!event.target.value) {
                    clearSelection();
                    return this.setState({ results: [] });
                  }
                  this.loadResults(event.target.value);
                },
                onFocus: (event: React.FocusEvent<HTMLInputElement>) => {
                  openMenu();
                  event.target.select(); // Select whole input text on focus
                },
                placeholder: 'Find address...',
              } as any)}
              collapsed={collapseToIcon}
            />
            <SearchResults {...getMenuProps()}>
              {isOpen &&
                results.map((item, index) => (
                  <SearchItem
                    key={item.id}
                    {...getItemProps({ item, index })}
                    style={{
                      backgroundColor:
                        highlightedIndex === index ? Colors.DarkGray(0.04) : Colors.White(),
                    }}>
                    {item.name}
                  </SearchItem>
                ))}
            </SearchResults>
          </SearchContainer>
        )}
      </Downshift>
    );
  }
}

const SearchContainer = styled.div`
  position: relative;
  max-width: 320px;
`;

const SearchBox = styled(SearchInput)`
  width: 320px;

  ${(props: { collapsed: boolean }) =>
    props.collapsed &&
    media.mobile`
    width: auto;

    input {
      transition: width 240ms ${Easings.ExpoOut};
      width: 40px;
      padding-right: 0;

      &:focus {
        width: 100%;
        padding-right: 6px;
      }
    }
  `};
`;

const SearchResults = styled.ul`
  position: absolute;
  top: calc(100% + 8px);
  z-index: 1;
  width: 100%;
  margin: 0;
  padding-left: 0;

  list-style-type: none;
  border-radius: ${BORDER_RADIUS};
  background-color: ${Colors.White()};
  box-shadow: ${DEFAULT_BOX_SHADOW};
`;

const SearchItem = styled.li`
  padding: 4px 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;

  &:first-child {
    margin-top: 8px;
  }

  &:last-child {
    margin-bottom: 8px;
  }
`;
