import React, { useRef } from 'react';
import styled from 'styled-components';
import { Instance } from 'tippy.js'; // tslint:disable-line

import { TextSize } from '../../TextSize';
import { Colors } from '../../Colors';
import { Popover } from '../Popover';

import { FilterButton } from './Filter/Button';
import { FilterDefinition } from './Filter/types';
import { AppliedFiltersBar } from './Filter/AppliedBar';
import { FilterEditorPane } from './Filter/EditorPane';
import { FilterSavePane } from './Filter/SavePane';
import { hasUnsavedChanges } from './Filter/utils';
import { SavedViewsDefinition } from './SavedView/types';
import { SavedViewButton } from './SavedView/Button';
import { SavedViewPane } from './SavedView/SavedViewPane';
import { Sortable as SortableComponent } from '../Sortable/Sortable';
import { SortableProps } from '../Sortable/types';
import { SettingsButton } from './Sorter/Button';

/**
 * Table header component properties
 */
export interface TableHeaderProps<T> {
  /**
   * Optional table title
   */
  title: React.ReactNode;
  filter?: FilterDefinition;
  savedViewDef?: SavedViewsDefinition;
  tableSorter?: SortableProps<T>;
  children?: React.ReactElement;
}

/**
 * Table header element
 * Used for filtering, custom actions, column selecting
 */
export const TableHeader = <T extends object>({
  children,
  title,
  filter,
  savedViewDef,
  tableSorter,
}: TableHeaderProps<T>) => {
  const hasActiveFilters = !!filter && !!filter.values && filter.values.length > 0;
  const isUnsaved = hasUnsavedChanges(filter);

  const filterPopoverRef = useRef<Instance>();
  const sorterPopoverRef = useRef<Instance>();
  const saviewPopoverRef = useRef<Instance>();

  return (
    <>
      <Container>
        <Title>{title}</Title>
        <Actions>
          {savedViewDef && (
            <Popover
              noPadding
              onCreate={instance => (saviewPopoverRef.current = instance)}
              placement="bottom-end"
              appendTo={typeof window !== 'undefined' ? window.document.body : undefined}
              content={
                <div>
                  <SavedViewPane
                    isChanged={savedViewDef.hasUnsavedChanges || false}
                    definition={savedViewDef}
                    onClose={() => {
                      if (saviewPopoverRef.current) saviewPopoverRef.current.hide();
                    }}
                  />
                </div>
              }>
              <SavedViewButton
                active={!!savedViewDef.current && !savedViewDef.current.isDefault}
                isUnsaved={savedViewDef.hasUnsavedChanges || false}
              />
            </Popover>
          )}
          {filter && (
            <Popover
              noPadding
              onCreate={instance => (filterPopoverRef.current = instance)}
              placement="bottom-end"
              appendTo={typeof window !== 'undefined' ? window.document.body : undefined}
              content={
                <div>
                  <FilterEditorPane
                    filterColumns={filter.columns}
                    currentValues={filter.values}
                    onApply={value => {
                      if (filterPopoverRef.current) filterPopoverRef.current.hide();
                      filter.onChange([...filter.values, value]);
                    }}
                  />
                  {filter.savedFilters && (
                    <FilterSavePane
                      isUnsaved={isUnsaved}
                      currentValues={filter.values}
                      savedFilters={filter.savedFilters}
                      onReset={values => filter.onChange(values)}
                    />
                  )}
                </div>
              }>
              <FilterButton active={hasActiveFilters} isUnsaved={isUnsaved} />
            </Popover>
          )}
          {tableSorter && (
            <Popover
              noPadding
              onCreate={instance => (sorterPopoverRef.current = instance)}
              placement="bottom-end"
              appendTo={typeof window !== 'undefined' ? window.document.body : undefined}
              content={
                <SortableComponent
                  items={tableSorter.items}
                  title={tableSorter.title}
                  onApply={tableSorter.onApply}
                  onCancel={() =>
                    sorterPopoverRef.current ? sorterPopoverRef.current.hide() : undefined
                  }
                />
              }>
              <SettingsButton active={false} isUnsaved={false} />
            </Popover>
          )}
          <>{children}</>
        </Actions>
      </Container>
      {filter && hasActiveFilters && (
        <AppliedFiltersBar
          filter={filter}
          onAdd={() => filterPopoverRef.current && filterPopoverRef.current.show()}
        />
      )}
    </>
  );
};
const Container = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const Title = styled.h2`
  ${TextSize.Large};
  margin: 0;
  color: ${Colors.NavyBlue()};
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
`;
