import React from 'react';
import styled, { css } from 'styled-components';

import { Spinner } from '../Spinner';

import { InternalTable } from './InternalTable';
import { Pagination, PaginationDefinition } from './Pagination';
import {
  ColumnDefinition,
  SortDefinition,
  SelectionDefinition,
  RowAction,
  SubcontentDefinition,
  TableSizeVariant,
} from './types';
import { useSelection } from './useSelection';
import { SelectionMenu } from './SelectionMenu';
import { useRowAction } from './useRowAction';
import { useRowSubcontent } from './useRowSubcontent';
import { TableHeader } from './Header';

interface FilterDefinition<D> {
  field: keyof D;
  type: 'dropdown' | 'string' | 'number' | 'date';
}

interface Props<D, SC extends keyof D> {
  /**
   * Static dataset - keep fields consistent
   */
  data: D[];
  /**
   * Columns to be shown in table, can render custom content
   */
  columns: ColumnDefinition<D>[];
  sort?: SortDefinition<D>;
  selection?: SelectionDefinition<D, SC>;
  filters?: FilterDefinition<D>[];
  /**
   * Either static list of actions for row or a dynamic based on function.
   *
   * Currently function shouldn't return empty list of actions.
   */
  rowActions?: RowAction<D>[] | ((row: D) => RowAction<D>[]);
  /**
   * Enables pagination - needs explicitly perPage, currentPage, total variables
   */
  pagination?: PaginationDefinition;
  /**
   * Different variants of minimum row height
   */
  size?: TableSizeVariant;
  /**
   * Hide row separators
   */
  noBorders?: boolean;
  /**
   * Whole row click handler
   */
  onRowClick?: (row: D) => void;
  /**
   * Callback on row hover
   */
  onRowHover?: (row?: D) => void;
  /**
   * Table row subcontent
   */
  subcontent?: SubcontentDefinition<D, keyof D>;
  /**
   * Render custom footer
   */
  renderFooter?: () => React.ReactNode;
  /**
   * Show loader spinner
   */
  isLoading?: boolean | string;
}

/**
 * Smart table with optional:
 * - selections
 * - pagination
 * - sorting
 * - fuzzy search
 * - filters
 */
export const Table = <D extends object, SC extends keyof D>({
  data,
  columns,
  selection,
  size = 'default',
  noBorders,
  pagination,
  rowActions = [],
  onRowClick,
  onRowHover,
  subcontent,
  renderFooter,
  sort,
  isLoading,
}: Props<D, SC>) => {
  const [columnsWithSelect, selectedIds] = useSelection({ data, columns, selection });
  const [columnsWithExpansion, expandedRows] = useRowSubcontent({
    subcontent,
    columns: columnsWithSelect,
  });
  const allColumns = useRowAction({ size, rowActions, columns: columnsWithExpansion });

  return (
    <TableContainer>
      <InternalTableWrapper contain={data.length > 0} expandable={!!subcontent}>
        {isLoading && <Spinner label={typeof isLoading === 'string' ? isLoading : undefined} />}
        <InternalTable
          data={data}
          sort={sort}
          columns={allColumns}
          size={size}
          noBorders={noBorders}
          onRowClick={onRowClick}
          onRowHover={onRowHover}
          rowActions={rowActions}
          renderFooter={renderFooter}
          renderSubcontent={
            subcontent
              ? row => expandedRows.has(row[subcontent.expansionColumn]) && subcontent.render(row)
              : undefined
          }
        />
      </InternalTableWrapper>
      <Footer>
        <SelectionMenu selectedIds={selectedIds} selection={selection} />
        {pagination && <Pagination {...pagination} hidePerPageSwitcher={selectedIds.size > 0} />}
      </Footer>
    </TableContainer>
  );
};

const TableContainer = styled.div`
  padding: 0 8px;
  margin: 0 -8px;
`;

Table.Container = TableContainer;
Table.Header = TableHeader;

const InternalTableWrapper = styled.div<{ expandable: boolean; contain: boolean }>`
  width: 100%;

  ${props =>
    props.contain &&
    css`
      position: relative;
    `};

  ${props =>
    props.expandable &&
    css`
      padding-left: 32px;
    `};
`;

const Footer = styled.footer`
  position: relative;

  &:not(:empty) {
    min-height: 64px;
  }
`;
